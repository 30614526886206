import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '8px',
    border: '1px solid #dadde9'
  },
}))

export const getCityName = (record) => {
  if (!record) return null

  if (record.cityName) {
    return record.cityName
  }

  const regExp = /\(([^)]+)\)/ // get text inside ()
  const placeStr = record.originLocation && record.originLocation !== '' ? record.originLocation : record.originName
  const matches = regExp.exec(placeStr)

  if (record.originAddressType && record.originAddressType === 'Accomodation' && record.originAddress) {
    const addressArr = record.originAddress.split(',')

    if (addressArr.length > 1) {
      return addressArr[addressArr.length - 2].replace(/[0-9-]+/g, '')
    }
  }

  if (record.originAddressType && record.originAddressType === 'Other') {
    if (record.additInfoDestinationCity && record.additInfoDestinationCity.replace(/\s/g, '') !== '') {
      return record.additInfoDestinationCity
    } else {
      const placeStrArr = placeStr.split(' ')
      let otherCityName = placeStrArr[placeStrArr.length - 1]
  
      if (placeStr.includes(',')) {
        const placeStrArr = placeStr.split(',')
        if (placeStrArr.length > 1) {
          otherCityName = placeStrArr[placeStrArr.length - 2].replace(/[0-9-]+/g, '')
        } else {
          otherCityName = placeStr
        }
      }
  
      return otherCityName
    }
  }

  if (matches && matches.input) {
    if (record.originAddressType) {
      // if type === Airport: London, England, UK (LHR/EGLL Heathrow) => London, England, UK
      if (record.originAddressType === 'Airport') {
        return matches.input.replace(matches[0], '')
      }

      if (record.originAddressType === 'Accomodation') {
        const accomodationCityName = matches[0].replace('(', '').replace(')', '')
        return accomodationCityName
      }
    }

    // check if value inside () is already in the place name and return it as city name.
    // Alwadi Hotel Doha - MGallery, Doha, Qatar (Doha, Qatar) => Doha, Qatar
    if (matches.input.replace(matches[0], '').includes(matches[1])) {
      return matches[1]
    }
  }

  return null
}

export const fixPlaceName = (string) => {
  const regExp = /\(([^)]+)\)/ // get text inside ()
  const matches = regExp.exec(string)
  let finalPlaceName = string

  // check if value inside () is already in the place name and remove it.
  // Alwadi Hotel Doha - MGallery, Doha, Qatar (Doha, Qatar) => Alwadi Hotel Doha - MGallery
  if (matches && matches.input) {
    const matchesAlt = { ...matches }

    matchesAlt.input = matchesAlt.input.replace(matchesAlt[0], '')

    if (matchesAlt.input.includes(matches[1])) {
      finalPlaceName = matches.input.replace(matches[0], '').replace(`, ${matches[1]}`, '')
    }
  }

  return finalPlaceName
}

export const getPaxNumber = (record) => {
  let paxNumber = record.paxMaxNumber ? record.paxMaxNumber : record.pax ? record.pax.length : 0

  /**
   * if tour, first check if there is a pax number in the numberOfAdults + numberOfChildren fields
   * then check if there is a pax number in the cgObservation field
   * the value from cgObservation field is prioritized
   */
  if (record.serviceType === 'Tour') {
    if (record.numberOfAdults || record.numberOfChildren) {
      paxNumber = record.numberOfAdults + record.numberOfChildren
    }

    if (record.cgObservation) {
      try {
        // Regular expression to find pax number from "* <número> pessoas"
        const regex = /\b(\d+)\s+pessoas\b/
        const res = record.cgObservation.replace(/[\r\n]/gm, ' ').toLowerCase().match(regex)
  
        if (res && res.length && res[1] && parseInt(res[1]) > paxNumber) {
          paxNumber = parseInt(res[1])
        }
      } catch (e) {
        console.error(e)
      }
    }
  } else {
    if (record.cgObservation) {
      try {
        // Regular expression to find pax number from "* <número> pessoas"
        const regex = /\b(\d+)\s+pessoas\b/
        const res = record.cgObservation.replace(/[\r\n]/gm, ' ').toLowerCase().match(regex)
  
        if (res && res.length && res[1] && parseInt(res[1]) > paxNumber) {
          paxNumber = parseInt(res[1])
        }
      } catch (e) {
        console.error(e)
      }
    }
  
    if (record.numberOfAdults || record.numberOfChildren) {
      paxNumber = record.numberOfAdults + record.numberOfChildren
    }
  }

  return paxNumber
}

export const getLuggageNumber = (record) => {
  const obs = record.cgObservation
  const carryOnRegex = /(\d+)\smala(s)?\spequena(s)?/;
  const carryOnRes = obs && obs.toLowerCase().match(carryOnRegex)

  // find suitcase luggage number from "2 MALAS PEQUENAS"
  const suitcaseRegex =  /(\d+)\smala(s)?\sgrande(s)?/;
  const suitcaseRes = obs && obs.toLowerCase().match(suitcaseRegex)

  return {
    carryOn: carryOnRes && carryOnRes.length && carryOnRes[1] ? parseInt(carryOnRes[1]) : 0,
    suitcase: suitcaseRes && suitcaseRes.length && suitcaseRes[1] ? parseInt(suitcaseRes[1]) : 0
  }
}

export const cgStatusOptions = {
  'Confirmed': {
    label: 'Confirmado',
    color: '#4ec21d'
  },
  'Cancelled': {
    label: 'Cancelado',
    color: '#ef3133'
  },
  'OnRequest': {
    label: 'OnRequest',
    color: 'black'
  },
  'WaitingPayment': {
    label: 'Aguardando Pagamento',
    color: 'aquamarine'
  },
  'AwatingPayment': {
    label: 'Aguardando Pagamento',
    color: 'aquamarine'
  },
  'Rejected': {
    label: 'Rejeitado',
    color: 'darkred'
  },
  'TechnicalProblem': {
    label: 'Pendente',
    color: 'pink'
  },
  'PendingCancellation': {
    label: 'Pendente de cancelamento',
    color: 'orange'
  },
  'InProgress': {
    label: 'Andamento',
    color: 'darkblue'
  },
  'Andamento': {
    label: 'Andamento',
    color: 'darkblue'
  },
}

export const supplierStatusOptions = {
  'reservation': {
    label: 'Reservar',
    color: 'orange',
    longLabel: 'Fornecedor a reservar'
  },
  'confirmed': {
    label: 'Fornecedor Confirmado',
    color: '#4ec21d',
    longLabel: 'Fornecedor Confirmado'
  },
  'pending': {
    label: 'Aguardando Confirmação',
    color: '#4290f5',
    longLabel: 'Aguardando Confirmação do Fornecedor'
  },
  'canceled': {
    label: 'Cancelado',
    color: '#ef3133',
    longLabel: 'Fornecedor Cancelado'
  }
}

export const supplierPaymentStatusOptions = {
  'notPaid': {
    label: 'Não pago',
    color: '#ef3133'
  },
  'paid': {
    label: 'Pago',
    color: '#4ec21d'
  }
}

export const supplierReservationPriorityOptions = {
  'none': {
    label: '-',
    color: 'gray'
  },
  'asap': {
    label: 'Imediata',
    color: 'red'
  },
  '7-days': {
    label: '7 dias',
    color: 'orange',
  },
  '14-days': {
    label: '14 dias',
    color: 'green',
  }
}

export const operationalStatusOptions = {
  'notDone': {
    label: 'Não concluído',
    color: '#eee',
    value: 'notDone'
  },
  'pending': {
    label: 'Pendencia',
    color: 'orange',
    value: 'pending'
  },
  'done': {
    label: 'Concluído',
    color: 'gray',
    value: 'done'
  },
  'error': {
    label: 'Falha',
    color: '#ef3133',
    value: 'error'
  }
}

export const needsFinancialReviewOptions = {
  'true': {
    label: 'Revisar',
    color: '#ef3133',
    value: true
  },
  'false': {
    label: 'Revisada',
    color: '#4ec21d',
    value: false
  },
  'none': {
    label: 'Pendente',
    color: 'lightgray',
    value: null,
    disabled: true
  }
}
