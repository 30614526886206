
import React, { useEffect, useState } from 'react'
import { useRecordContext, useDataProvider } from 'react-admin'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import InputLabel from '@mui/material/InputLabel'
import StrapiImage from '../../../media-library/StrapiImage'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

const TransferItems = ({ showPrice = true, paxDetails = false }) => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [vehicles, setVehicles] = useState(null)

  useEffect(() => {
    if (record?.items?.filter(item => item.type === 'transfer').length) {
      const vehicleIds = record.items.filter(item => item.type === 'transfer').map(item => item.options?.vehicle?.id)
        .filter((value, index, self) => self.indexOf(value) === index)

      /**
       * filters[id][$in][1]=6
       * filters[id][$in][2]=7
       */
      const filters = vehicleIds.reduce((acc, id, index) => {
        acc[`filters[id][$in][${index + 1}]`] = id
        return acc
      }, {})

      if (vehicleIds.length) {
        dataProvider.getList('transfer-vehicles', {
          meta: {
            ...filters,
            'fields[0]': 'images',
            'populate': 'deep,3',
          },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'updatedAt', order: 'DESC' }
        }).then((res) => {
          const vehiclesAlt = {}
          res.data.forEach(vehicle => {
            vehiclesAlt[vehicle.id] = vehicle
          })

          setVehicles(vehiclesAlt)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  if (record?.items?.filter(item => item.type === 'transfer').length === 0) {
    return null
  }

  return (
    <Stack gap={2}>
      <InputLabel>Itens</InputLabel>
      <Stack gap={2}>
        {record?.items?.filter(item => item.type === 'transfer').map((item, index) => (
          <Card key={index}>
            <CardContent>
              <Stack gap={4} direction="row" fontSize={'14px'}>
                <Stack gap={2} flexShrink={0}>
                  <b>Rota #{item.itemRef}</b>
                  {item.options?.vehicle?.id ?
                    <StrapiImage
                      src={`${vehicles && vehicles[item.options.vehicle.id]?.images?.data?.length ?
                        `${vehicles[item.options.vehicle.id]?.images?.data[0].attributes.url}`
                        : `https://fakeimg.pl/720x480`}`}
                      alt="option"
                      style={{ width: '200px', height: '130px', objectFit: 'contain', paddingRight: '24px', borderRight: '1px solid #eee' }} />
                    : ''}
                  <Stack>
                    <b>{item.options?.vehicle?.name?.pt} #{item.options?.vehicle?.id}</b>
                    <div>{item.options?.vehicle?.models?.pt}</div>
                  </Stack>
                </Stack>
                <Stack gap={4} direction="row" justifyContent="space-between" width="100%">
                  <Stack gap={1}>
                    <div>
                      <b>Data:</b> {new Date(item.options?.date).toLocaleDateString('pt-BR', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                      })}
                    </div>
                    <div>
                      <b>Origem:</b> {item.options?.origin?.name}
                    </div>

                    <div>
                      <b>Destino:</b> {item.options?.destination?.name}
                    </div>
                    <Stack gap={3} direction="row">
                      <div>
                        <b>Malas pequenas:</b> {item.options?.mediumBaggageQuantity}
                      </div>
                      <div>
                        <b>Malas grandes:</b> {item.options?.smallBaggageQuantity}
                      </div>
                    </Stack>
                    {paxDetails ?
                      <Stack gap={1}>
                        <b>Passageiros:</b>
                        <Stack gap={2}>
                          {item.options?.pax?.map((paxItem, index) => (
                            <Card key={index}>
                              <ul key={index} style={{ padding: '12px', margin: 0, listStyle: 'none', lineHeight: '22px' }}>
                                <li><b style={{ fontSize: '14px' }}>{paxItem.name} {paxItem.surname}</b></li>
                                {Object.keys(paxItem).sort().map((key, i) => {
                                  if (
                                    key === 'id' ||
                                    key === 'name' ||
                                    key === 'surname' ||
                                    key === 'whatsappAllowed' ||
                                    key === 'phoneDDD' ||
                                    key === 'phoneDDI' ||
                                    key === 'mainPax'
                                  ) return null

                                  return (
                                    <li key={i} style={{ color: '#444' }}>
                                      <b>{key}</b>:{' '}
                                      {key === 'phone' ?
                                        `${paxItem.phoneDDI ? `+${paxItem.phoneDDI} ` : ''}${paxItem.phoneDDD} ${paxItem.phone}`
                                        : paxItem[key] ? paxItem[key].toString() : '?'}
                                    </li>
                                  )
                                })}
                              </ul>
                            </Card>
                          ))}
                        </Stack>
                      </Stack>
                      :
                      <div style={{ width: '200px', cursor: 'pointer' }}>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              {item.options?.pax?.map((paxItem, index) =>
                                <ul key={index} style={{ padding: '12px', margin: 0, listStyle: 'none', lineHeight: '22px' }}>
                                  <li><b style={{ fontSize: '14px' }}>{paxItem.name} {paxItem.surname}</b></li>
                                  {Object.keys(paxItem).sort().map((key, i) => {
                                    if (
                                      key === 'id' ||
                                      key === 'name' ||
                                      key === 'surname' ||
                                      key === 'whatsappAllowed' ||
                                      key === 'phoneDDD' ||
                                      key === 'phoneDDI' ||
                                      key === 'mainPax'
                                    ) return null

                                    return (
                                      <li key={i} style={{ color: '#444' }}>
                                        <b>{key}</b>:{' '}
                                        {key === 'phone' ?
                                          `${paxItem.phoneDDI ? `+${paxItem.phoneDDI} ` : ''}${paxItem.phoneDDD} ${paxItem.phone}`
                                          : paxItem[key] ? paxItem[key].toString() : '?'}
                                      </li>
                                    )
                                  })}
                                </ul>
                              )}
                            </React.Fragment>
                          }
                        >
                          <div><b>Passageiros:</b> {item.options?.passengers}</div>
                        </HtmlTooltip>
                      </div>
                    }
                  </Stack>
                  {showPrice ?
                    <Stack fontSize={'16px'}>
                      {item.options?.price ?
                        <b>
                          {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: item.options?.price?.currency || 'BRL'
                          }).format(item.options?.price?.value)}
                        </b>
                        : ''}
                    </Stack>
                    : ''}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </Stack>
  )
}

export default TransferItems
