import { useState, useEffect, useRef } from "react"
import { useRecordContext, Form, SaveButton, useUpdate, useNotify, useRefresh } from "react-admin"
import Box from "@mui/material/Box"

const FieldForm = ({ children, resource, transform = (data) => data, ...props }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [update] = useUpdate()
  const save = (data) => {
    update(
      resource,
      {
        id: record.id,
        data: transform(data)
      },
      {
        onSuccess: () => {
          notify(`Salvo com sucesso`, { type: 'success' })

          if (props.onFinish) {
            props.onFinish()
          }
        },
        onFailure: () => {
          notify(`Erro ao salvar`, { type: 'error' })
        }
      }
    )
  }

  const wrapperSx = {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    gap: '0.5em',
    '.MuiFormHelperText-root': {
      display: 'none'
    },
    '.MuiInputBase-input': {
      padding: '8px 16px !important',
      fontSize: '14px'
    },
    '.MuiFormLabel-root': {
      fontSize: '14px'
    }
  }

  return (
    <Form {...props} onSubmit={save}>
      <Box sx={wrapperSx}>
        {children}
        <SaveButton
          sx={{ padding: '2px 4px', marginTop: '2px', minWidth: 'auto', borderRadius: '4px', '.MuiSvgIcon-root': { display: 'none' } }}
          size="small"
          label="Ok" />
      </Box>
    </Form>
  )
}

const EditableField = ({ field, input, resource, transform, ...props }) => {
  const ref = useRef()
  const refresh = useRefresh()
  const [isEditOpen, setIsEditOpen] = useState(false)

  useEffect(() => {
    if (isEditOpen) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (!event.target.className.includes('MuiAutocomplete-option')) {
            setIsEditOpen(false)
          }
        }
      }

      document.addEventListener('click', handleClickOutside, true)

      return () => {
        document.removeEventListener('click', handleClickOutside, true)
      }
    }
  }, [isEditOpen])

  const handleOnFinish = () => {
    setIsEditOpen(false)
    refresh()
  }

  return (
    <span ref={ref}>
      {isEditOpen ?
        <FieldForm resource={resource} onFinish={handleOnFinish} transform={transform}>
          {input}
        </FieldForm>
        :
        <span onClick={() => setIsEditOpen(true)}>
          {field}
        </span>
      }
    </span>
  )
}

export default EditableField
