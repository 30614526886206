import { useEffect, useState, useRef } from 'react'
import { useUpdate, useRecordContext, useNotify, useRefresh } from 'react-admin'
import { Form, Button } from 'react-admin'
import EditIcon from '@mui/icons-material/Edit'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import CurrencyInput from '../../components/CurrencyInput'
import Box from '@mui/material/Box'
import currencies from '../../data/currencies.json'

const ItemSupplierPriceFC = ({ ...props }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [update, { isLoading }] = useUpdate()
  const wrapperRef = useRef(null)
  const refresh = useRefresh()
  const [currencyCode, setCurrencyCode] = useState('USD')
  const [value, setValue] = useState()
  const [shouldSave, setShouldSave] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [canEdit, setCanEdit] = useState(!!props.canEdit)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  const getFormatedValue = () => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: currencyCode
    }).format(value)
  }

  useEffect(() => {
    if (record.supplierPriceFC2) {
      setCurrencyCode(record.supplierPriceFC2.currency)
      setValue(record.supplierPriceFC2.value)
    }
  }, [record.supplierPriceFC2])

  useEffect(() => {
    if (record.supplierPriceFC2?.value !== value || record.supplierPriceFC2?.currency !== currencyCode) {
      setShouldSave(true)
    } else {
      setShouldSave(false)
    }
  }, [value, currencyCode, record.supplierPriceFC2])

  const handleInputChange = (value) => {
    setValue(value)
  }

  const handleDialogClose = () => {
    setIsConfirmDialogOpen(false)
    setIsEditing(false)

    if (record.supplierPriceFC2) {
      setCurrencyCode(record.supplierPriceFC2.currency)
      setValue(record.supplierPriceFC2.value)
    }
  }

  const handleFormSubmit = async (data) => {
    setIsConfirmDialogOpen(true)
  }

  useEffect(() => {
    /**
     * Detects if clicked outside wrapperRef
     */
    function handleClickOutside(event) {
      if (!shouldSave && wrapperRef.current && !wrapperRef.current.contains(event.target)) {  
        setIsEditing(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    };
  }, [wrapperRef, shouldSave])

  const handleDialogConfirm = async () => {
    update(
      'services',
      { id: record.id, data: { supplierPriceFC2: {
        currency: currencyCode,
        value: value
      } }, previousData: record },
      {
        onSuccess: () => {
          setShouldSave(false)
          setIsEditing(false)
          setCanEdit(!!props.canEdit)
          setIsConfirmDialogOpen(false)
          refresh()
        },
        onError: (error) => {
          console.error(error)
          notify(`Erro ao alterar preço fornecedor em moeda extrangeira!`, { type: 'error' })
        }
      }
    )
  }

  return (
    <div ref={wrapperRef}>
      {isEditing ?
        <Form onSubmit={handleFormSubmit}>
          <Box sx={{
            position: 'relative',
            display: 'flex',
            gap: '4px',
            '.MuiFormControl-root': {
              margin: 0,
            },
            '.MuiInputBase-root': {
              borderRadius: '4px',
            },
            '.MuiInputBase-input': {
              padding: '4px 8px',
              fontSize: '16px',
              fontWeight: 'bold',
            }
          }}>
            <select value={currencyCode} onChange={(e) => setCurrencyCode(e.target.value)}
              style={{ borderRadius: '4px', borderColor: '#ccc' }}>
              {currencies.map((currency) => (
                <option key={currency.code} value={currency.code}>{currency.code}</option>
              ))}
            </select>
            <CurrencyInput
              record={record}
              currencyCode={currencyCode}
              defaultValue={record.supplierPriceFC2?.value}
              source="supplierPriceFC2.value"
              onChange={handleInputChange} />
            {/* <input
              type="text"
              defaultValue={supplierPriceFC}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              style={{ width: '110px', height: '26px', fontWeight: 'bold', fontSize: '16px', padding: '4px' }} /> */}
            {shouldSave &&
              <Button disableElevation variant="contained" type="submit" id="submit-button" sx={{
                padding: 0,
                minWidth: '32px',
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: '600',
                borderRadius: '4px',
                'span': {
                  margin: 0
                }
              }}>
                <>OK</>
              </Button>
            }
          </Box>
        </Form>
        : <span style={{ color: 'red', fontWeight: 'bold' }}>
          {
            (value) ?
              <>
                {canEdit ?
                  <Button variant="outlined" endIcon={<EditIcon />} color="error"
                    sx={{
                      fontWeight: 'bold',
                    }}
                    onClick={() => setIsEditing(true)}>
                    <>{getFormatedValue() || 'Definir'}</>
                  </Button>
                  : <>{getFormatedValue() || '-'}</>
                }
              </>
              : <Button variant="outlined" endIcon={<EditIcon />}
                sx={{
                  fontWeight: 'bold',
                }}
                onClick={() => setIsEditing(true)}><>{record.supplierPriceFC || 'Definir'}</></Button>
          }
        </span>}

      <Dialog
        open={isConfirmDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Confirmar ação</DialogTitle>
        <DialogContent>
          <DialogContentText>
            O <b><u>Valor Fornecedor em moeda extrangeira</u></b> será alterado para{' '}
            <b style={{ color: 'red' }}>
              {getFormatedValue()}
            </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isLoading ? <Button color="error" variant="outlined" onClick={handleDialogClose}><>Cancelar</></Button> : ''}
          <Button autoFocus color="success" variant="contained" onClick={handleDialogConfirm}>
            {isLoading ? <>...</> : <>Confirmar</>}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ItemSupplierPriceFC