import { useParams } from 'react-router-dom'
import { Edit, TextInput, useRecordContext, SimpleForm, TopToolbar, ListButton } from 'react-admin'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

const PostEditActions = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  )
}

const Aside = () => {
  const record = useRecordContext()
  return (
    <div style={{ width: '360px', margin: '1em' }}>
      <Typography variant="h6">Detalhes</Typography>
      {record && (
        <>
          <Typography variant="body2">
            Criado em: {new Date(record.createdAt).toLocaleString()}
          </Typography>
          <br />
          <Typography variant="body2">
            Atualizado em: {new Date(record.updatedAt).toLocaleString()}
          </Typography>
        </>
      )}
    </div>
  )
}

const EditPage = () => {
  const { id } = useParams()

  return (
    <Edit aside={<Aside />}
      actions={<PostEditActions />}
      title={`Categoria Lançamento #${id}`}
      mutationMode="pessimistic">
      <SimpleForm>
        <Stack direction="row" spacing={2}>
          <TextInput source="name" label="Nome" sx={{ minWidth: '480px' }} />
        </Stack>
      </SimpleForm>
    </Edit>
  )
}

export default EditPage
