import Icon from '@mui/icons-material/Category'
import List from './List'
import Edit from './Edit'
import Create from './Create'

const props = {
  icon: Icon,
  options: { label: 'Classificações de clientes'},
  list: List,
  edit: Edit,
  create: Create
}

export default props
