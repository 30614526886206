import Icon from '@mui/icons-material/CardTravel'
import List from './List'
import Edit from './Edit'

const props = {
  icon: Icon,
  options: { label: 'Reservas'},
  list: List,
  edit: Edit
}

export default props
