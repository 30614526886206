import { List, Authenticated, Pagination, Datagrid, TextField, BooleanField, FunctionField, DateField, EmailField, ShowButton, SelectArrayInput } from 'react-admin'
import StatusButton from '../../components/StatusButton'
import { statusOptions } from '../utils'
import Box from '@mui/material/Box'

const CustomPagination = () => <Pagination sx={{
  '.MuiTablePagination-spacer': {
    display: 'none'
  },
  '.MuiToolbar-root': {
    padding: 0
  },
  '.MuiPaginationItem-root .MuiPaginationItem-icon': {
    width: '28px',
    height: '28px',
    margin: '0 4px'
  }
}} rowsPerPageOptions={[10, 25, 50, 100]} />

const StatusChoiceWithColor = ({ choice }) => {
  return (
    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      <Box className='color-box' sx={{ backgroundColor: choice.color, width: '16px', height: '16px', borderRadius: '4px', marginRight: '8px' }}></Box>
      {choice.name}
    </Box>
  )
}

const filters = [
  <SelectArrayInput label="Status" source="status" alwaysOn
    choices={Object.keys(statusOptions).map(key => {
      return {
        id: key,
        name: statusOptions[key].label,
        color: statusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
]

const CustomList = (props) => {
  const meta = {}

  // populate relations
  meta[`populate`] = 'deep,3'

  return (
    <Authenticated>
      <List {...props}
        style={{ marginTop: '16px' }}
        queryOptions={{ meta: meta }}
        sort={{ field: 'createdAt', order: 'ASC' }}
        debounce={500}
        filters={filters}
        filterDefaultValues={{ status: 'notDone' }}
        pagination={<CustomPagination />}
        perPage={25}>
        <Datagrid bulkActionButtons={false}>
          <ShowButton label="Ver" />
          <StatusButton resource="service-requests" source="status" options={statusOptions} />
          <FunctionField label="Tipo" render={record => {
            const field = record.customFields.find(item => item.key === 'type')
            return field?.value ? field?.value.toUpperCase() : ''
          }} />
          <TextField source="requestor.name" label="Solicitante" />
          <EmailField source="contact.email" label="E-mail" />
          <TextField source="contact.phone" label="Telefone" />
          <BooleanField source="contact.mailAllowed" label="E-mail permitido" />
          <BooleanField source="contact.whatsappAllowed" label="WhatsApp" />
          <FunctionField render={record => {
            const field1 = record.customFields.find(item => item.key === 'originName')
            const field2 = record.customFields.find(item => item.key === 'destinationName')

            return `${field1?.value} => ${field2?.value}`
          }} />
          <DateField source="createdAt" label="Criado em" showTime />
        </Datagrid>
      </List>
    </Authenticated>
  )
}

export default CustomList
