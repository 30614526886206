import { Edit, TextInput, useRecordContext, SimpleForm, TopToolbar, ListButton, BooleanInput } from 'react-admin'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

const PostEditActions = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  )
}

const Aside = () => {
  const record = useRecordContext()
  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Typography variant="h6">Detalhes</Typography>
      {record && (
        <>
          <Typography variant="body2">
            createdAt: {record.createdAt}
          </Typography>
          <br />
          <Typography variant="body2">
            updatedAt: {record.updatedAt}
          </Typography>
        </>
      )}
    </div>
  )
}

const EditPage = () => {

  return (
    <Edit aside={<Aside />} actions={<PostEditActions />}>
      <SimpleForm>
        <Stack direction="row" spacing={2}>
          <TextInput disabled source="id" sx={{ marginTop: '0', width: '100px' }} />
          <TextInput source="name" label="Descrição" />
          <TextInput source="structural_code" label="Estrutural" />
          <TextInput source="accounting_code" label="Cod Contábil" />
          <BooleanInput source="isActive" label="Ativo" />
        </Stack>
      </SimpleForm>
    </Edit>
  )
}

export default EditPage
