import EditForm from "./EditForm"

const Edit = (props) => {
  return (
    <>
      <EditForm mode="edit" />
    </>
  )
}

export default Edit
