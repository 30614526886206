import Icon from '@mui/icons-material/Upcoming'
import List from './List'

const props = {
  icon: Icon,
  options: { label: 'Fluxo de Reservas' },
  list: List
}

export default props
