import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useNotify, useUpdate, TextInput, useEditController, useCreateController, SimpleForm, required, ReferenceInput, AutocompleteInput, NumberInput, useRedirect, SelectInput, DateInput } from 'react-admin'
import CurrencyInput from '../components/CurrencyInput'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CustomToolbar from './EditFormToolbar'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import EditIcon from '@mui/icons-material/Edit'
import Alert from '@mui/material/Alert'
import ItemInvalidate from './ItemInvalidate'
import { IfCanAccess } from '../rbac'

const EditPage = ({ mode }, ref) => {
  const { id } = useParams()
  const notify = useNotify()
  const redirect = useRedirect()
  const [update] = useUpdate()
  const [record, setRecord] = useState(null)
  const [recurrenceDayStart, setRecurrenceDayStart] = useState(null)
  const [isRecurrence, setIsRecurrence] = useState(false)
  const [canValidate, setCanValidate] = useState(false)
  const [redirectTo, setRedirectTo] = useState('edit') // create, edit

  // state to control some data when they change
  const [controlledValues, setControlledValues] = useState({
    value: 0, // CurrencyInput is not a react-admin input component, so we need to control its value
  })

  const transform = data => {
    return {
      ...data,
      value: controlledValues.value,
      // check if the date is yyyy-mm format and add the day
      date: data.date.length === 7 ? `${data.date}-01` : data.date,
    }
  }

  const onSuccess = (data) => {
    notify(`Salvo com sucesso!`, { type: 'success' })
    let route

    switch (redirectTo) {
      case 'create':
        route = '/accounting-transactions/create'
        break
      default:
        route = `/accounting-transactions/${data.id}`
    }

    redirect(route)
  }

  const saveControllerParams = {
    id,
    transform: transform,
    mutationOptions: { onSuccess },
    queryOptions: {
      meta: {
        // populate only the relations we need to avoid delays
        populate: 'parent,chart_of_accounts,bank_account,categories,parties'
      }
    }
  }

  const useSaveController = mode === 'edit' ? useEditController : useCreateController
  const { record: entry, save } = useSaveController(saveControllerParams)

  useEffect(() => {
    if (entry) {
      setRecord({
        ...entry,
        bank_account: entry.bank_account?.data?.id,
        categories: entry.categories?.data[0]?.id,
        chart_of_accounts: entry.chart_of_accounts?.data[0]?.id,
        parties: entry.parties?.data[0]?.id
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry])

  const customSave = initialData => {
    const data = transform(initialData)
    update(
      'accounting-transactions',
      { id: record.id, data, previousData: record },
      {
        onSuccess: () => {
          onSuccess(data)
        },
        onError: () => {
          notify(`Erro ao salvar!`, { type: 'error' })
        }
      }
    )
  }

  const handleRecurrenceSwitch = (e) => {
    setIsRecurrence(e.target.checked)
  }

  const handleControlledInputChange = (name, value) => {
    setControlledValues({ ...controlledValues, [name]: value })
  }

  useEffect(() => {
    const changedData = { ...record, ...controlledValues }
    setCanValidate(changedData.paymentSettlementDate && changedData.bank_account ? true : false)
  }, [record, controlledValues])

  return (
    <>
      <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3>{mode === 'edit' ? `Editar lançamento #${id}` : 'Novo lançamento'}</h3>
        {record && record.parent && record.parent.data ?
          <Button variant="contained" startIcon={<EditIcon />} size="small" href={`/#/accounting-transactions/${record.parent.data.id}`}>
            Editar lançamento pai #{record.parent.data.id}
          </Button>
          : ''}
      </header>
      {record || mode !== 'edit' ?
        <>
          {record?.validated ?
            <>
              <Alert severity="error" sx={{ marginBottom: '20px' }}>
                Este lançamento já foi validado e não pode ser alterado.
              </Alert>
              <IfCanAccess action="invalidate" resource="accounting-transactions">
                <Box>
                  <ItemInvalidate record={record} />
                </Box>
              </IfCanAccess>
            </>
           :
            <Box display={{ width: '100%', gap: '16px' }}>
              <Box sx={{ flexShrink: 0, backgroundColor: 'white', 'form': { boxShadow: '0px 2px 8px 0px #00000029', borderRadius: '8px' } }}>
                <SimpleForm
                  record={record}
                  toolbar={<CustomToolbar canValidate={canValidate} setRedirectTo={setRedirectTo} mode={mode} />}
                  onSubmit={mode === 'edit' ? (data) => customSave(data) : save}>
                  <Box display="flex" width="100%" gap="1em">
                    <DateInput source="date" type="month" label="Competência"
                      format={
                        // format date from yyyy-mm-dd to yyyy-mm
                        (value) => {
                          if (!value) return value
                          return value.split('-').slice(0, 2).join('-')
                        }
                      }
                      validate={[required()]}
                      sx={{ flexShrink: 0 }} />
                    <SelectInput label="Tipo" source="transactionType"
                      validate={required()}
                      // defaultValue={mode === 'edit' ? record?.transactionType : 'out'}
                      sx={{ flexShrink: 0, width: '250px' }}
                      choices={[
                        { id: 'out', name: 'Saída' },
                        { id: 'in', name: 'Entrada' }
                      ]} />
                    <CurrencyInput label="Valor"
                      source="value"
                      record={record}
                      validate={[required()]}
                      sx={{ flexShrink: 0 }}
                      onChange={(value) => handleControlledInputChange('value', value)} />
                    <ReferenceInput source="categories" reference="accounting-transaction-categories" sort={{ field: 'name', order: 'ASC' }}>
                      <AutocompleteInput
                        sx={{ width: '100%' }}
                        label="Categoria"
                        validate={required()}
                        optionText="name"
                        filterToQuery={
                          searchText => ({ name_containsi: searchText })
                        } />
                    </ReferenceInput>
                  </Box>

                  <Box display="flex" width="100%" gap="1em">
                    <TextInput source="description" label="Descrição" sx={{ width: '70%', flexShrink: 0 }} validate={required()} />
                    <ReferenceInput source="parties" reference="accounting-transaction-parties">
                      <AutocompleteInput
                        fullWidth
                        label="Beneficiário/Pagador"
                        optionText="name"
                        filterToQuery={
                          searchText => ({ name_containsi: searchText })
                        } />
                    </ReferenceInput>
                  </Box>

                  <Box display="flex" width="100%" gap="1em">
                    <ReferenceInput source="chart_of_accounts" reference="chart-of-accounts">
                      <AutocompleteInput
                        fullWidth
                        label="Plano de contas"
                        optionText={choice =>
                          `${choice.structural_code} - ${choice.name}`
                        }
                        validate={required()}
                        filterToQuery={
                          searchText => ({ name_containsi: searchText })
                        } />
                    </ReferenceInput>
                    <ReferenceInput source="bank_account" reference="bank-accounts">
                      <AutocompleteInput
                        fullWidth
                        label="Conta bancária"
                        onChange={value => handleControlledInputChange('bank_account', value)}
                        optionText={choice =>
                          `${choice.name}`
                        }
                        filterToQuery={
                          searchText => ({ name_containsi: searchText })
                        } />
                    </ReferenceInput>
                  </Box>

                  <Box display="flex" width="100%" gap="1em">
                    <DateInput source="paymentDueDate" fullWidth label="Data de vencimento" validate={required()} onChange={e => {
                      if (e.target.value) {
                        setRecurrenceDayStart(e.target.value.split('T')[0].split('-')[2])
                      } else {
                        setRecurrenceDayStart(null)
                      }
                    }} />
                    <DateInput source="paymentSettlementDate" fullWidth label="Data de baixa"
                      onChange={(e) => handleControlledInputChange('paymentSettlementDate', e.target.value)} />
                    <TextInput source="invoiceNumber" label="Nº RSV / Fatura" sx={{ flexShrink: 0 }} />
                    <TextInput source="invoiceUrl" label="Link RSV / Fatura" sx={{ flexShrink: 0, width: '400px' }} />
                  </Box>

                  {mode !== 'edit' ?
                    <Box>
                      <FormGroup>
                        <FormControlLabel control={<Switch onChange={handleRecurrenceSwitch} />} label="É recorrente" />
                      </FormGroup>
                      {isRecurrence ?
                        <ul style={{ listStyle: 'none', padding: 0 }}>
                          <li>Repete: Mensalmente no dia <b>{recurrenceDayStart || '?'}</b></li>
                          <li>Termina após: </li>
                          <NumberInput source="recurrenceNumber" label="Número de ocorrências" validate={required()} />
                        </ul>
                        : ''}
                    </Box>
                    : ''}
                </SimpleForm>
              </Box>
              <Box style={{ width: '100%', textAlign: 'right', marginTop: '20px' }}>
                {record && (
                  <>
                    <Typography variant="body2" color="gray">
                      createdAt: {record.createdAt}
                    </Typography>
                    <Typography variant="body2" color="gray">
                      updatedAt: {record.updatedAt}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>}
        </>
        :
        <Box sx={{ display: 'flex', paddingTop: '8px' }}>
          <CircularProgress />
        </Box>
      }
    </>
  )
}

export default EditPage
