import { Create, TextInput, TopToolbar, SimpleForm, ListButton } from 'react-admin'

const CreateActions = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  )
}

const CreatePage = () => {
  return (
    <Create actions={<CreateActions />}>
      <SimpleForm>
        <TextInput source="name" label="Descrição" />
      </SimpleForm>
    </Create>
  )
}

export default CreatePage
