import { SaveButton, DeleteButton, Toolbar } from 'react-admin'
import { IfCanAccess } from '../rbac'

const ToolbarWithPermissions = ({ ...props }) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      <SaveButton />
      <IfCanAccess action="delete">
        <DeleteButton />
      </IfCanAccess>
    </Toolbar> 
  )
}

export default ToolbarWithPermissions
