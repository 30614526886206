import { useState, useEffect } from 'react'
import { useRecordContext, useUpdate, useNotify } from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

const StatusButton = (props) => {
  const {
    options,
    resource,
    source,
    readOnly = false,
    variant = 'default' // default, text
  } = props
  const [update, { isLoading }] = useUpdate()
  const notify = useNotify()
  const record = useRecordContext()
  const [selectedOption, setSelectedOption] = useState()

  useEffect(() => {
    if (record) {
      Object.keys(options).forEach((item) => {
        if (options[item].value === record[source]) {
          setSelectedOption(options[item])
        }
      })
    }
  }, [record, options, source])

  const handleChange = (event) => {
    Object.keys(options).forEach((item) => {
      if (options[item].value === event.target.value) {
        setSelectedOption(options[item])
      }
    })

    update(
      resource,
      {
        id: record.id,
        data: {
          [source]: event.target.value,
          previousData: record
        }
      },
      {
        onSuccess: () => {
          notify(`Status alterado com sucesso!`, { type: 'success' })
        },
        onError: () => {
          notify(`Erro ao alterar status!`, { type: 'error' })
        }
      }
    )
  }

  if (!selectedOption) {
    return (
      <Box sx={{
        width: '24px',
        height: '24px',
        borderRadius: '6px',
        background: 'linear-gradient(90deg, #ddd, #ccc)'
      }}></Box>
    )
  }

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', gap: '12px' }}>
      <Select
        value={selectedOption.value}
        onChange={handleChange}
        title={selectedOption.label}
        disabled={readOnly}
        sx={variant === 'text' ?
          {
            height: 24,
            minWidth: 70,
            color: 'white',
            textAlign: 'center',
            borderRadius: '4px',
            fontSize: '13px',
            fontWeight: '600',
            bgcolor: selectedOption.color,
            '&.Mui-disabled': {
              color: 'white',
            },
            '.Mui-disabled': {
              WebkitTextFillColor: 'white !important',
            },
            '.MuiSelect-select': {
              padding: '0 8px !important',
            },
            '.color-box': {
              display: 'none'
            },
            'svg': {
              display: 'none'
            }
          }
          :
          {
            height: 24,
            width: 24,
            color: 'white',
            borderRadius: '6px',
            fontSize: '13px',
            fontWeight: '600',
            textIndent: '-999px',
            bgcolor: selectedOption.color,
            '.color-box': {
              display: 'none'
            },
            'svg': {
              display: 'none'
            }
          }
        }>
        {Object.keys(options).map((item, index) => {
          if (options[item].disabled) return null

          return (
            <MenuItem key={index} value={options[item].value}>
              <Box className='color-box' sx={{ backgroundColor: options[item].color, width: '16px', height: '16px', borderRadius: '4px', marginRight: '8px' }}></Box>
              {options[item].label}
            </MenuItem>
          )
        }
        )}
      </Select>
      {isLoading ? <CircularProgress size={24} /> : ''}
    </div>
  )
}

export default StatusButton
