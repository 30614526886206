import React from 'react'
import { List, Datagrid, TextField, DateField, EditButton, Authenticated } from 'react-admin'

const CustomList = (props) => {
  return (
    <Authenticated>
      <List {...props}>
        <Datagrid>
          <TextField source="id" />
          <TextField source="namePT" />
          <TextField source="code" />
          <DateField source="updatedAt" />
          <EditButton />
        </Datagrid>
      </List>
    </Authenticated>
  )
}

export default CustomList