import Icon from '@mui/icons-material/AccountBalance'
import List from './List'
import Edit from './Edit'
import Create from './Create'

const props = {
  icon: Icon,
  list: List,
  edit: Edit,
  create: Create,
}

export default props
