import { useRef, useState, useEffect } from 'react'
import { useListContext } from 'react-admin'
import { Button } from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import styles from './styles.module.css'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import Calendar from '../../components/Calendar'

const DateFilter = () => {
  const {
    filterValues,
    setFilters
  } = useListContext()
  const [range, setRange] = useState()
  const detailsRef = useRef(null)
  const calendarComponentRef = useRef(null)

  useEffect(() => {
    if (filterValues && (filterValues.paymentSettlementDate_gte || filterValues.paymentSettlementDate_lte)) {
      const newRange = {
        startDate: filterValues.paymentSettlementDate_gte ? new Date(`${filterValues.paymentSettlementDate_gte.split('T')[0]}T00:00`) : null
      }

      if (filterValues.paymentSettlementDate_lte) {
        newRange.endDate = new Date(`${filterValues.paymentSettlementDate_lte.split('T')[0]}T00:00`)
      } else if (filterValues.paymentSettlementDate_gte) {
        newRange.endDate = new Date(`${filterValues.paymentSettlementDate_gte.split('T')[0]}T00:00`)
      } else {
        newRange.endDate = null
      }

      setRange(newRange)
    }
  }, [filterValues])

  const handleCalendarChange = (e) => {
    setRange(e.selection)
  }

  const handleApplyClick = () => {
    if (range) {
      setFilters({
        ...filterValues,
        paymentSettlementDate_gte: range.startDate ? range.startDate.toISOString().split('T')[0] : null,
        paymentSettlementDate_lte: range.endDate ? `${range.endDate.toISOString().split('T')[0]}T23:59:59` : null // range.endDate && range.endDate !== range.startDate ? `${range.endDate.toISOString().split('T')[0]}T23:59:59` : null
      })
    }

    if (detailsRef && detailsRef.current) {
      detailsRef.current.removeAttribute('open')
    }
  }

  const handleResetClick = () => {
    setFilters({
      ...filterValues,
      paymentSettlementDate_gte: null,
      paymentSettlementDate_lte: null
    })

    if (calendarComponentRef && calendarComponentRef.current) {
      calendarComponentRef.current.clear()
    }

    if (detailsRef && detailsRef.current) {
      detailsRef.current.removeAttribute('open')
    }
  }

  const handleTodayClick = () => {
    const today = new Date().toISOString().split('T')[0]

    setFilters({
      ...filterValues,
      paymentSettlementDate_gte: today,
      paymentSettlementDate_lte: `${today}T23:59:59`
    })

    if (detailsRef && detailsRef.current) {
      detailsRef.current.removeAttribute('open')
    }
  }

  return (
    <details className={styles.wrapper} ref={detailsRef}>
      <summary className={styles.summary}>
        <EventIcon />
        {
          filterValues && (filterValues.paymentSettlementDate_gte || filterValues.paymentSettlementDate_lte) ?
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              {filterValues.paymentSettlementDate_gte ? <span>{new Date(`${filterValues.paymentSettlementDate_gte.split('T')[0]}T00:00`).toLocaleDateString()}</span> : '...'}
              —
              {filterValues.paymentSettlementDate_lte ? <span>{new Date(`${filterValues.paymentSettlementDate_lte.split('T')[0]}T00:00`).toLocaleDateString()}</span> : <AllInclusiveIcon style={{ margin: '0 4px' }} />}
            </div>
            : 'Data de baixa'
        }
      </summary>
      <div className={styles.popoverContent}>
        <Calendar
          ref={calendarComponentRef}
          monthsInView={2}
          scroll={false}
          minDate={new Date('1970-01-01')}
          onChange={handleCalendarChange}
          ranges={range ? [range] : []} />
        <div className={styles.actions}>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Button
              variant="outlined"
              onClick={handleResetClick}>
              Limpar
            </Button>
            <Button
              onClick={handleTodayClick}>
              Hoje
            </Button>
          </div>
          <Button
            variant="contained"
            onClick={handleApplyClick}>
            Aplicar
          </Button>
        </div>
      </div>
    </details>
  )
}

export default DateFilter
