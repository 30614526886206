import { useState } from 'react'
import { Edit, TextInput, DateInput, NumberInput, BooleanInput, ArrayInput, SimpleFormIterator, useRecordContext, SimpleForm, TopToolbar, ListButton, FunctionField } from 'react-admin'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit'
import Box from '@mui/material/Box'
import ToolbarWithPermissions from '../../components/ToolbarWithPermissions'
import { IfCanAccess } from '../../rbac'
import ItemPax from '../List/ItemPax'
import ItemClient from '../List/ItemClient'
import ItemSupplier from '../List/ItemSupplier'
import ItemSupplierPaymentStatus from '../List/ItemSupplierPaymentStatus'
import ItemSupplierPrice from '../List/ItemSupplierPrice'
import ItemCloneButton from '../List/ItemCloneButton'
import ItemSupplierPriceFC from '../List/ItemSupplierPriceFC2'
import ItemSupplierStatus from '../List/ItemSupplierStatus'
import ItemCgStatus from '../List/ItemCgStatus'
import ItemClientMessageStatus from '../List/ItemClientMessageStatus'
import ItemSupplierMessageStatus from '../List/ItemSupplierMessageStatus'
import ItemPaymentStatus from '../List/ItemPaymentStatus'
import ItemSupplierPaymentDate from '../List/itemSupplierPaymentDate'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ItemTransaction from '../List/ItemTransaction'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  )
}


function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

const PostEditActions = () => {
  const record = useRecordContext()

  return (
    <TopToolbar>
      <ListButton label="Planning" />
      <ItemCloneButton />
      <Button variant="outlined" size="small" startIcon={<EditIcon />}
        href={`https://strapi.flynet.travel/admin/content-manager/collectionType/api::service.service/${record && record.id}`}
        target="_blank">
        Editar no Strapi
      </Button>
    </TopToolbar>
  )
}

const Aside = () => {
  const record = useRecordContext()

  const flightStr = (record && record.originAirCiaName) ? `${record.originAirCiaName} ${record.originAirNumber}` : null

  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Typography variant="h6">Cliente</Typography>
      {record && (
        <Typography variant="body2">
          <ItemClient />
        </Typography>
      )}
      <br />
      <Typography variant="h6">Fornecedor</Typography>
      {record && (
        <ItemSupplier />
      )}
      {flightStr && (
        <>
          <Typography variant="h6">Voo</Typography>
          <Typography variant="body2">
            <a href={`https://www.google.com/search?q=${flightStr}`} target="_blank" rel="noreferrer">{flightStr}</a> às {record.originAirTime}
          </Typography>
        </>
      )}
      <br />
      {record && (
        <Stack gap={1} marginTop={2}>
          <Typography variant="body2">
            <b>Criado em:</b> {new Date(record.createdAt).toLocaleString()}
          </Typography>
          <Typography variant="body2">
            <b>Atualizado em:</b> {new Date(record.updatedAt).toLocaleString()}
          </Typography>
        </Stack>
      )}
    </div>
  )
}

const EditPage = () => {
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <Edit aside={<Aside />} actions={<PostEditActions />}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="Tabs">
            <Tab label="Serviço" {...a11yProps(0)} />
            <Tab label="Financeiro" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <SimpleForm toolbar={<ToolbarWithPermissions />}>
            <>
              <Stack direction="row" spacing={2}>
                <TextInput disabled source="id" sx={{ width: '100px', marginTop: '0' }} />
                <DateInput source="serviceDate" label="Data do Serviço" />
                <TextInput source="checkInHour" label="H Início" sx={{ width: '100px' }} />
                <TextInput source="checkOutHour" label="H Fim" sx={{ width: '100px' }} />
                <Stack direction="row" spacing={1.5} sx={{ fontSize: '13px' }}>
                  <div>
                    <div>Msg Cli.</div>
                    <ItemClientMessageStatus />
                  </div>
                  <div>
                    <div>Msg Forn.</div>
                    <ItemSupplierMessageStatus label="MSG Forn." />
                  </div>
                  <div>
                    <div>Status Rsv.</div>
                    <ItemCgStatus label="Sts. Rsv." />
                  </div>
                  <div>
                    <div>Status Forn.</div>
                    <ItemSupplierStatus label="Sts. Forn." />
                  </div>
                  <div>
                    <div>Pgto Forn.</div>
                    <ItemSupplierPaymentStatus />
                  </div>
                  <div>
                    <div>Sts. Pgto.</div>
                    <ItemPaymentStatus />
                  </div>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2}>
                <TextInput source="serviceType" label="Produto" sx={{ width: '150px', marginTop: '0' }} />
                {/* <TextInput source="originName" label="Local Início" />
            <TextInput source="destinationName" label="Local Fim" /> */}
                <TextInput source="transportType" label="Tipo" />
                <TextInput source="cgBookingNumber" label="RSV Cangooroo" />
                <TextInput source="cgServiceId" label="ServiceId Cangooroo" />
              </Stack>
              <Stack direction="row" spacing={2} sx={{ marginBottom: '24px' }}>
                <fieldset style={{ minWidth: '250px' }}>
                  <legend>Local Início</legend>
                  <Stack spacing={2} sx={{ '.MuiFormHelperText-root': { display: 'none' } }}>
                    <TextInput source="cityName" label="Cidade, País" />
                    <TextInput source="additInfoOriginCity" />
                    <TextInput source="additInfoTimeOrigin" />
                    <TextInput source="additOriginCompanyName" />
                    <TextInput source="additOriginNumberInfo" />
                    <TextInput source="originAddress" />
                    <TextInput source="originAddressComplement" />
                    <TextInput source="originAddressType" />
                    <TextInput source="originAirCiaName" />
                    <TextInput source="originAirNumber" />
                    <TextInput source="originAirTime" />
                    <TextInput source="originLocation" />
                    <TextInput source="originName" />
                    <TextInput source="originNumber" />
                    <TextInput source="originType" />
                  </Stack>
                </fieldset>
                <fieldset style={{ minWidth: '250px' }}>
                  <legend>Local Fim</legend>
                  <Stack spacing={2} sx={{ '.MuiFormHelperText-root': { display: 'none' } }}>
                    <TextInput source="additDestinationCompanyName" />
                    <TextInput source="additDestinationNumberInfo" />
                    <TextInput source="additInfoDestinationCity" />
                    <TextInput source="additInfoTimeDestination" />
                    <TextInput source="destinationAddress" />
                    <TextInput source="destinationAddressComplement" />
                    <TextInput source="destinationAddressType" />
                    <TextInput source="destinationAirCiaName" />
                    <TextInput source="destinationAirNumber" />
                    <TextInput source="destinationAirTime" />
                    <TextInput source="destinationId" />
                    <TextInput source="destinationLocation" />
                    <TextInput source="destinationName" />
                    <TextInput source="destinationType" />
                  </Stack>
                </fieldset>
                <div>
                  <Stack direction="row" spacing={2} sx={{ marginBottom: '24px' }}>
                    <div>
                      <TextInput
                        multiline
                        rows={4}
                        source="observation"
                        variant="standard"
                        sx={{
                          bgcolor: '#f7f7f7',
                          padding: '8px',
                          paddingBottom: '0',
                          width: '350px'
                        }}
                      />
                    </div>
                    <div>
                      <TextInput
                        multiline
                        rows={4}
                        label="Voucher Obs."
                        source="cgObservation"
                        variant="standard"
                        disabled
                        sx={{
                          bgcolor: '#f7f7f7',
                          padding: '8px',
                          paddingBottom: '0',
                          width: '350px'
                        }}
                      />
                    </div>
                  </Stack>
                  <TextInput
                    multiline
                    rows={4}
                    label="Observação Reserva (fluxo de reservas)"
                    source="bookingFlow.observation"
                    variant="standard"
                    fullWidth
                    sx={{
                      bgcolor: '#f7f7f7',
                      padding: '8px',
                      paddingBottom: '0'
                    }}
                  />
                </div>
              </Stack>
              <br />
              <ItemPax />
              <ArrayInput source="pax" label="Passageiros">
                <SimpleFormIterator>
                  <TextInput source="name" />
                  <TextInput source="surname" />
                  <NumberInput source="age" />
                  <TextInput source="cpf" />
                  <TextInput source="phone" />
                  <TextInput source="email" />
                  <DateInput source="birthday" />
                  <BooleanInput source="whatsappAllowed" />
                </SimpleFormIterator>
              </ArrayInput>
            </>
          </SimpleForm>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <Box p={2}>
            <Stack gap={2}>
              <Box>
                <b>Valor Net: </b>
                <FunctionField sx={{ color: '#078F20', fontWeight: 'bold' }} render={record => record.price ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: record.price.currency }).format(record.price.value - record.agencyCommissionValue) : ''} />
              </Box>
              <Box>
                <span style={{ display: 'block', fontWeight: 'bold', marginBottom: '6px' }}>Valor Fornecedor (R$): </span>
                <ItemSupplierPrice canEdit={true} />
              </Box>
              <Box>
                <span style={{ display: 'block', fontWeight: 'bold', marginBottom: '6px' }}>Valor ME: </span>
                <ItemSupplierPriceFC canEdit={true} />
              </Box>
              <Box sx={{ opacity: 0.7, cursor: 'not-allowed' }}>
                <span style={{ display: 'block', fontWeight: 'bold', marginBottom: '6px' }}>Data Vencimento: </span>
                <ItemSupplierPaymentDate canEdit={false} />
              </Box>
              <Box>
                <span style={{ display: 'block', fontWeight: 'bold', marginBottom: '6px' }}>Margem Bruta: </span>
                <FunctionField render={record => (
                  <>
                    {record.price && record.price.value && record.supplierPrice && record.supplierPrice.value ?
                      <div>
                        {Math.round(((record.price.value - record.supplierPrice.value) / record.price.value) * 100)}%
                      </div>
                      : '-'}
                  </>
                )} />
              </Box>
              <IfCanAccess action="editTransactions">
                <Box>
                  <span style={{ display: 'block', fontWeight: 'bold', marginBottom: '6px' }}>Lançamento (saída):</span>
                  <ItemTransaction />
                </Box>
              </IfCanAccess>
            </Stack>
          </Box>
        </CustomTabPanel>
      </Box>
    </Edit>
  )
}

export default EditPage
