import { useEffect, useState } from "react"
import { Edit, SimpleForm, useRecordContext, DateInput, Toolbar, SaveButton, required } from "react-admin"
import { useDataProvider } from 'react-admin'

const EditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
)

const ItemTransaction = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [transaction, setTransaction] = useState(null)

  useEffect(() => {
    if (record) {
      const { cgBookingNumber, cgServiceId, serviceType } = record
      const ref = `${serviceType ? serviceType.toUpperCase() : ''}-${cgBookingNumber}-${cgServiceId}`
      dataProvider
        .getList('accounting-transactions', {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            invoiceNumber: ref,
            transactionType: 'out'
          }
        }).then(response => {
          setTransaction(response.data[0])
        }).catch(error => {
          console.error(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record])

  if (!transaction) return null

  return (
    <div>
      <span style={{ fontSize: '14px' }}>ID: {transaction.id}</span>
      <Edit sx={{ width: '360px' }} resource="accounting-transactions" id={transaction.id} title=" " redirect={false}>
        <SimpleForm toolbar={<EditToolbar />}>
          <DateInput source="paymentDueDate" fullWidth label="Data Vencimento" validate={required()} />
        </SimpleForm>
      </Edit>
    </div>
  )
}

export default ItemTransaction
