import { useState, useEffect } from 'react'
import { Layout } from 'react-admin'
import MyAppBar from './MyAppBar'
import MyMenu from './MyMenu'
import userContext from './contexts/userContext'
import authProvider from './authProvider'

const MyLayout = (props) => {
  const [user, setUser] = useState(authProvider.getUser)

  useEffect(() => {
    if (user && !user.affiliate && user.role.name === 'AFFILIATE') {
      authProvider.getAffiliate().then(affiliate => {
        setUser({ ...user, affiliate })
      })
    }
  }, [user])

  return (
    <userContext.Provider value={[user, setUser]}>
      <Layout {...props} appBar={MyAppBar} menu={MyMenu} />
    </userContext.Provider>
  )
}

export default MyLayout;
