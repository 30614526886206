import { usePermissions, useResourceContext } from 'react-admin'

// canAccess({ permissions, action: "edit", resource: "posts" })
const permissionMap = {
  ADMIN: [
    { action: ['create', 'read', 'edit', 'editTransactions'], resource: 'services' },
    { action: ['create', 'read', 'edit', 'validate', 'invalidate'], resource: 'accounting-transactions' }
  ],
  MANAGER: [
    { action: ['create', 'read', 'edit', 'editTransactions'], resource: 'services' }
  ],
  CONSULTANT: [
    { action: ['read'], resource: 'services' }
  ]
}

export const canAccess = ({ permissions, resource, action = 'read' }) => { // { permissions, action: 'create'|'read'|'edit'|'delete', resource: "posts" }
  const permission = permissionMap[permissions]
  if (permission) {
    const resourcePermission = permission.find(p => p.resource === resource)
    if (resourcePermission) {
      return resourcePermission.action.includes(action)
    }
  }

  return false
}

export const IfCanAccess = (props) => {
  const { children, action = 'read' } = props
  let { permissions } = usePermissions()
  let resource = useResourceContext()

  if (props.resource) {
    resource = props.resource
  }

  if (props.permissions) {
    permissions = props.permissions
  }

  if (permissions && resource && action) {
    if (canAccess({ permissions, resource, action })) {
      return children
    }
  }

  return null
}
