import { List, Authenticated, Datagrid, TextField, DateField, FunctionField, EditButton, SelectArrayInput, TextInput, Pagination } from 'react-admin'
import ImportChecker from '../imported-items/Checker'
import Alerts from '../services/Alerts'
import Box from '@mui/material/Box'
import { getPaxNumber } from '../services/utils'
import ItemPaymentStatus from '../services/List/ItemPaymentStatus'
import ItemSupplier from '../services/List/ItemSupplier'
import ItemClient from '../services/List/ItemClient'
import ItemCgStatus from '../services/List/ItemCgStatus'
import ItemSupplierStatus from '../services/List/ItemSupplierStatus'
import { cgStatusOptions, supplierStatusOptions, operationalStatusOptions, supplierReservationPriorityOptions } from '../services/utils'
import ItemSupplierReservationPriority from './ItemSupplierReservationPriority'
import ItemSupplierPrice from '../services/List/ItemSupplierPrice'
import ItemStartLocation from '../services/List/ItemStartLocation'
import ItemVoucherObs from '../services/List/ItemVoucherObs'
import ItemBookingNumber from '../services/List/ItemBookingNumber'
import SupplierFilter from '../services/List/SupplierFilter'
import ItemAssignee from './ItemAssignee'
import ItemObs from './ItemObs'

const todaysDate = (() => {
  const date = new Date()
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() - userTimezoneOffset).toISOString()
})()

const StatusChoiceWithColor = ({ choice }) => {
  return (
    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      <Box className='color-box' sx={{ backgroundColor: choice.color, width: '16px', height: '16px', borderRadius: '4px', marginRight: '8px' }}></Box>
      {choice.name}
    </Box>
  )
}

const postFilters = [
  <SelectArrayInput label="Status Reserva" source="cgStatus" alwaysOn
    choices={Object.keys(cgStatusOptions).map(key => {
      return {
        id: key,
        name: cgStatusOptions[key].label,
        color: cgStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Status Fornecedor" source="supplierStatus" alwaysOn
    choices={Object.keys(supplierStatusOptions).map(key => {
      return {
        id: key,
        name: supplierStatusOptions[key].label,
        color: supplierStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Tipo produto" source="serviceType" alwaysOn choices={[
    { id: 'Tour', name: 'Tour & Tickets' },
    { id: 'Transfer', name: 'Transfer' },
    { id: 'Hotel', name: 'Hotel' },
    { id: 'Car', name: 'Rent a Car' },
    { id: 'Flight', name: 'Aéreo' },
    { id: 'Insurances', name: 'Seguro' }
  ]} />,
  <SelectArrayInput label="Prioridade" source="supplierReservationPriority" alwaysOn
    choices={Object.keys(supplierReservationPriorityOptions).filter(key => key !== 'none').map(key => {
      return {
        id: key,
        name: supplierReservationPriorityOptions[key].label,
        color: supplierReservationPriorityOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <TextInput label="cgBookingNumber" source="cgBookingNumber" alwaysOn />,
  <TextInput label="cgServiceId" source="cgServiceId" alwaysOn />,
  <SelectArrayInput label="Status Operacional" source="operationalStatus"
    choices={Object.keys(operationalStatusOptions).map(key => {
      return {
        id: key,
        name: operationalStatusOptions[key].label,
        color: operationalStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  // <ReferenceInput source="[supplier][id][$in]" reference="suppliers" alwaysOn>
  //   <AutocompleteInput sx={{ width: '320px' }} label="Fornecedor"
  //     optionText={choice =>
  //       `#${choice.id} ${choice.trade_name}`
  //     }
  //     filterToQuery={
  //       searchText => ({ trade_name_containsi: searchText })
  //     } />
  // </ReferenceInput>
  <SupplierFilter alwaysOn />
]

const CustomPagination = () => <Pagination sx={{
  '.MuiTablePagination-spacer': {
    display: 'none'
  },
  '.MuiToolbar-root': {
    padding: 0
  },
  '.MuiPaginationItem-root .MuiPaginationItem-icon': {
    width: '28px',
    height: '28px',
    margin: '0 4px'
  }
}} rowsPerPageOptions={[10, 25, 50, 100]} />

const CustomList = (props) => {
  const meta = {}

  // populate relations
  meta[`populate`] = 'deep,3'

  return (
    <Authenticated>
      <ImportChecker />
      <Alerts />
      <List {...props}
        style={{ minWidth: '2200px' }}
        title="Fluxo de Reservas"
        resource="services:bookingFlow"
        queryOptions={{ meta: meta }}
        sort={{ field: 'reservationDate', order: 'DESC' }}
        debounce={500}
        pagination={<CustomPagination />}
        perPage={25}
        filterDefaultValues={{ serviceDate_gte: todaysDate.split('T')[0] }}
        filters={postFilters}>
        <Datagrid bulkActionButtons={false} sx={{
          "& .RaDatagrid-headerCell": {
            backgroundColor: "hsl(222 39% 92% / 1)",
            fontSize: '10px',
            fontWeight: '600',
            lineHeight: '1.4em',
            textTransform: 'uppercase'
          },
          "& .MuiTableRow-root:hover, & .MuiTableRow-root.clicked": {
            backgroundColor: "#fff2c3 !important",
            opacity: '1 !important'
          },
          "& .MuiTableCell-root": {
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderColor: 'hsl(0 0% 78% / 1)',
            padding: '5px 10px'
          },
          "& .MuiTableCell-root:first-of-type": {
            borderLeft: '0'
          },
        }}>
          <TextField source="id" />
          <Box label="Sts. Rsv.">
            <ItemCgStatus />
          </Box>
          <DateField source="reservationDate" label="Data RSV" />
          <DateField source="serviceDate" label="Data Serviço" />
          <Box label="Cliente" sx={{ width: '125px' }}>
            <ItemClient />
          </Box>
          <FunctionField label="No. RSV" source="cgBookingNumber" render={record =>
            <ItemBookingNumber record={record} />
          } />
          <Box label="Status Pgto">
            <ItemPaymentStatus />
          </Box>
          <FunctionField label="Tipo" source="serviceType" sx={{ fontSize: '12px', textTransform: 'uppercase', fontWeight: 'bold', color: '#555' }} render={record => {
            return `${record.serviceType} ${getPaxNumber(record)} PAX`
          }} />
          <Box label="Local Início">
            <ItemStartLocation />
          </Box>
          <Box label="Voucher Obs." sx={{ width: '290px' }}>
            <ItemVoucherObs />
          </Box>
          <Box label="Responsável" source="bookingFlow.assignee">
            <ItemAssignee />
          </Box>
          <Box label="Prioridade" source="supplierReservationPriority">
            <ItemSupplierReservationPriority />
          </Box>
          <FunctionField
            source="supplierReservationDeadline"
            label="Reservar até"
            render={record => {
              const supplierReservationDeadlineDate = record.supplierReservationDeadline ? new Date(record.supplierReservationDeadline.split('T')[0] + 'T00:00:00') : null
              return supplierReservationDeadlineDate
                ? <Box sx={{ minWidth: '90px', color: supplierReservationDeadlineDate <= new Date() ? 'red' : '' }}>
                  {supplierReservationDeadlineDate.toLocaleDateString()}
                </Box>
                : ''
            }} />
          <FunctionField label="Observação de Reserva" source="bookingFlow.observation" render={record => <ItemObs obs={record.bookingFlow?.observation} />} />
          <Box label="Sts. Forn.">
            <ItemSupplierStatus />
          </Box>
          <Box label="Fornecedor" sx={{ width: '190px' }}>
            <ItemSupplier />
          </Box>
          <FunctionField sx={{ color: '#078F20', fontWeight: 'bold', whiteSpace: 'nowrap' }} source="price.value"
            render={record => record.price ? `${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: record.price.currency }).format(record.price.value - record.agencyCommissionValue)}` : ''} label="Valor Net" />
          <Box label="Valor Fornecedor">
            <ItemSupplierPrice />
          </Box>
          <EditButton resource="services" />
        </Datagrid>
      </List>
    </Authenticated>
  )
}

export default CustomList