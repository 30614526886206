// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__9TBBI {
  width: 260px;
}

.styles_popoverContent__oGt4l {
  position: absolute;
  margin-top: -4px;
  width: 720px;
  top: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding: 8px 16px 16px 16px;
  border-radius: 12px;
  border: 1px solid #eee;
  z-index: 99;
}

.styles_summary__FrGdl {
  position: relative;
  display: flex;
  gap: 12px;
  align-items: center;
  border: 1px solid #ccc;
  padding: 14px;
  border-radius: 12px;
  margin-bottom: 4px;
  cursor: pointer;
}

.styles_actions__LAbew {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/services/List/DateFilter/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,SAAS;EACT,2CAA2C;EAC3C,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,8BAA8B;EAC9B,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  width: 260px;\n}\n\n.popoverContent {\n  position: absolute;\n  margin-top: -4px;\n  width: 720px;\n  top: 100%;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);\n  background-color: #fff;\n  padding: 8px 16px 16px 16px;\n  border-radius: 12px;\n  border: 1px solid #eee;\n  z-index: 99;\n}\n\n.summary {\n  position: relative;\n  display: flex;\n  gap: 12px;\n  align-items: center;\n  border: 1px solid #ccc;\n  padding: 14px;\n  border-radius: 12px;\n  margin-bottom: 4px;\n  cursor: pointer;\n}\n\n.actions {\n  display: flex;\n  gap: 20px;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__9TBBI`,
	"popoverContent": `styles_popoverContent__oGt4l`,
	"summary": `styles_summary__FrGdl`,
	"actions": `styles_actions__LAbew`
};
export default ___CSS_LOADER_EXPORT___;
