import React from 'react'
import { useRecordContext } from "react-admin"
import Box from '@mui/material/Box'
import { fixPlaceName, LightTooltip } from '../utils'

const ItemStartLocation = ({ ...props }) => {
  const recordFromContext = useRecordContext()
  const record = props.record || recordFromContext

  if (!record) return null

  return (
    <Box sx={{
      width: '232px',
      alignItems: 'center',
      gap: '8px',
      fontSize: '13px',
      lineHeight: '1.3em',
      textTransform: 'uppercase',
      '& svg': {
        width: '16px',
        height: '16px',
        fill: 'teal'
      }
    }}>
      {(() => {
        if (!record) {
          return ''
        }

        if (record.serviceType === 'Car') {
          return <>{record.vendorName} - {record.carModel} <br /> Retirada: {record.originName}</>
        }

        if (record.serviceType === 'Insurances') {
          return <>{record.tourName}</>
        }

        if (record.serviceType === 'Hotel') {
          return (
            <LightTooltip title={
              <React.Fragment>
                <b>Hotel: </b>{record.destinationName} <br />
                <b>Quarto: </b>{record.roomDescription} <br />
                <b>Regime: </b>{record.boardDescription} <br />
                <b>Tel: </b>{record.phone ? record.phone : '?'}
              </React.Fragment>
            }>
              <Box sx={{ lineHeight: '20px' }}>
                {record.destinationName && !record.destinationName.toLowerCase().includes('hotel') ? 'Hotel ' : ''}
                {record.destinationName}...
              </Box>
            </LightTooltip>
          )
        }

        if (record.serviceType === 'Tour') {
          return <>{record.tourName} {record.tourAddress ? `- ${record.tourAddress}` : ''}</>
        }

        return (
          <>
            {(record.originName || record.destinationName) ?
              <>
                {(() => {
                  if (record.originAddressType && record.originAddressType === 'Airport') {
                    return (
                      <>
                        Aeroporto {fixPlaceName(record.originName)}
                        {record.originAirNumber ?
                          <>
                            &nbsp;-&nbsp;
                            <a target="_blank" rel="noreferrer" title="Pesquisar voo" href={`https://www.google.com/search?q=${record.originAirNumber} flightaware.com`}>
                              {record.originAirNumber}
                            </a>
                          </>
                          : ''}
                      </>
                    )
                  }

                  if (record.originAddressType && record.originAddressType === 'Accomodation') {
                    const accomodationName = fixPlaceName(record.originLocation)
                    return `Hotel: ${accomodationName} - ${record.originAddress}`
                  }

                  if (record.originName || record.originAddress) {
                    const originStr = fixPlaceName(record.originName || record.originAddress)
                    return `
                    ${originStr}
                    ${record.originAddress && record.originAddress !== originStr ? ` - ${record.originAddress}` : ''}
                    ${record.originAddressComplement ? ` - ${record.originAddressComplement}` : ''}
                    ${record.additOriginNumberInfo && !originStr.includes(record.additOriginNumberInfo) ? ` - ${record.additOriginNumberInfo}` : ''}
                  `
                  } else {
                    return '?'
                  }
                })()}
              </>
              :
              <>{record.tourName ? record.tourName : ''}</>
            }
          </>
        )
      })()}
    </Box>
  )
}

export default ItemStartLocation
