import { useRecordContext, useCreate, useRedirect, useNotify, useDataProvider } from 'react-admin'
import Button from '@mui/material/Button'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const ItemCloneButton = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [create] = useCreate()
  const redirect = useRedirect()

  const getNewItemServiceId = async (record) => {
    const servicesRes = await dataProvider.getList('services', {
      meta: {
        'filters[cgBookingNumber][$eq]': record.cgBookingNumber.toString(),
        'filters[serviceType][$eq]': record.serviceType,
        'fields[0]': 'id',
        'fields[1]': 'cgServiceId'
      },
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'updatedAt', order: 'DESC' }
    })

    let highestServiceSubId = 0
    if (servicesRes && servicesRes.data && servicesRes.data.length) {
      servicesRes.data.forEach((service) => {
        const serviceId = parseInt(service.cgServiceId.split('-')[0])
        const serviceSubId = parseInt(service.cgServiceId.split('-')[1]) || 0

        if (serviceSubId && serviceId.toString() === record.cgServiceId.toString() && Number(serviceSubId) > highestServiceSubId) {
          highestServiceSubId = Number(serviceSubId)
        }
      })
    }

    return `${record.cgServiceId}-${highestServiceSubId + 1}`
  }

  const handleClick = async () => {
    const payload = { ...record }

    if (payload) {
      delete payload.id
      delete payload.unverifiedChanges
      payload.cgServiceId = await getNewItemServiceId(record)
    }

    create(
      'services',
      { data: payload },
      {
        onSuccess: (newItem) => {
          notify(`Serviço duplicado com sucesso! ${newItem.cgBookingNumber}-${newItem.cgServiceId}`, { type: 'success' })
          redirect(`/services/${newItem.id}`)
        }
      }
    )
  }

  return (
    <Button
      size="small"
      startIcon={<ContentCopyIcon />}
      onClick={handleClick}
    >
      Duplicar
    </Button>
  )
}

export default ItemCloneButton
