import { Create, TextInput, SimpleForm, TopToolbar, ListButton } from 'react-admin'
import Stack from '@mui/material/Stack'

const PostEditActions = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  )
}

const EditPage = () => {
  return (
    <Create actions={<PostEditActions />}
      title={`Nova Categoria Lançamento`}
      mutationMode="pessimistic">
      <SimpleForm>
        <Stack direction="row" spacing={2}>
          <TextInput source="name" label="Nome" sx={{ minWidth: '480px' }} />
        </Stack>
      </SimpleForm>
    </Create>
  )
}

export default EditPage
