import { useState, useEffect } from 'react'
import { fetchUtils } from 'react-admin'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider';

const HotelExpressPackageItem = ({ item, ...props }) => {
  const [product, setProduct] = useState(null)
  useEffect(() => {
    if (item && item.itemRef) {
      fetchUtils.fetchJson(`https://api.hotel.express/products/${item.itemRef}`)
        .then(response => {
          setProduct(response.json)
        })
    }
  }, [item])

  if (!product) {
    return null
  }

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: 'h6' }}
        title="Pacote hotel.express"
        action={
          <IconButton
            aria-label="Open"
            href={`https://hotel.express/packages/${product?.places?.find(place => place.type === 'city')?.slug}/${product?.slug}`}
            target="_blank">
            <OpenInNewIcon />
          </IconButton>
        }
      />
      <CardMedia
        component="img"
        height="194"
        image={product?.gallery?.[0]?.formats?.medium?.url || 'https://via.placeholder.com/194'}
        alt="Product Image"
      />
      <CardContent>
        <h4 style={{ margin: 0 }}>{product?.title}</h4>
        <Typography variant="subtitle2" sx={{ marginBottom: '1.5em' }}>
          {product?.places?.find(place => place.type === 'city')?.namePT}
        </Typography>

        <Stack gap={2} divider={<Divider flexItem />}>
          <Stack justifyContent="space-between" gap={1} direction="row">
            <div>A partir da data:</div>
            {item.options.startDate ?
              <b>
                {
                  new Date(`${item.options.startDate}T00:00:00`).toLocaleDateString('pt-BR', {
                    day: 'numeric',
                    weekday: 'short',
                    month: 'short',
                    year: 'numeric'
                  }).replace(/[,.]/g, '')
                    .split(' ')
                    .map((text, index) => {
                      return index === 0 ?
                        <span className="text-foreground" key={index}>{text}</span>
                        :
                        <span key={index}> {text}</span>
                    })
                }
              </b>
              :
              <b>Flexível</b>
            }
          </Stack>
          <Stack justifyContent="space-between" gap={1} direction="row">
            <>Quantidade de diárias:</>
            <b>{item.options.numberOfDays} {item.options.numberOfDays > 1 ? 'diárias' : 'diária'}</b>
          </Stack>
          <Stack justifyContent="space-between" gap={1} direction="row">
            <>Categoria do hotel:</>
            <b>{item.options.hotelCategory} estrelas</b>
          </Stack>
          {(() => {
            const paxByRoom = Object.keys(item.options).map((key) => {
              if (key.includes('paxNumber.room-')) {
                return item.options[key]
              }
              return undefined
            }).filter((value) => value !== undefined)

            return paxByRoom.map((value, i) => (
              <Stack justifyContent="space-between" gap={1} direction="row" key={i}>
                <div>Apartamento <b>{i + 1}</b>:</div>
                <b>{value} {value > 1 ? 'pessoas' : 'pessoa'}</b>
              </Stack>
            ))
          })()}
        </Stack>
      </CardContent>
    </Card>
  )
}

export default HotelExpressPackageItem
