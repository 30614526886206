import { useEffect, useState, useCallback } from 'react'
import { useList, useGetList, ListContextProvider, useDataProvider, Datagrid, TextField, Authenticated, FunctionField, fetchUtils } from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import BookingImportForm from './BookingImportForm'

/**
 * steps:
 * 1. get list of items to import (from cangooroo)
 * 2. check if item is already imported on strapi
 * 2.1. for each item, check if it's already on importedItems object.
 * 2.2. if it's not, group bookingNumbers of each service
 * 2.3. for each bookingNumber, get services imported on strapi
 * 2.4. populate importedItems object with serviceId, status (ok | notFound | error | loading) and serviceType
 */
const CustomList = (props) => {
  const dataProvider = useDataProvider()
  const [currentPage, setCurrentPage] = useState(1)
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0]
  })

  // allow to force state update
  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const [importedItems, setImportedItems] = useState([]) // { serviceId: '9', serviceType: 'TOUR', source: 'cangooroo', status: 'ok' }

  const { data, isLoading } = useGetList('services/import-audit-items', {
    meta: {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate
    }
  })

  const listContext = useList({ data, isLoading, perPage: 32, page: currentPage })

  useEffect(() => {
    if (listContext) {
      if (listContext.page !== currentPage) {
        listContext.setPage(currentPage)
      }
    }
  }, [currentPage, listContext])

  useEffect(() => {
    setCurrentPage(1)
  }, [data])

  useEffect(() => {
    if (listContext && listContext.data && listContext.data.length) {
      const bookingNumbers = {}

      listContext.data.forEach(item => {
        const foundItem = importedItems.find(importedItem => importedItem.serviceId === item.serviceId.toString() &&
          importedItem.source === item.source.toLowerCase() &&
          importedItem.serviceType === item.serviceType)

        if (!foundItem) {
          bookingNumbers[item.bookingId] = true
        }
      })

      if (Object.keys(bookingNumbers).length) {
        dataProvider.getList('services/import-audit-items/check', {
          meta: {
            cgBookingNumbers: Object.keys(bookingNumbers).join(',')
          },
          pagination: { page: 1, perPage: 10 },
          sort: { field: 'updatedAt', order: 'DESC' }
        }).then(({ data: checkRes, total }) => {
          const foundItems = []
          const notFoundItems = []

          listContext.data.forEach(item => {
            const foundItem = checkRes.find(checkItem => checkItem.cgServiceId.toString() === item.serviceId.toString() && checkItem.serviceType === item.serviceType)

            if (foundItem) {
              foundItems.push({
                bookingId: item.bookingId,
                serviceId: item.serviceId.toString(),
                serviceType: item.serviceType,
                source: item.source.toLowerCase(),
                status: 'ok'
              })
            } else {
              notFoundItems.push({
                bookingId: item.bookingId,
                serviceId: item.serviceId.toString(),
                serviceType: item.serviceType,
                source: item.source.toLowerCase(),
                status: 'notFound'
              })
            }
          })

          setImportedItems([...importedItems, ...foundItems, ...notFoundItems])
        }).catch(err => {
          console.error(err)
        })
      }
    }
  }, [listContext, importedItems, dataProvider])

  const handleDateChange = (e) => {
    const timer = setTimeout(() => {
      setDateRange({
        ...dateRange,
        [e.target.name]: e.target.value
      })
    }, 500)

    return () => clearTimeout(timer)
  }

  const handleUpdateClick = (e, record) => {
    const importedItemsAlt = [...importedItems]
    const itemIndexes = []

    importedItemsAlt.forEach((item, idx) => {
      if (record.bookingId === item.bookingId) {
        itemIndexes.push(idx)
      }
    })

    itemIndexes.forEach(idx => {
      importedItemsAlt[idx].status = 'loading'
    })

    setImportedItems(importedItemsAlt)

    const url = `${process.env.REACT_APP_DATA_PROVIDER}/import-services/${record.bookingId}`

    let reqStatus = 'error'
    fetchUtils.fetchJson(url).then(async (res) => {
      reqStatus = 'ok'
    }).catch(error => {
      console.error(error)
    }).finally(() => {
      itemIndexes.forEach(idx => {
        importedItemsAlt[idx].status = reqStatus
      })

      setImportedItems(importedItemsAlt)
      forceUpdate()
    })
  }

  return (
    <Authenticated>
      <ListContextProvider value={listContext}>
        <Typography variant="h5" sx={{ display: 'flex', margin: '20px 0 16px', gap: '12px', alignItems: 'center' }}>
          <CloudDoneIcon size={24} />
          Auditoria de importação
        </Typography>
        <Box sx={{
          display: 'flex',
          gap: '12px',
          alignItems: 'center',
          margin: '8px 0 28px',
          'input': {
            padding: '8px',
            borderRadius: '6px',
            border: '1px solid #bbb',
            maxWidth: '130px'
          }
        }}>
          <span style={{ fontSize: '14px' }}>Data Serviço:</span>
          <input type='date' name="startDate" defaultValue={dateRange.startDate} onChange={handleDateChange} />
          <input type='date' name="endDate" defaultValue={dateRange.endDate} onChange={handleDateChange} />
          {data ? <b>{data.length} itens</b> : ''}
        </Box>
        <Datagrid bulkActionButtons={false}>
          <TextField source="source" />
          <TextField source="bookingId" label="BookingId" />
          <TextField source="serviceId" label="ServiceId" />
          <TextField source="lastUpdate" />
          <TextField source="bookingDate" />
          <TextField source="serviceDate" />
          <TextField source="serviceType" />
          <FunctionField label="Status" render={record => {
            const foundItem = importedItems.find(item => item.serviceId === record.serviceId.toString() && item.source === record.source.toLowerCase() && item.serviceType === record.serviceType)

            if (foundItem && foundItem.status !== 'loading') {
              return (
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  {foundItem.status === 'ok' ?
                    <>
                      <CheckCircleIcon sx={{ color: 'green' }} />
                      Importado
                    </>
                    :
                    <>
                      <WarningIcon sx={{ color: 'red' }} />
                      {foundItem.status === 'notFound' ? 'Não importado' : 'Erro'}
                    </>
                  }
                </Box>
              )
            } else {
              return <CircularProgress size={20} />
            }
          }} />
          <FunctionField render={record => {
            const foundItem = importedItems.find(item => item.serviceId === record.serviceId.toString() && item.source === record.source.toLowerCase() && item.serviceType === record.serviceType)

            if (foundItem && foundItem.status !== 'loading') {
              return (
                <Button variant={foundItem.status === 'notFound' ? 'contained' : 'outlined'} size="small" onClick={(e) => handleUpdateClick(e, record)}>
                  {foundItem.status === 'notFound' ? 'Importar' : 'Atualizar'}
                </Button>
              )
            }

            return null
          }} />
        </Datagrid>
      </ListContextProvider>
      <Box sx={{ display: 'flex', gap: '12px', margin: '20px 0' }}>
        <Button variant='contained' disabled={!listContext.hasPreviousPage} onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}>
          <ChevronLeftIcon />
        </Button>
        <Button variant='contained' disabled={!listContext.hasNextPage} onClick={() => setCurrentPage(currentPage + 1)}>
          <ChevronRightIcon />
        </Button>
      </Box>
      <Card sx={{ marginTop: '32px', maxWidth: '720px' }}>
        <CardContent>
          <Typography variant="h6">
            Importação Manual
          </Typography>
          <BookingImportForm />
        </CardContent>
      </Card>
    </Authenticated>
  )
}

export default CustomList