import styled from 'styled-components'
import { InView } from 'react-intersection-observer'

export const Wrapper = styled.div`
  position: relative;

  &.scroll {
    overflow-y: scroll;

    .prev-button,
    .next-button {
      display: none;
    }

    .observer {
      margin-top: -100px;
    }
  }

  .prev-button,
  .next-button {
    position: absolute;
    top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 0;
    border: 0;
    width: 36px;
    height: 36px;
    /* box-shadow: 0 1px 5px rgb(0 0 0 / 16%); */
    color: #0f6b9b;
    z-index: 1;
    cursor: pointer;

    svg {
      width: 24px;
    }
  }

  .prev-button {
    left: 8px;

    svg {
      transform: rotate(90deg);
    }
  }

  .next-button {
    right: 8px;

    svg {
      transform: rotate(-90deg);
    }
  }
`

export const MonthsWrapper = styled(InView)`
  display: grid;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;

    .month {
      &:after {
        content: '';
        position: absolute;
        top: 30%;
        bottom: 20%;
        width: 1px;
        background-color: #eee;
        right: 0;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

`