import { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import ReloadIcon from '@mui/icons-material/Refresh'

// Mensagem de alerta para quando os serviços corresponderem a todas as condições abaixo:
// - pagamento fornecedor não está pago
// - status fornecedor é confirmado
// - data pagamento fornecedor não está preenchida
// neste caso a seguinte mensagem deve ser exibida:
// "X serviços estão confirmados, não estão pagos e não possuem data de pagamento para o fornecedor."
const Alerts = () => {
  const dataProvider = useDataProvider()
  const startDate = new Date().toISOString().split('T')[0]
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getItems = async () => {
    setIsLoading(true)

    dataProvider.getList('services', {
      pagination: { page: 1, perPage: 25 },
      sort: { field: 'id', order: 'ASC' },
      meta: {
        'fields[0]': 'id',
        'fields[1]': 'cgBookingNumber',
        'fields[2]': 'cgServiceId',
      },
      filter: {
        supplierPaymentStatus: 'notPaid',
        supplierStatus: 'confirmed',
        supplierPaymentDate_null: true,
        serviceDate_gte: startDate
      }
    }).then((res) => {
      setItems(res.data)
    }).catch(error => {
      console.error(error)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    getItems()

    const interval = setInterval(() => {
      getItems()
    }, 600000)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (items && items.length > 0) {
    return (
      <>
        <Alert severity="error" sx={{ marginTop: '10px' }}>
          <Stack direction="row" gap="12px" alignItems="center">
            <Box><b>{items.length}</b> serviços estão confirmados, não estão pagos e não possuem data de pagamento para o fornecedor.</Box>
            <Box>
              {items.map((item, index) => {
                return (
                  <span key={index}>
                    <a href={`/#/services/${item.id}`} key={index} target="_blank" rel="noreferrer">{item.cgBookingNumber}-{item.cgServiceId}</a>
                    {index < items.length - 1 && ', '}
                  </span>
                )
              })}
            </Box>
            <Box sx={{ marginLeft: '20px', width: '60px', margin: '-16px 0 -16px 20px' }}>
              {isLoading ?
                <CircularProgress size={16} />
                : <IconButton
                  size="small"
                  onClick={() => {
                    getItems()
                  }}>
                  <ReloadIcon />
                </IconButton>}
            </Box>
          </Stack>
        </Alert>
      </>
    )
  }

  return null
}

export default Alerts
