import { Create, TextInput, NumberInput, TabbedForm, FormTab, TopToolbar, ListButton, ArrayInput, BooleanInput, SimpleFormIterator } from 'react-admin'
import Stack from '@mui/material/Stack'

const CreateActions = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  )
}

const CreatePage = () => {
  return (
    <Create actions={<CreateActions />}>
      <TabbedForm>
        <FormTab label="Fornecedor" sx={{ '.input-stack .MuiFormControl-root:first-of-type': { margin: 0 } }}>
          <Stack className="input-stack" direction={'row'} spacing={2}>
            <TextInput source="company_name" label="Razão social" />
            <TextInput source="trade_name" label="Nome fantasia" />
            <TextInput source="cpf" />
            <TextInput source="cnpj" />
            <NumberInput source="rating" sx={{ width: '130px' }} />
          </Stack>

          <Stack className="input-stack" direction={'row'} spacing={2}>
            <TextInput source="inscrEstadual" />
            <TextInput source="inscrMunicipal" />
            <TextInput source="cadastur" />
            <TextInput source="accounting_code" />
            <TextInput source="cgClientId" label="Cangooroo ClientId" />
          </Stack>

          <Stack className="input-stack" direction={'row'} spacing={2}>
            <TextInput source="email" label="E-mail principal" />
            <TextInput source="phone1" label="Telefone 1" />
            <TextInput source="phone2" label="Telefone 2" />
            <TextInput source="zipCode" label="CEP" />
            <TextInput source="address" label="Endereço" />
          </Stack>

          <ArrayInput source="channels" label="Canais (redes sociais, sites...)">
            <SimpleFormIterator inline>
              <TextInput source="url" label="Link" />
              <BooleanInput source="isActive" options={{
                checked: true
              }} />
            </SimpleFormIterator>
          </ArrayInput>

          <ArrayInput source="emails" label="E-mails">
            <SimpleFormIterator inline>
              <TextInput source="email" label="Endereço de E-mail" />
              <TextInput source="name" label="Nome/Setor" />
            </SimpleFormIterator>
          </ArrayInput>

          <Stack direction={'row'} spacing={2} width="100%">
            <TextInput source="obs" multiline rows={4} fullWidth />
            <TextInput source="billingObs" multiline rows={4} fullWidth />
          </Stack>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default CreatePage
