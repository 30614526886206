import { useState, useEffect, useRef } from 'react'
import { useUpdate, useRecordContext } from 'react-admin'
import { TextInput, Form, Button } from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const ItemAssignee = () => {
  const record = useRecordContext()
  const ref = useRef(null)
  const [update, { isLoading }] = useUpdate()
  const [value, setValue] = useState(null)
  const [shouldSave, setShouldSave] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)

  useEffect(() => {
    if (isEditOpen && !shouldSave) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsEditOpen(false)
        }
      }

      document.addEventListener('click', handleClickOutside, true)

      return () => {
        document.removeEventListener('click', handleClickOutside, true)
      }
    }
  }, [isEditOpen, shouldSave])

  const handleInputChange = (e) => {
    setValue(e.target.value.toString())
    setShouldSave(e.target.value !== record.bookingFlow?.assignee)
  }

  const handleFormSubmit = (data) => {
    update(
      'services',
      {
        id: data.id,
        data: {
          bookingFlow: {
            ...record.bookingFlow,
            assignee: value
          }
        },
        previousData: record
      },
      {
        onSuccess: () => {
          setShouldSave(false)
          setIsEditOpen(false)
        }
      }
    )
  }

  return (
    <Box ref={ref}>
      {isEditOpen ?
        <Form onSubmit={handleFormSubmit}>
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <TextInput source="bookingFlow.assignee" label="" hiddenLabel onInput={handleInputChange} value={value} sx={{
              '& .MuiInputBase-root': {
                borderRadius: '4px',
                margin: '0 0 -20px'
              },
              '& .MuiInputBase-input': {
                padding: '3px 6px',
                fontSize: '0.875rem'
              }
            }} />
            {shouldSave &&
              <Button disableElevation variant="contained" type="submit" sx={{
                padding: 0,
                height: '26px',
                minWidth: '32px',
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: '600',
                borderRadius: '4px',
                position: 'absolute',
                top: '8px',
                right: '-24px',
                'span': {
                  margin: 0
                }
              }}>
                {isLoading ? <CircularProgress size={'18px'} sx={{ color: 'white' }} /> : <>OK</>}
              </Button>
            }
          </div>
        </Form>
        :
        <Button
          onClick={() => {
            setIsEditOpen(true)
          }}
          sx={{
            minHeight: '28px',
            fontWeight: 600,
            textTransform: 'none',
            border: '1px solid #aaa',
            padding: '4px 8px',
            width: '100%',
            fontSize: '13px',
            justifyContent: 'flex-start'
          }}>
          <>{value || record.bookingFlow?.assignee}</>
        </Button>
      }
    </Box>
  )
}

export default ItemAssignee