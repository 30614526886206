// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additional-item-value-input {
  border: 0;
  outline: 0;
  width: 36px;
  color: #333;
  -moz-appearance: textfield;
  font-size: '14px';
}

.additional-item-value-input::-webkit-outer-spin-button,
.additional-item-value-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/transfer-routes/OptionCard/styles.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,WAAW;EACX,WAAW;EACX,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX","sourcesContent":[".additional-item-value-input {\n  border: 0;\n  outline: 0;\n  width: 36px;\n  color: #333;\n  -moz-appearance: textfield;\n  font-size: '14px';\n}\n\n.additional-item-value-input::-webkit-outer-spin-button,\n.additional-item-value-input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
