import { List, SelectArrayInput, Authenticated, Pagination } from 'react-admin'
import { cgStatusOptions, supplierStatusOptions, supplierPaymentStatusOptions, operationalStatusOptions } from '../utils'
import ImportChecker from '../../imported-items/Checker'
import Alerts from '../../services/Alerts'
import { TextInput } from 'react-admin'
import DateFilter from './DateFilter'
import SearchForm from './SearchForm'
import Box from '@mui/material/Box'
import Item from './Item'
import SupplierFilter from './SupplierFilter'

const todaysDate = (() => {
  const date = new Date()
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() - userTimezoneOffset).toISOString()
})()

const StatusChoiceWithColor = ({ choice }) => {
  return (
    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      <Box className='color-box' sx={{ backgroundColor: choice.color, width: '16px', height: '16px', borderRadius: '4px', marginRight: '8px' }}></Box>
      {choice.name}
    </Box>
  )
}

const postFilters = [
  <DateFilter alwaysOn />,
  // <DateInput label="Data Serviço (de)" sx={{ width: '210px' }} source="serviceDate_gte" alwaysOn />,
  // <DateInput label="Data Serviço (até)" sx={{ width: '210px' }} source="serviceDate_lt" alwaysOn />,
  <SelectArrayInput label="Status Reserva" source="cgStatus" alwaysOn
    choices={Object.keys(cgStatusOptions).map(key => {
      return {
        id: key,
        name: cgStatusOptions[key].label,
        color: cgStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Status Fornecedor" source="supplierStatus" alwaysOn
    choices={Object.keys(supplierStatusOptions).map(key => {
      return {
        id: key,
        name: supplierStatusOptions[key].label,
        color: supplierStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Status Pgto. Fornecedor" source="supplierPaymentStatus" alwaysOn
    choices={Object.keys(supplierPaymentStatusOptions).map(key => {
      return {
        id: key,
        name: supplierPaymentStatusOptions[key].label,
        color: supplierPaymentStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Tipo produto" source="serviceType" alwaysOn choices={[
    { id: 'Tour', name: 'Tour & Tickets' },
    { id: 'Transfer', name: 'Transfer' },
    { id: 'Hotel', name: 'Hotel' },
    { id: 'Car', name: 'Rent a Car' },
    { id: 'Flight', name: 'Aéreo' },
    { id: 'Insurances', name: 'Seguro' }
  ]} />,
  <TextInput label="cgBookingNumber" source="cgBookingNumber" alwaysOn />,
  <TextInput label="cgServiceId" source="cgServiceId" alwaysOn />,
  <SelectArrayInput label="Status Operacional" source="operationalStatus"
    choices={Object.keys(operationalStatusOptions).map(key => {
      return {
        id: key,
        name: operationalStatusOptions[key].label,
        color: operationalStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  // <ReferenceInput source="[supplier][id][$in]" reference="suppliers" alwaysOn>
  //   <AutocompleteInput sx={{ width: '320px' }} label="Fornecedor"
  //     optionText={choice =>
  //       `#${choice.id} ${choice.trade_name}`
  //     }
  //     filterToQuery={
  //       searchText => ({ trade_name_containsi: searchText })
  //     } />
  // </ReferenceInput>,
  <SupplierFilter alwaysOn />,
  <SearchForm alwaysOn />,
]

const CustomPagination = () => <Pagination sx={{
  '.MuiTablePagination-spacer': {
    display: 'none'
  },
  '.MuiToolbar-root': {
    padding: 0
  },
  '.MuiPaginationItem-root .MuiPaginationItem-icon': {
    width: '28px',
    height: '28px',
    margin: '0 4px'
  }
}} rowsPerPageOptions={[10, 25, 50, 100]} />

const CustomList = (props) => {
  const meta = {}

  // populate relations
  meta[`populate`] = 'deep,3'

  return (
    <Authenticated>
      <ImportChecker />
      <Alerts />
      <List {...props}
        style={{ marginTop: '16px', minWidth: '2500px' }}
        queryOptions={{ meta: meta }}
        sort={{ field: 'serviceDate', order: 'ASC' }}
        filterDefaultValues={{ serviceDate_gte: todaysDate.split('T')[0] }}
        filters={postFilters}
        debounce={500}
        pagination={<CustomPagination />}
        perPage={25}>
        <Item />
      </List>
    </Authenticated>
  )
}

export default CustomList