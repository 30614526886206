import { Edit, TextInput, BooleanInput, useRecordContext, TabbedForm, FormTab, TopToolbar, ListButton, ArrayInput, SimpleFormIterator } from 'react-admin'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import ClientRestrospective from './Retrospective'

const PostEditActions = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  )
}

const Aside = () => {
  const record = useRecordContext()
  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Typography variant="h6">Detalhes</Typography>
      {record && (
        <>
          <Typography variant="body2">
            createdAt: {record.createdAt}
          </Typography>
          <br />
          <Typography variant="body2">
            updatedAt: {record.updatedAt}
          </Typography>
        </>
      )}
    </div>
  )
}

const EditPage = () => {

  return (
    <Edit aside={<Aside />} actions={<PostEditActions />}>
      <TabbedForm>
        <FormTab label="Cliente" sx={{ '.input-stack .MuiFormControl-root:first-of-type': { margin: 0 } }}>
          <Stack className="input-stack" direction={'row'} spacing={2}>
            <TextInput disabled source="id" sx={{ width: '100px' }} />
            <TextInput source="company_name" label="Razão social" />
            <TextInput source="trade_name" label="Nome fantasia" />
            <TextInput source="cpf" />
            <TextInput source="cnpj" />
            <BooleanInput source="canPayLater" label="Faturado" />
          </Stack>

          <Stack className="input-stack" direction={'row'} spacing={2}>
            <TextInput source="inscrEstadual" />
            <TextInput source="inscrMunicipal" />
            <TextInput source="cadastur" />
            <TextInput source="accounting_code" />
            <TextInput source="cgClientId" label="Cangooroo ClientId" />
          </Stack>

          <Stack className="input-stack" direction={'row'} spacing={2}>
            <TextInput source="email" label="E-mail principal" />
            <TextInput source="phone1" label="Telefone 1" />
            <TextInput source="phone2" label="Telefone 2" />
            <TextInput source="zipCode" label="CEP" />
            <TextInput source="address" label="Endereço" />
          </Stack>

          <ArrayInput source="channels" label="Canais (redes sociais, sites...)">
            <SimpleFormIterator inline>
              <TextInput source="url" label="Link" />
              <BooleanInput source="isActive" options={{
                checked: true
              }} />
            </SimpleFormIterator>
          </ArrayInput>

          <ArrayInput source="emails" label="E-mails">
            <SimpleFormIterator inline>
              <TextInput source="email" label="Endereço de E-mail" />
              <TextInput source="name" label="Nome/Setor" />
            </SimpleFormIterator>
          </ArrayInput>

          <Stack direction={'row'} spacing={2} width="100%">
            <TextInput source="obs" multiline rows={4} fullWidth />
            <TextInput source="billingObs" multiline rows={4} fullWidth />
          </Stack>
        </FormTab>
        <FormTab label="Retrospectiva">
          <ClientRestrospective />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default EditPage
