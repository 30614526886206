import { useContext } from 'react'
import { AppBar } from 'react-admin'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import userContext from './contexts/userContext'
import MyUserMenu from './MyUserMenu'

const MyAppBar = ({ ...props }) => {
  const [user] = useContext(userContext)

  return (
    <AppBar
      userMenu={<MyUserMenu />}
      sx={{
        "& .RaAppBar-title": {
          flex: 'auto',
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
      }}
      {...props}
    >
      <Typography
        variant="h6"
        color="inherit"
        // className={'RaAppBar-title'}
        id="react-admin-title"
      />
      <Box sx={{ flexGrow: 1, textAlign: 'center', paddingTop: '4px' }}>
        <a href="/" style={{ textDecoration: 'none', color: 'currentcolor' }} title={`Version: ${process.env.REACT_APP_VERSION}`}>
          {user && user.role.name === 'AFFILIATE' ?
            <>
             {user.affiliate ? <Box sx={{ fontSize: '20px', fontWeight: 'bold' }}>{user.affiliate.trade_name}</Box> : ''}
            </>
          : <img src="/logo192.png" alt="Logo" width="143px" />}
        </a>
      </Box>
      <span className={'.RaAppBar-spacer'} />
    </AppBar>
  )
}

export default MyAppBar;
