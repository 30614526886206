import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useNotify, useUpdate, TextInput, useEditController, useCreateController, SimpleForm, DateTimeInput, ReferenceInput, AutocompleteInput, NumberInput, useRedirect } from 'react-admin'
import { serviceMap, paymentConditionMap } from './utils'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import ServiceList from './ServiceList'
import CustomToolbar from './Toolbar'

const EditPage = ({ mode }) => {
  const { id } = useParams()
  const notify = useNotify()
  const redirect = useRedirect()
  const [update] = useUpdate()
  const [services, setServices] = useState([])
  const [record, setRecord] = useState(null)
  const [paymentCondition, setPaymentCondition] = useState(null)
  const [customPaymentCondition, setCustomPaymentCondition] = useState(null)

  const transform = data => {
    return {
      ...data,
      services: services,
      paymentConditions: paymentCondition && paymentCondition !== 'other' ? paymentCondition : customPaymentCondition
    }
  }

  const onSuccess = (data) => {
    notify(`Salvo com sucesso!`, { type: 'success' })
    redirect(`/quotations/${data.id}`)
  }

  const saveControllerParams = { id, transform: transform, mutationOptions: { onSuccess }, queryOptions: { meta: { populate: 'deep,3' } } }
  const useSaveController = mode === 'edit' ? useEditController : useCreateController
  const { record: entry, save } = useSaveController(saveControllerParams)

  useEffect(() => {
    if (entry) {
      setRecord({
        ...entry,
        client: entry.client?.data?.id
      })
    }
  }, [entry])

  useEffect(() => {
    if (record) {
      if (record.services) {
        setServices(record.services)
      }

      if (record.paymentConditions) {
        if (paymentConditionMap[record.paymentConditions]) {
          setPaymentCondition(record.paymentConditions)
        } else {
          setPaymentCondition('other')
          setCustomPaymentCondition(record.paymentConditions)
        }
      }
    }
  }, [record])

  const handleServiceInputChange = (e) => {
    const { checked, name } = e.target

    if (checked) {
      setServices([...services, { name }])
    } else {
      setServices(services.filter(service => service.name !== name))
    }
  }

  const handlePaymentConditionInputChange = (e) => {
    const { checked, name } = e.target

    if (checked) {
      setPaymentCondition(name)
    } else {
      setPaymentCondition(null)
    }
  }

  const handleCustomPaymentConditionInputChange = (e) => {
    const { value } = e.target

    setPaymentCondition('other')
    setCustomPaymentCondition(value)
  }

  const customSave = initialData => {
    const data = transform(initialData)

    update(
      'quotations',
      { id: record.id, data, previousData: record },
      {
        onSuccess: () => {
          onSuccess(data)
        },
        onError: () => {
          notify(`Erro ao salvar!`, { type: 'error' })
        }
      }
    )
  }

  return (
    <>
      <h3>{mode === 'edit' ? `Editar orçamento #${id}` : 'Novo orçamento'}</h3>
      <Box display={{ xs: 'block', sm: 'flex', width: '100%', gap: '16px' }}>
        <Box sx={{ width: '820px', flexShrink: 0, backgroundColor: 'white', 'form': { boxShadow: '0px 2px 8px 0px #00000029', borderRadius: '8px' } }}>
          <SimpleForm
            record={record}
            toolbar={<CustomToolbar />}
            onSubmit={mode === 'edit' ? (data) => customSave(data) : save}>
            <Stack direction="row" spacing={2}>
              <Box sx={{ width: '500px', fontSize: '13px', '.MuiFormControl-root': { margin: 0 } }}>
                <ReferenceInput
                  source="client"
                  reference="clients">
                  <AutocompleteInput
                    label="Agência"
                    optionText="company_name"
                    filterToQuery={
                      searchText => ({ company_name_contains: searchText })
                    } />
                </ReferenceInput>
              </Box>
              <NumberInput source="commission" label="Comissão %" />
            </Stack>
            <Stack direction="row" spacing={2} sx={{ marginBottom: '16px' }}>
              <FormControl>
                <FormLabel component="legend">Produtos</FormLabel>
                <FormGroup>
                  {Object.keys(serviceMap).map((serviceName, index) => {
                    return (
                      <FormControlLabel
                        control={<Checkbox
                          checked={services.some(e => e.name === serviceName)}
                          onChange={handleServiceInputChange}
                          name={serviceName} />}
                        label={serviceMap[serviceName]}
                        key={index} />
                    )
                  })}
                </FormGroup>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ marginBottom: '16px' }}>
              <FormControl>
                <FormLabel component="legend">Condições de pagamento</FormLabel>
                <FormGroup>
                  {Object.keys(paymentConditionMap).map((paymentConditionName, index) => {
                    return (
                      <FormControlLabel
                        control={<Checkbox
                          checked={paymentCondition === paymentConditionName}
                          onChange={handlePaymentConditionInputChange}
                          name={paymentConditionName} />}
                        label={paymentConditionMap[paymentConditionName]}
                        key={index} />
                    )
                  })}

                  <Stack direction="row" spacing={2} alignItems={'center'}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={paymentCondition === 'other'}
                          onChange={handlePaymentConditionInputChange}
                          name={'other'} />}
                    />
                    <input
                      placeholder={'Outro'}
                      value={customPaymentCondition || ''}
                      onChange={handleCustomPaymentConditionInputChange}
                      style={{ width: '100%', margin: '0 0 0 -12px', padding: '12px', borderRadius: '8px', border: '1px solid #bbb' }} />
                  </Stack>
                </FormGroup>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ marginTop: '16px' }}>
              <DateTimeInput source="expiry" label="Prazo para confirmação" sx={{ margin: 0 }} />
              <TextInput source="seller" label="Vendedor" />
            </Stack>
            <ServiceList services={services} onChange={(data) => {
              setServices(data)
            }} />
          </SimpleForm>
        </Box>
        <Box style={{ width: '100%' }}>
          {record && (
            <>
              <Typography variant="body2">
                createdAt: {record.createdAt}
              </Typography>
              <br />
              <Typography variant="body2">
                updatedAt: {record.updatedAt}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default EditPage
