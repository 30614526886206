import React, { useCallback, useMemo, useState } from 'react'
import {
  TextInput,
  NumberInput,
  Form,
  SaveButton,
  required,
  useCreate,
  SaveContextProvider
} from 'react-admin'
import CurrencyInput from '../../components/CurrencyInput'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

const CieloForm = ({ ...props }) => {
  const [create, { isLoading }] = useCreate()
  const [link, setLink] = useState()
  const [error, setError] = useState()
  const [controlledValues, setControlledValues] = useState({
    price: 0, // CurrencyInput is not a react-admin input component, so we need to control its value
  })

  const handleControlledInputChange = (name, value) => {
    setControlledValues({ ...controlledValues, [name]: value })
  }

  const handleSave = useCallback((values) => {
    values.price = controlledValues.price * 100 // convert to cents

    if (!values.price) return

    const payload = {
      OrderNumber: values.OrderNumber,
      type: 'Service',
      name: values.name,
      description: values.description,
      price: Number(values.price),
      maxNumberOfInstallments: Number(values.maxNumberOfInstallments),
      quantity: Number(values.quantity),
      shipping: {
        "type": "WithoutShipping",
      },
      softDescriptor: values.softDescriptor
    }

    create('cielo/create-payment-link', { data: payload }, {
      onSettled: (data, error) => {
        if (error) {
          setError(error)
          return
        }

        setLink(data.shortUrl)
      },
    })
  }, [create, controlledValues])

  const saveContext = useMemo(() => ({
    save: handleSave
  }), [handleSave])

  return (
    <SaveContextProvider value={saveContext}>
      <Box sx={{ margin: '0 12px' }}>
        <h1>Criar link de pagamento</h1>
        <Form save={handleSave}>
          <Stack spacing={1} direction="column" sx={{ '.MuiFormControl-root': { margin: 0 }, maxWidth: '768px' }}>
            <TextInput source="OrderNumber" label="Número do pedido" validate={required()} />
            <TextInput source="name" label="Nome do produto" validate={required()} />
            <TextInput source="description" label="Descrição" />
            {/* <NumberInput source="price" label="Preço BRL em centavos (20042 => R$ 200,42)" validate={required()} /> */}
            <CurrencyInput label="Valor" validate={[required()]} onChange={(value) => handleControlledInputChange('price', value)} />
            <NumberInput sx={{ marginTop: '20px !important' }} source="maxNumberOfInstallments" label="Número máximo de parcelas" defaultValue={3} validate={required()} />
            <NumberInput source="quantity" label="Número máximo de transações" defaultValue={2} validate={required()} />
            <TextInput source="softDescriptor" label="Descrição a ser apresentada na fatura do cartão" validate={required()} />
          </Stack>
          {isLoading ?
            <>Aguarde...</>
            :
            <SaveButton label="Criar link" icon={<></>} />
          }
        </Form>
      </Box>
      <Box sx={{ marginTop: '40px' }}>
        {link && !error ?
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Link de pagamento criado com sucesso! <a href={link} target="_blank" rel="noreferrer">{link}</a>
          </Alert>
          : ''}

        {error ?
          <Alert severity="error">
            Erro ao criar link de pagamento: {error.message}
          </Alert>
          : ''}
      </Box>
    </SaveContextProvider>
  )
}

export default CieloForm
