import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'

const formatValue = (val) => {
  // convert 1 to 0.01 and 100 to 1.00
  val = val.toString().replace(/\D/g, '') // remove non-numeric characters
  return (Number(val) / 100).toFixed(2)
}

const CurrencyInput = ({ record, source, defaultValue, label, onChange, currencyCode = 'BRL', sx }) => {
  const [value, setValue] = useState(defaultValue ? Number(defaultValue) * 100 : record && record[source] ? Number(record[source]) * 100 : 0)
  const [formattedValue, setFormattedValue] = useState('0')

  useEffect(() => {
    const formattedValueAlt = formatValue(value)
    setFormattedValue(formattedValueAlt)

    if (onChange) {
      onChange(Number(formattedValueAlt))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, source])

  const handleChange = (event) => {
    setValue(event.target.value || 0)
  }

  return (
    <TextField
      id={source}
      label={label}
      value={
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: currencyCode
        }).format(formattedValue)
      }
      onChange={handleChange}
      sx={{ ...sx }}
    />
  )
}

export default CurrencyInput
