import { useRecordContext, useUpdate, useNotify, useRefresh } from 'react-admin'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { IfCanAccess } from '../../rbac'

const ItemUnverifiedChanges = () => {
  const record = useRecordContext()
  const [update, { isLoading }] = useUpdate()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleRemoveClick = () => {
    if (isLoading) return

    update(
      'services',
      {
        id: record.id,
        data: { unverifiedChanges: [] }
      },
      {
        onSuccess: () => {
          refresh()
          notify(`Alerta de removido`, { type: 'success' })
        }
      }
    )
  }

  return (
    <>
      {record && record.unverifiedChanges && record.unverifiedChanges.length ?
        <Box sx={{ display: 'inline-block', backgroundColor: '#ffeded', border: '1px solid red', borderRadius: '8px', padding: '12px' }}>
          <b>Alterações não verificadas:</b>
          <TableContainer component={Paper} sx={{
            margin: '12px 0',
            '.MuiTableCell-root:first-of-type': {
              border: '1px solid rgba(224, 224, 224, 1)',
              borderLeft: 0,
              borderTop: 0
            }
            }}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Campo</TableCell>
                  <TableCell align="right">Valor (antigo → novo)</TableCell>
                  <TableCell align="right">Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.unverifiedChanges.map((item, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{item.field}</TableCell>
                    <TableCell align="right">
                      {(() => {
                        try {
                          const obj = JSON.parse(item.value)

                          return (
                            <div>
                              <span style={{ color: 'red', display: 'inline-block', maxWidth: '300px' }}>{JSON.stringify(obj.__old)}</span> → <span style={{ color: 'green', display: 'inline-block', maxWidth: '300px' }}>{JSON.stringify(obj.__new)}</span>
                            </div>
                          )
                        } catch (e) {
                          return item.value
                        }
                      })()}
                    </TableCell>
                    <TableCell align="right">{item.date ? new Date(item.date).toLocaleString() : '?'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ textAlign: 'right' }}>
            <IfCanAccess action="edit">
              <Button variant="contained" color="error" size="small" onClick={handleRemoveClick}>
                {isLoading ? <CircularProgress size="20px" color="inherit" /> : 'Remover alerta'}
              </Button>
            </IfCanAccess>
          </Box>
        </Box>
        : ''}
    </>
  )
}

export default ItemUnverifiedChanges
