import { useState, useEffect, forwardRef, useRef, useImperativeHandle } from 'react'
import * as S from './styles'
import { InView } from 'react-intersection-observer'
import Month from './Month'
import ChevronIcon from '@mui/icons-material/KeyboardArrowDown'

const MonthWrapper = (props) => {
  if (props.scroll) {
    return (
      <InView onChange={(inView, entry) => { inView && props.onIntersect && props.onIntersect() }}>
        {props.children}
      </InView>
    )
  }

  return (
    <>{props.children}</>
  )
}

const Calendar = forwardRef((props, ref) => {
  const { scroll, minDate, style } = props
  const calendarRef = useRef()
  // const [initialRanges, setInitialRanges] = useState([])
  const [ranges, setRanges] = useState([])
  const [monthsToShow, setMonthsToShow] = useState([])
  const [currentDate, setCurrentDate] = useState(new Date())
  const [monthsInView, setMonthsInView] = useState(props.monthsInView || 1)
  const [canGoBack, setCanGoBack] = useState(true)
  const [isShowingMonths, setIsShowingMonths] = useState(false)
  const [rangeSelected, setRangeSelected] = useState(false)

  useImperativeHandle(ref, () => ({
    clear: () => {
      setRanges([])
    }
  }))

  useEffect(() => {
    if (props.ranges && props.ranges.length && props.ranges[0].startDate) {
      // if (!rangeSelected) {
      //   setInitialRanges(props.ranges)
      // }
      // remove the code below if the commented code above is uncommented

      const firstDateISOString = props.ranges[0].startDate.toISOString().split('T')[0]
      const currentDateAlt = new Date(`${firstDateISOString}T00:00`)

      setCurrentDate(currentDateAlt)
      setRanges(props.ranges)
    }
  }, [props.ranges, rangeSelected])

  // useEffect(() => {
  //   if (initialRanges.length && initialRanges[0].startDate) {
  //     const firstDateISOString = initialRanges[0].startDate.toISOString().split('T')[0]
  //     const currentDateAlt = new Date(`${firstDateISOString}T00:00`)

  //     setCurrentDate(currentDateAlt)
  //     setRanges(initialRanges)
  //   }
  // }, [initialRanges])

  useEffect(() => {
    if (monthsInView) {
      const monthsToShowAlt = []
      const currentDateAlt = new Date(currentDate.toISOString())

      for (let i = 0; i < monthsInView; i++) {
        monthsToShowAlt.push({
          date: new Date(currentDateAlt.toISOString())
        })

        currentDateAlt.setDate(1)
        currentDateAlt.setMonth(currentDateAlt.getMonth() + 1)
      }

      setMonthsToShow(monthsToShowAlt)
    }
  }, [monthsInView, currentDate])

  useEffect(() => {
    if (scroll && minDate) {
      let diff = (currentDate.getTime() - minDate.getTime()) / 1000
      diff /= (60 * 60 * 24 * 7 * 4)
      const monthsDiff = Math.abs(Math.round(diff))

      if (monthsDiff) {
        setMonthsInView(monthsDiff + 1)
        setCurrentDate(minDate)
      }
    }
  }, [scroll, minDate, currentDate])

  useEffect(() => {
    if (monthsToShow.length && minDate) {
      if (
        minDate.getMonth() === monthsToShow[0].date.getMonth() &&
        minDate.getFullYear() === monthsToShow[0].date.getFullYear()
      ) {
        setCanGoBack(false)
      } else {
        setCanGoBack(true)
      }
    }
  }, [monthsToShow, minDate])

  const handleRangeChange = (range) => {
    if (props.onChange) {
      if (range) props.onChange({ selection: range })
    }

    setRangeSelected(true)
    setRanges([range])
  }

  const handleNextBtnClick = (e) => {
    const monthsToShowAlt = [...monthsToShow]

    monthsToShowAlt.map(item => {
      item.date.setDate(1)
      item.date.setMonth(item.date.getMonth() + 1)

      return item
    })

    setMonthsToShow(monthsToShowAlt)
  }

  const handlePrevBtnClick = (e) => {
    const monthsToShowAlt = [...monthsToShow]

    monthsToShowAlt.map(item => {
      item.date.setDate(1)
      item.date.setMonth(item.date.getMonth() - 1)

      return item
    })

    setMonthsToShow(monthsToShowAlt)
  }

  const loadNextMonths = () => {
    setMonthsInView(monthsInView + 1)
  }

  const handleMonthInView = (month) => {
    const monthIdx = monthsToShow.findIndex(item => item.date === month.date)

    if (monthIdx > -1) {
      // is last month in monthsToShow array
      if (monthIdx === (monthsToShow.length - 1)) {
        loadNextMonths()
      }
    }
  }

  return (
    <S.Wrapper className={`${scroll && 'scroll'}`} style={style} ref={calendarRef}>
      {canGoBack &&
        <button type="button" className="prev-button" onClick={handlePrevBtnClick}><ChevronIcon /></button>
      }

      <button type="button" className="next-button" onClick={handleNextBtnClick}><ChevronIcon /></button>
      <S.MonthsWrapper onChange={inView => { setIsShowingMonths(inView) }}>
        {monthsToShow.map((item, index) =>
          <MonthWrapper key={index} scroll={scroll} onIntersect={() => handleMonthInView(item)}>
            <Month date={item.date}
              isShowingMonths={isShowingMonths}
              ranges={ranges}
              onRangeChange={handleRangeChange}
              scroll={scroll}
              minDate={minDate} />
          </MonthWrapper>
        )}
      </S.MonthsWrapper>
    </S.Wrapper>
  )
})

export default Calendar
