export const statusOptions = {
  'AwaitingPayment': {
    label: 'Aguardando Pagamento',
    color: 'cornflowerblue',
    value: 'AwaitingPayment'
  },
  'InProgress': {
    label: 'Em Andamento',
    color: 'darkblue',
    value: 'InProgress'
  },
  'Confirmed': {
    label: 'Confirmado',
    color: '#4ec21d',
    value: 'Confirmed'
  },
  'OnRequest': {
    label: 'OnRequest',
    color: 'black',
    value: 'OnRequest'
  },
  'Rejected': {
    label: 'Rejeitado',
    color: 'darkred',
    value: 'Rejected'
  },
  'TechnicalProblem': {
    label: 'Pendente',
    color: 'pink',
    value: 'TechnicalProblem'
  },
  'PendingCancellation': {
    label: 'Pendente de cancelamento',
    color: 'orange',
    value: 'PendingCancellation'
  },
  'Cancelled': {
    label: 'Cancelado',
    color: '#ef3133',
    value: 'Cancelled'
  },
}

export const paymentStatusOptions = {
  'paid': {
    label: 'Pago',
    color: 'green',
    value: 'paid'
  },
  'notPaid': {
    label: 'Não Pago',
    color: 'red',
    value: 'notPaid'
  },
  'invoiced': {
    label: 'Faturado',
    color: 'green',
    value: 'invoiced'
  },
}
