import { useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import Alert from '@mui/material/Alert'
import { fetchUtils } from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'

const BookingImportForm = () => {
  const [message, setMessage] = useState() // { severity: 'success', text: 'Importação realizada com sucesso' }
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    const formData = new FormData(e.target)
    const bookingId = formData.get('bookingId')

    const url = `${process.env.REACT_APP_DATA_PROVIDER}/import-services/${bookingId?.split('-')[0]}`

    fetchUtils.fetchJson(url).then(async (res) => {
      setMessage({ severity: 'success', text: 'Importação realizada com sucesso' })

      // remove message after 2 seconds
      setTimeout(() => {
        setMessage()
      }, 2000)

      // clear form
      e.target.reset()
    }).catch(error => {
      console.error(error)
      setMessage({ severity: 'error', text: 'Erro ao importar, tente novamente.' })

      // remove message after 2 seconds
      setTimeout(() => {
        setMessage()
      }, 2000)
    }).finally(() => {
      setIsSubmitting(false)
    })
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center', margin: '16px 0' }}>
        <TextField type="text" name="bookingId" placeholder="BookingId" required={true} sx={{ '.MuiInputBase-input': { padding: '8px 16px' } }} />
        <Button variant="contained" type="submit">
          {isSubmitting ? <CircularProgress sx={{ color: 'white' }} size={20} /> : 'Importar'}
        </Button>
      </Box>
      {message ?
        <Alert severity={message.severity}>
          {message.text}
        </Alert>
        : ''}
    </form>
  )
}

export default BookingImportForm
