import Cookies from './helpers/Cookies'

const getUser = () => {
  const user = Cookies.getCookie('user')
  return JSON.parse(user)
}

const authProvider = {
  login: ({ username, password }) => {
    const identifier = username // strapi expects 'identifier' and not 'username'
    const request = new Request(process.env.REACT_APP_DATA_PROVIDER + '/auth/local', {
      method: 'POST',
      body: JSON.stringify({ identifier, password }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(async response => {
        Cookies.setCookie('token', response.jwt, 1)

        // get user info
        const userRequest = new Request(process.env.REACT_APP_DATA_PROVIDER + '/users/me', {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${response.jwt}`
          })
        })

        return fetch(userRequest).then(async userResponse => {
          if (userResponse.status < 200 || userResponse.status >= 300) {
            throw new Error(userResponse.statusText)
          }

          const userData = await userResponse.json()

          Cookies.setCookie('user', JSON.stringify(userData), 1)
        })
      })
  },

  logout: () => {
    Cookies.deleteCookie('token')
    Cookies.deleteCookie('user')
    return Promise.resolve()
  },

  checkAuth: () => {
    return Cookies.getCookie('token') ? Promise.resolve() : Promise.reject()
  },

  checkError: ({ status }) => {
    if (status === 401) {
      Cookies.deleteCookie('token')
      Cookies.deleteCookie('user')
      return Promise.reject()
    }

    if (status === 403) {
      return Promise.reject({ logoutUser: false })
    }

    return Promise.resolve()
  },

  getPermissions: () => {
    const user = Cookies.getCookie('user')
    const userData = JSON.parse(user)
    return userData && userData.role ? Promise.resolve(userData.role.name) : Promise.reject()
  },

  getUser: getUser,

  getAffiliate: async (userFromParams) => {
    const user = userFromParams || getUser()

    if (user && user.id) {
      const userToken = Cookies.getCookie('token')
      if (!userToken) return {}

      const affiliateRequest = new Request(process.env.REACT_APP_DATA_PROVIDER + '/affiliates?filters[user][id][$eq]=' + user.id, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userToken}`
        })
      })

      return fetch(affiliateRequest).then(async affiliateResponse => {
        if (affiliateResponse.status < 200 || affiliateResponse.status >= 300) {
          throw new Error(affiliateResponse.statusText)
        }

        const resData = await affiliateResponse.json()

        if (resData && resData.data && resData.data[0] && resData.data[0].attributes) {
          return { ...resData.data[0].attributes, id: resData.data[0].id }
        }
      })
    }

    return {}
  }
}

export default authProvider
