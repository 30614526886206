import { useRecordContext, FunctionField } from "react-admin"
import Box from '@mui/material/Box'
import { LightTooltip } from '../utils'

const ItemVoucherObs = () => {
  const record = useRecordContext()

  if (!record) return null

  return (
    <FunctionField source="cgObservation" render={record => {
      let voucherObs = record.cgObservation ? record.cgObservation : ''

      if (voucherObs[0] === '-') {
        voucherObs = voucherObs.slice(1)
      }

      return (
        <LightTooltip title={voucherObs}>
          <Box sx={{
            fontSize: '13px',
            lineHeight: '1.45em',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}>{voucherObs}</Box>
        </LightTooltip>
      )
    }} />
  )
}

export default ItemVoucherObs