import { useState, useEffect } from 'react'
import { useRecordContext, useUpdate, useNotify, useRefresh } from 'react-admin'
import { Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material'
import Box from '@mui/material/Box'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CircularProgress from '@mui/material/CircularProgress'
import 'instantsearch.css/themes/satellite.css'

const algoliaClient = algoliasearch('R1OKWHPZ4Y', '04cd0f2fca4f9760ab1491c78b8d9aac')
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      });
    }

    return algoliaClient.search(requests.map((req) => {
      return {
        ...req,
        params: {
          ...req.params,
          hitsPerPage: 5,
        }
      }
    }))
  },
}

const ManageSupplier = (props) => {
  const { onClose, open, readOnly = false } = props
  const record = useRecordContext()
  const [update, { isLoading }] = useUpdate()
  const notify = useNotify()
  const refresh = useRefresh()
  const [supplier, setSupplier] = useState(null)
  const [searchState, setSearchState] = useState({})

  const handleClose = () => {
    onClose()
  }

  const handleSave = () => {
    if (isLoading || !record) return

    update(
      'services',
      {
        id: record.id,
        data: { supplier: supplier ? supplier.id : null }
      },
      {
        onSuccess: () => {
          refresh()
          onClose()
          notify(`Fornecedor alterado com sucesso!`, { type: 'success' })
        },
        onError: () => {
          onClose()
          notify(`Erro ao alterar fornecedor!`, { type: 'error' })
        }
      }
    )
  }

  const handleSearchStateChange = (searchState) => {
    setSearchState(searchState)
  }

  useEffect(() => {
    if (open && record.supplier && record.supplier.data && record.supplier.data.attributes) {
      setSupplier({ id: record.supplier.data.id, ...record.supplier.data.attributes })
    }
  }, [record, open])

  if (!record || !open) return

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        {readOnly ? 'Fornecedor' : 'Selecionar fornecedor'}&nbsp;
        ({record.cgBookingNumber ? `Reserva ${record.cgBookingNumber}-${record.cgServiceId}` : ''})
      </DialogTitle>
      <DialogContent>
        {!readOnly ?
          <>
            <InstantSearch
              searchClient={searchClient}
              searchState={searchState}
              onSearchStateChange={handleSearchStateChange}
              indexName="production_api::supplier.supplier">
              <SearchBox
                translations={{
                  placeholder: 'Buscar fornecedor...',
                }} />
              <Box sx={{ position: 'absolute', zIndex: 1 }}>
                <Hits hitComponent={({ hit }) => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', width: '320px', cursor: 'pointer' }} onClick={() => {
                    setSupplier(hit)
                    setSearchState({
                      query: '',
                    })
                  }}>
                    <Box sx={{ fontSize: '12px', borderRadius: '4px', padding: '2px 4px', backgroundColor: 'gray', color: 'white' }}>#{hit.id}</Box>
                    <Box sx={{ flexGrow: 1 }}>{hit.trade_name || hit.company_name}</Box>
                    {/* <Box>{hit.rating ? hit.rating : '?'} / 10</Box> */}
                  </Box>
                )} />
              </Box>
            </InstantSearch>
            <Box sx={{ textAlign: 'right' }}>
              <Button size="small" sx={{ marginTop: '4px' }} href={`/#/suppliers/create`} target="_blank">+ Criar</Button>
            </Box>
          </>
          : ''}
        <Box sx={{ minHeight: '324px' }}>
          {supplier ?
            <Box sx={{ marginTop: '24px' }}>
              Selecionado:
              <Box sx={{
                position: 'relative',
                marginTop: '16px',
                padding: '0 16px',
                border: '1px solid #ddd',
                borderRadius: '4px',
              }}>
                <p><b>ID:</b> {supplier.id}</p>
                <p><b>Nome:</b> {supplier.trade_name}</p>
                <p><b>Razão social:</b> {supplier.company_name}</p>
                <p><b>CNPJ:</b> {supplier.cnpj}</p>
                <p><b>Email:</b> {supplier.email}</p>
                <p><b>Tel:</b> {supplier.phone1} {supplier.phone2 ? `| ${supplier.phone2}` : ''}</p>
                <p><b>cgSupplierId:</b> {supplier.cgSupplierId}</p>
                <p><b>Rating:</b> {supplier.rating ? supplier.rating : '?'} / 10</p>
                <Box sx={{
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  display: 'flex',
                  gap: '16px'
                }}>

                  <IconButton href={`/#/suppliers/${supplier.id}`} target="_blank">
                    <EditIcon />
                  </IconButton>
                  {!readOnly ?
                    <IconButton color="error" onClick={() => setSupplier(null)}>
                      <DeleteIcon />
                    </IconButton>
                    : ''}
                </Box>
              </Box>
            </Box>
            : ''}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button variant="contained" onClick={handleSave}>
          {isLoading ? <CircularProgress size={'20px'} sx={{ color: 'white' }} /> : <>Salvar</>}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ManageSupplier
