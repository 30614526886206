import { useNotify } from 'react-admin'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import DescriptionIcon from '@mui/icons-material/Description'

const ItemBookingNumber = ({ record, showInternalId = false }) => {
  const notify = useNotify()

  return (
    <div style={{ textAlign: 'center' }}>
      {record.cgBookingNumber ?
        <Button onClick={() => {
          navigator.clipboard.writeText(`${record.cgBookingNumber}-${record.cgServiceId}`)
          notify('Número reserva copiado para área de transferência', { type: 'success' })

          if (record.voucherUrl && record.voucherUrl !== '') {
            let voucherUrl = record.voucherUrl

            // if voucherUrl starts with ../ replace with https://flynet.cangooroo.net/
            if (voucherUrl.startsWith('../')) {
              voucherUrl = `https://flynet.cangooroo.net/${voucherUrl.replace('../', '')}`
            }

            const parsedUrl = new URL(voucherUrl)
            const params = new URLSearchParams(parsedUrl.search)
            const paramsObj = Object.fromEntries(params)

            delete paramsObj.s
            delete paramsObj.u

            const url = `${parsedUrl.origin}${parsedUrl.pathname}?${new URLSearchParams(paramsObj).toString()}`

            setTimeout(() => {
              window.open(url, 'popup', 'width=800,height=600')
            }, 300)
          }
        }}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '4px',
            color: 'teal',
            fontWeight: '600',
            whiteSpace: 'nowrap',
            padding: '0 6px'
          }}
          title={record.voucherUrl && record.voucherUrl !== '' ? 'Ver voucher' : ''}>
          {record.cgBookingNumber}-{record.cgServiceId} {record.voucherUrl ? <DescriptionIcon sx={{ width: '12px', height: '12px', marginTop: '-1px' }} /> : ''}
        </Button>
        : ''}
      {showInternalId ?
        <Box sx={{
          fontSize: '12px',
          marginTop: '6px'
        }}>
          {record.id}
        </Box>
        : ''}
    </div>
  )
}

export default ItemBookingNumber
