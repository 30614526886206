import { List, Datagrid, TextField, EditButton } from 'react-admin';

const CustomList = () => (
  <List>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" label="Nome" />
      <TextField source="structural_code" label="Estrutural" />
      <TextField source="accounting_code" label="Cod Contábil" />
      <EditButton />
    </Datagrid>
  </List>
)

export default CustomList