import { useState } from 'react'
import { useUpdate, useRecordContext, useNotify, useRefresh, useRedirect } from 'react-admin'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const ItemInvalidate = ({ type = 'button', redirectTo, ...props }) => {
  let record = useRecordContext()
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const [update, { isLoading }] = useUpdate()
  const [dialogOpen, setDialogOpen] = useState(false)

  if (!record && props.record) {
    record = props.record
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleDialogConfirm = () => {
    update(
      'accounting-transactions',
      { id: record.id, data: { validated: false }, previousData: record },
      {
        onSuccess: () => {
          notify(`Lançamento ${record.id} desvalidado`, { type: 'success' })
          setDialogOpen(false)

          if (redirectTo && redirectTo === 'list') {
            redirect(record.transactionType === 'in' ? '/accounts-receivable' : '/accounts-payable')
          } else {
            refresh()
          }
        },
        onError: (error) => {
          console.error(error)
          notify(`Erro ao desvalidar lançamento ${record.id}!`, { type: 'error' })
        }
      }
    )
  }

  if (!record) return null

  return (
    <>
      <Button type={type} variant="contained" color="error" onClick={() => {
        setDialogOpen(true)
      }}>
        Desvalidar
      </Button>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Confirmar ação</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja realmente desvalidar o lançamento {record.id}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isLoading ? <Button color="error" variant="outlined" onClick={handleDialogClose}><>Cancelar</></Button> : ''}
          <Button autoFocus color="success" variant="contained" onClick={handleDialogConfirm}>
            {isLoading ? <>...</> : <>Confirmar</>}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ItemInvalidate
