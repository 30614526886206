import { useParams } from 'react-router-dom'
import { Create, TextInput, SimpleForm, TopToolbar, ListButton } from 'react-admin'
import Stack from '@mui/material/Stack'

const PostEditActions = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  )
}

const EditPage = () => {
  const { id } = useParams()

  return (
    <Create actions={<PostEditActions />}
      title={`Novo Beneficiário/Pagador #${id}`}
      mutationMode="pessimistic">
      <SimpleForm>
        <Stack direction="row" spacing={2} width="100%">
          <TextInput source="name" label="Nome" sx={{ margin: 0, width: '100%' }} />
          <TextInput source="cnpj" label="CNPJ" sx={{ margin: 0, width: '100%' }} />
          <TextInput source="cpf" label="CPF" sx={{ margin: 0, width: '100%' }} />
        </Stack>
        <Stack direction="row" spacing={2} width="100%">
          <TextInput source="address" label="Endereço" sx={{ margin: 0, width: '100%' }} />
          <TextInput source="accountingCode" label="Cod. Contábil" sx={{ margin: 0, width: '100%' }} />
        </Stack>
      </SimpleForm>
    </Create>
  )
}

export default EditPage
