const CountryFlag = ({ countryCode = 'BR', size }) => {
  return (
    <img
      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode.toUpperCase()}.svg`}
      alt={countryCode}
      width={size}
    />
  )
}

export default CountryFlag
