import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNotify, useRedirect, useCreateController, useEditController, useUpdate, SimpleForm, TextInput, BooleanInput, useDataProvider, SelectInput, required, SaveButton } from 'react-admin'
import { RichTextInput } from 'ra-input-rich-text'
// import Tabs from '@mui/material/Tabs'
// import Tab from '@mui/material/Tab'
// import DescriptionTabPanel from './DescriptionTabPanel'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import OptionCard from '../OptionCard'
import CustomToolbar from './Toolbar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { FaEdit } from 'react-icons/fa'
import PlacesAutocomplete from './PlacesAutocomplete'
import { ReactSortable } from 'react-sortablejs'
import ItemReverseRouteButton from '../List/ItemReverseRouteButton'

const statusOptions = {
  'false': {
    label: 'Desativada',
    color: '#ef3133',
    value: false
  },
  'true': {
    label: 'Ativada',
    color: '#4290f5',
    value: true
  }
}

const StatusChoiceWithColor = ({ choice }) => {
  return (
    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      <Box className='color-box' sx={{ backgroundColor: choice.color, width: '16px', height: '16px', borderRadius: '4px', marginRight: '8px' }}></Box>
      {choice.name}
    </Box>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

const FromToIcon = () => (
  <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_25)">
      <path d="M4.1701 27.0327C2.27965 25.1422 2.27965 22.0656 4.1701 20.1751C6.06055 18.2847 9.13721 18.2847 11.0277 20.1751C12.9181 22.0656 12.9177 25.1419 11.0273 27.0323C9.13685 28.9228 6.0609 28.9235 4.1701 27.0327ZM9.62758 21.5752C8.50892 20.4565 6.68883 20.4565 5.57017 21.5752C4.45151 22.6938 4.45151 24.5139 5.57017 25.6326C6.68883 26.7513 8.50857 26.7509 9.62723 25.6322C10.7459 24.5136 10.7462 22.6938 9.62758 21.5752Z" fill="#1E4265" />
      <path d="M37.5583 26.3326C39.0653 27.8397 41.5087 27.8397 43.0158 26.3326C44.5228 24.8256 44.5228 22.3822 43.0158 20.8751C41.5087 19.3681 39.0654 19.3681 37.5583 20.8751C36.0513 22.3822 36.0513 24.8256 37.5583 26.3326Z" fill="#1E4265" />
      <path d="M34.1614 23.4747L30.3143 21.2532L30.3143 22.9795L14.288 22.9798L14.288 23.9697L30.3143 23.9693L30.3143 25.6963L34.1614 23.4747Z" fill="#1E4265" />
    </g>
    <defs>
      <clipPath id="clip0_1_25">
        <rect width="33" height="33" fill="white" transform="translate(24 0.330963) rotate(45)" />
      </clipPath>
    </defs>
  </svg>
)

const EditForm = ({ mode, ...props }) => {
  const { id } = useParams()
  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const [record, setRecord] = useState(null)
  const [update] = useUpdate()
  const [currency, setCurrency] = useState(record?.currency || null)
  const [distanceLabel, setDistanceLabel] = useState('Distância (km)')
  const [radiusMode, setRadiusMode] = useState(false)
  // const [placeFromId, setPlaceFromId] = useState(null)
  const [googlePlaceFrom, setGooglePlaceFrom] = useState(null)
  const [googlePlaceTo, setGooglePlaceTo] = useState(null)
  const [options, setOptions] = useState([])
  const [defaultAdditionalServices, setDefaultAdditionalServices] = useState([])
  const [currentContract, setCurrentContract] = useState(null)
  const [contractModalOpen, setContractModalOpen] = useState(false)
  const [availableContracts, setAvailableContracts] = useState([])
  // const [descriptionTabIndex, setDescriptionTabIndex] = useState(0)

  const onSuccess = (data) => {
    notify(`Rota salva com sucesso!`, { type: 'success' })
    redirect(`/transfer-routes/${data.id}`)
  }

  const transform = data => {
    const dataAlt = {
      ...data,
      googlePlaceFrom,
      googlePlaceTo: radiusMode ? null : googlePlaceTo,
      contract: currentContract ? currentContract.id : null,
      options: options.map(option => {
        return {
          ...option,
          vehicle: option.vehicle.id,
          additionalServices: option.additionalServices.filter(additionalService => additionalService.checked).map(additionalService => {
            return {
              ...additionalService,
              item: additionalService.item.id
            }
          })
        }
      })
    }

    if (googlePlaceFrom && googlePlaceFrom.value) {
      if (googlePlaceFrom.value.place_id) {
        dataAlt.googlePlaceIdFrom = googlePlaceFrom.value.place_id
      }

      if (googlePlaceFrom.value.geometry && googlePlaceFrom.value.geometry.location) {
        dataAlt.coordinatesFrom = {
          lat: googlePlaceFrom.value.geometry.location.lat,
          lng: googlePlaceFrom.value.geometry.location.lng
        }
      }
    }

    if (googlePlaceTo && googlePlaceTo.value) {
      if (googlePlaceTo.value.place_id) {
        dataAlt.googlePlaceIdTo = googlePlaceTo.value.place_id
      }

      if (googlePlaceTo.value.geometry && googlePlaceTo.value.geometry.location) {
        dataAlt.coordinatesTo = {
          lat: googlePlaceTo.value.geometry.location.lat,
          lng: googlePlaceTo.value.geometry.location.lng
        }
      }
    }

    return dataAlt
  }

  const saveControllerParams = { id, transform: transform, mutationOptions: { onSuccess }, queryOptions: { meta: { populate: 'deep,4' } } }
  const useSaveController = mode === 'edit' ? useEditController : useCreateController
  const { record: entry, isLoading, save } = useSaveController(saveControllerParams)

  const customSave = initialData => {
    const data = transform(initialData)

    update(
      'transfer-routes',
      { id: record.id, data, previousData: record },
      {
        onSuccess: () => {
          onSuccess(data)
        },
        onError: () => {
          notify(`Erro ao salvar rota!`, { type: 'error' })
        }
      }
    )
  }

  const handleRadiusModeChange = (event) => {
    setRadiusMode(event.target.checked)
  }

  const handleOriginChange = (value) => {
    // setPlaceFromId(placeId)
    setGooglePlaceFrom(value)
  }

  const handleDestinationChange = (value) => {
    // setPlaceToId(placeId)
    setGooglePlaceTo(value)
  }

  const handleOptionChange = (e) => {
    const optionsAlt = [...options]
    optionsAlt[e.index] = e.option
    setOptions(optionsAlt)
  }

  const handleContractSelect = (e, contract) => {
    setCurrentContract(contract)
  }

  useEffect(() => {
    if (entry && defaultAdditionalServices.length) {
      // const placeTo = entry.placeTo && entry.placeTo.data && entry.placeTo.data.id ? entry.placeTo.data.id : null

      setRecord({
        ...entry,
        // placeFrom: entry.placeFrom && entry.placeFrom.data && entry.placeFrom.data.id ? entry.placeFrom.data.id : entry.placeFrom,
        // placeTo: placeTo
      })

      setCurrency(entry.currency)

      setGooglePlaceFrom(entry.googlePlaceFrom)
      setGooglePlaceTo(entry.googlePlaceTo)

      if (entry.contract && entry.contract.data && entry.contract.data.id && entry.contract.data.attributes) {
        setCurrentContract({
          id: entry.contract.data.id,
          ...entry.contract.data.attributes
        })
      }

      if (!entry.googlePlaceTo) {
        setRadiusMode(true)
      }

      setOptions(entry.options.map(option => {
        const entryAdditionalServices = option.additionalServices.map(additionalService => {
          if (additionalService?.item?.data) {
            return {
              item: { id: additionalService.item.data.id },
              priceUSD: additionalService.priceUSD
            }
          }

          return null
        }).filter(item => item)

        return {
          ...option,
          vehicle: option.vehicle && option.vehicle.data ? { id: option.vehicle.data.id, ...option.vehicle.data.attributes } : null,
          additionalServices: defaultAdditionalServices.map(item => {
            const foundItem = entryAdditionalServices.find(entryAdditionalService => {
              return entryAdditionalService && entryAdditionalService.item && entryAdditionalService.item.id === item.id
            })

            return {
              item,
              checked: foundItem ? true : false,
              priceUSD: foundItem ? foundItem.priceUSD : item.basePriceUSD
            }
          })
        }
      }))
    }
  }, [entry, defaultAdditionalServices])

  useEffect(() => {
    if (dataProvider) {
      if (!defaultAdditionalServices.length) {
        dataProvider.getList('transfer-additional-services', {
          pagination: { page: 1, perPage: 1000 }, sort: { field: 'namePT', order: 'ASC' }, meta: { populate: 'deep,3' }
        }).then(({ data }) => {
          setDefaultAdditionalServices(data)
        }).catch(error => {
          console.error(error)
        })
      }
    }
  }, [dataProvider, defaultAdditionalServices.length])

  useEffect(() => {
    if (dataProvider && contractModalOpen && !availableContracts.length) {
      dataProvider.getList('transfer-contracts', {
        pagination: { page: 1, perPage: 100 }, sort: { field: 'id', order: 'DESC' }
      }).then(({ data }) => {
        setAvailableContracts(data)
      }).catch(error => {
        console.error(error)
      })
    }
  }, [contractModalOpen, availableContracts, dataProvider])

  useEffect(() => {
    if (!entry && dataProvider && !currentContract) {
      dataProvider.getList('transfer-contracts', {
        pagination: { page: 1, perPage: 1 }, sort: { field: 'id', order: 'DESC' }, meta: { 'filters[code][$eq]': 'default' }
      }).then(({ data }) => {
        setCurrentContract(data[0])
      }).catch(error => {
        console.error(error)
      })
    }
  }, [entry, dataProvider, currentContract])

  useEffect(() => {
    setDistanceLabel(radiusMode ? 'Trajeto máximo (km)' : 'Distância (km)')
  }, [radiusMode])

  useEffect(() => {
    if (dataProvider && googlePlaceFrom && !options.length && defaultAdditionalServices.length) {
      const countryCode = googlePlaceFrom.value.address_components.find(addressComponent => addressComponent.types.includes('country')).short_name

      const reqMeta = {
        populate: 'deep,4'
      }

      if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
        reqMeta['filters[places][countryCode][$eq]'] = countryCode
      }

      dataProvider.getList('transfer-vehicles', {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'id', order: 'DESC' },
        meta: reqMeta,
      }).then(({ data }) => {
        // sort by lowest vehicle maxPax
        setOptions(data.sort((a, b) => a.maxPax - b.maxPax).map(data => {
          return {
            active: true,
            vehicle: data,
            priceUSD: null,
            priceKmUSD: null,
            ferryIncluded: false,
            additionalServices: defaultAdditionalServices.map(item => {
              return {
                item,
                priceUSD: item.basePriceUSD || 0,
                checked: false
              }
            }),
          }
        }))
      }).catch(error => {
        console.error(error)
      })
    }
  }, [googlePlaceFrom, options.length, dataProvider, defaultAdditionalServices])

  if (isLoading || (mode === 'edit' && !record) || !defaultAdditionalServices.length) {
    return (
      <Box sx={{ display: 'flex', padding: '16px' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ width: '100%', backgroundColor: 'white', 'form': { boxShadow: '0px 2px 8px 0px #00000029', borderRadius: '8px' } }}>
        <SimpleForm record={record}
          toolbar={<CustomToolbar />}
          onSubmit={mode === 'edit' ? (data) => customSave(data) : save}>
          <Box display={{ xs: 'block', sm: 'flex', width: '100%', alignItems: 'start' }} gap={4}>
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'right', width: '100%', margin: '-8px 0 -16px' }}>
                <BooleanInput sx={{ marginRight: '-12px' }} label="Arredores" source="radiusMode" checked={radiusMode} onChange={handleRadiusModeChange} />
              </Box>

              <Box display={{ xs: 'block', sm: 'flex', width: '100%', marginBottom: '16px' }} gap={'20px'} alignItems={'center'}>
                <PlacesAutocomplete placeholder="Origem" onChange={handleOriginChange} value={googlePlaceFrom} />
                <Box>
                  <FromToIcon />
                </Box>
                <Box width="100%">
                  {radiusMode ?
                    <Box width="100%">
                      <Box padding="0 20px" height="55px" display="flex" alignItems="center" border="1px solid #ccc" color="gray" borderRadius="10px">
                        Arredores
                      </Box>
                    </Box>
                    :
                    <PlacesAutocomplete placeholder="Destino" onChange={handleDestinationChange} value={googlePlaceTo} />
                  }
                </Box>
              </Box>

              <Box display={{ xs: 'block', sm: 'flex', width: '100%' }} gap={'20px'} alignItems={'center'}>
                <TextInput source="distance" label={distanceLabel} />
                <TextInput source="timeInWay" label="Duração (min)" />
              </Box>

              {options.length ?
                <Box sx={{ marginBottom: '32px', width: '100%' }}>
                  <h2 style={{ fontSize: '16px', marginBottom: '20px' }}>Opções</h2>
                  <ReactSortable list={options} setList={setOptions}>
                    {options.map((option, index) =>
                      <OptionCard key={index} index={index} option={option} onChange={handleOptionChange} currency={currency} />
                    )}
                  </ReactSortable>
                  {/* <Button variant="contained" type="button">+ Adicionar opção</Button> */}
                </Box>
                : <Alert sx={{ marginBottom: '28px' }} severity="info">Informe a origem para ver opções</Alert>}


              <Box sx={{ marginBottom: '32px', width: '100%' }}>
                <h2 style={{ fontSize: '16px', marginBottom: '12px' }}>Contrato</h2>
                <Box sx={{ position: 'relative', width: '340px', flexShrink: 0, backgroundColor: '#f7f7f7', padding: '0 16px', borderRadius: '8px' }}>
                  {currentContract ?
                    <List sx={{ '.MuiListItemText-root .MuiTypography-root': { fontSize: '14px' }, width: '100%' }}>
                      <ListItem disablePadding>
                        <ListItemText>
                          <Box sx={{ color: '#fff', fontSize: '12px', backgroundColor: '#777', display: 'inline-block', padding: '2px 8px', borderRadius: '20px' }}>
                            <b>#{currentContract.id}</b> - {currentContract.code}
                          </Box>
                        </ListItemText>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemText>Tempo de espera: <b>{currentContract.paxWaitingTime} min</b></ListItemText>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemText>Início penalidade: <b>{currentContract.cancellationTimeMin} min</b></ListItemText>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemText>Antecedência mínima para reserva: <b>{currentContract.bookingTimeMin} min</b></ListItemText>
                      </ListItem>
                    </List>
                    : 'Contrato default não encontrado'}

                  <IconButton size="small" color="primary" type="button" aria-label="delete" onClick={() => setContractModalOpen(true)} sx={{ position: 'absolute', top: '6px', right: '6px' }}>
                    <FaEdit />
                  </IconButton>

                  <Modal
                    open={contractModalOpen}
                    onClose={() => setContractModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={modalStyle}>
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                        Selecione
                      </Typography>
                      {availableContracts.map((contract, index) =>
                        <Box key={index} sx={{ position: 'relative', width: '340px', flexShrink: 0, backgroundColor: '#f7f7f7', padding: '0 16px', border: '1px solid #eee', borderRadius: '8px', margin: '16px 0' }}>
                          <List sx={{ '.MuiListItemText-root .MuiTypography-root': { fontSize: '14px' }, width: '100%' }}>
                            <ListItem disablePadding>
                              <ListItemText>
                                <Box sx={{ color: '#fff', fontSize: '12px', backgroundColor: '#777', display: 'inline-block', padding: '2px 8px', borderRadius: '20px' }}>
                                  <b>#{contract.id}</b> - {contract.code}
                                </Box>
                              </ListItemText>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemText>Tempo de espera: <b>{contract.paxWaitingTime} min</b></ListItemText>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemText>Início penalidade: <b>{contract.cancellationTimeMin} min</b></ListItemText>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemText>Antecedência mínima para reserva: <b>{contract.bookingTimeMin} min</b></ListItemText>
                            </ListItem>
                          </List>

                          {contract.id === currentContract.id ?
                            <Button onClick={() => setContractModalOpen(false)} size="small" variant="outlined" color="primary" type="button" sx={{ position: 'absolute', top: '12px', right: '12px' }}>
                              Atual
                            </Button>
                            :
                            <Button onClick={(e) => handleContractSelect(e, contract)} size="small" variant="contained" color="primary" type="button" sx={{ position: 'absolute', top: '12px', right: '12px' }}>
                              Selecionar
                            </Button>
                          }
                        </Box>
                      )}
                    </Box>
                  </Modal>
                </Box>
              </Box>

              <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '8px', '.ProseMirror': { minHeight: '150px' } }}>
                <RichTextInput source="descriptionPT" label="Descrição PT" size="large" />
              </Box>

              {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={descriptionTabIndex} onChange={(e, val) => setDescriptionTabIndex(val)}>
                <Tab label="Descrição PT"  />
                <Tab label="Descrição EN" />
                <Tab label="Descrição ES" />
              </Tabs>
            </Box>
            <DescriptionTabPanel value={descriptionTabIndex} index={0}>
              <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '8px', '.ProseMirror': { minHeight: '150px' } }}>
                <RichTextInput source="descriptionPT" label="Descrição PT" size="large" />
              </Box>
            </DescriptionTabPanel>
            <DescriptionTabPanel value={descriptionTabIndex} index={1}>
              <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '8px', '.ProseMirror': { minHeight: '150px' } }}>
                <RichTextInput source="descriptionEN" label="Descrição EN" size="large" />
              </Box>
            </DescriptionTabPanel>
            <DescriptionTabPanel value={descriptionTabIndex} index={2}>
              <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '8px', '.ProseMirror': { minHeight: '150px' } }}>
                <RichTextInput source="descriptionES" label="Descrição ES" size="large" />
              </Box>
            </DescriptionTabPanel> */}
            </Box>

            <Box sx={{
              width: '240px',
              flexShrink: 0,
              borderRadius: '8px',
              backgroundColor: '#f7f7f7',
              padding: 2,
              '.MuiFormHelperText-root': { display: 'none' }
            }}>
              <SaveButton fullWidth alwaysEnable label="Salvar" sx={{ marginBottom: 2 }} />
              <SelectInput source="active" label="Status" fullWidth
                validate={required()}
                choices={Object.keys(statusOptions).map(key => {
                  return {
                    id: key,
                    name: statusOptions[key].label,
                    color: statusOptions[key].color,
                    value: statusOptions[key].value
                  }
                })}
                optionText={(choice) => {
                  return (
                    <StatusChoiceWithColor choice={choice} />
                  )
                }} />
              <SelectInput source="currency" label="Moeda" fullWidth
                onChange={(e) => setCurrency(e.target.value)}
                validate={required()}
                choices={[
                  { id: 'USD', name: 'USD' },
                  { id: 'BRL', name: 'BRL' },
                  { id: 'EUR', name: 'EUR' }
                ]} />

              {mode === 'edit' ?
                <Box sx={{ marginTop: 1 }}> 
                  <ItemReverseRouteButton />
                </Box>
              : ''}

              <Divider sx={{ margin: '1em 0' }} />

              <Stack gap={2}>
                <Stack sx={{ fontSize: '14px' }}>
                  <div>Rota criada em:</div>
                  <b>
                    {
                      new Date(record?.createdAt).toLocaleDateString('pt-BR', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                      })
                    }
                  </b>
                </Stack>
                <Stack sx={{ fontSize: '14px' }}>
                  <div>Última atualização:</div>
                  <b>
                    {
                      new Date(record?.updatedAt).toLocaleDateString('pt-BR', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                      })
                    }
                  </b>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </SimpleForm>
      </Box>
    </>
  )
}

export default EditForm
