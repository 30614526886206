import * as React from 'react'
import { useState, useEffect } from 'react'
import { useNotify, useUpdate, useRecordContext, useDataProvider, TextInput, Form } from 'react-admin'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import PhoneIcon from '@mui/icons-material/Phone'
import AddIcCallIcon from '@mui/icons-material/AddIcCall'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

const ItemPax = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [pax, setPax] = useState([])
  const [phoneModalOpen, setPhoneModalOpen] = useState(false)
  const notify = useNotify()
  const [update, { isLoading }] = useUpdate()

  const handlePhoneModalOpen = () => setPhoneModalOpen(true)
  const handlePhoneModalClose = () => setPhoneModalOpen(false)

  useEffect(() => {
    if (record && record.pax && record.pax.length) {
      setPax(record.pax)
    }
  }, [record])

  const handleFormSubmit = (data) => {
    const pax = [...record.pax]

    if (pax && pax.length && data.paxPhone) {
      pax[0].phoneDDI = data.paxPhoneDDI
      pax[0].phoneDDD = data.paxPhoneDDD
      pax[0].phone = data.paxPhone
    }

    update(
      'services',
      {
        id: data.id,
        data: { pax: pax },
        previousData: record
      },
      {
        onSuccess: async () => {
          try {
            const relatedServicesRes = await dataProvider.getList('services', {
              meta: {
                'filters[cgBookingNumber][$eq]': record.cgBookingNumber.toString(),
                'populate': 'pax',
                'fields[0]': 'id',
                'fields[1]': 'pax'
              },
              pagination: { page: 1, perPage: 10 },
              sort: { field: 'updatedAt', order: 'DESC' }
            })

            if (relatedServicesRes && relatedServicesRes.data && relatedServicesRes.data.length) {
              relatedServicesRes.data.forEach(async (relatedService) => {
                if (relatedService.id === record.id) return // skip current service

                const pax = [...relatedService.pax]
      
                if (pax && pax.length && data.paxPhone) {
                  const matchingPax = pax.find(paxItem => paxItem.name === record.pax[0].name && paxItem.surname === record.pax[0].surname)
      
                  if (matchingPax) {
                    matchingPax.phoneDDI = data.paxPhoneDDI
                    matchingPax.phoneDDD = data.paxPhoneDDD
                    matchingPax.phone = data.paxPhone
      
                    await dataProvider.update('services', {
                      id: relatedService.id,
                      data: { pax: pax },
                      previousData: relatedService
                    })
      
                    console.log('pax phone update for related service.id', relatedService.id)
                  }
                }
              })
            }
          } catch (e) {
            console.error(e)
          } finally {
            setPhoneModalOpen(false)
          }
        }
      }
    )
  }

  if (!pax || !pax.length) return null

  const paxPhone = pax[0].phone ? pax[0].phone.replace(/^\D+/g, '') : null

  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            {pax.map((paxItem, index) =>
              <ul key={index} style={{ padding: '12px', margin: 0, listStyle: 'none', lineHeight: '22px' }}>
                <li><b style={{ fontSize: '14px' }}>{paxItem.name} {paxItem.surname}</b></li>
                {Object.keys(paxItem).map((key, i) => {
                  if (
                    key === 'id' ||
                    key === 'name' ||
                    key === 'surname' ||
                    key === 'whatsappAllowed' ||
                    key === 'phoneDDD' ||
                    key === 'phoneDDI' ||
                    key === 'mainPax'
                  ) return null

                  return (
                    <li key={i} style={{ color: '#444' }}>
                      <b>{key}</b>:
                      {key === 'phone' ?
                        `${paxItem.phoneDDI ? `+${paxItem.phoneDDI} ` : ''}${paxItem.phoneDDD} ${paxItem.phone}`
                        : paxItem[key] ? paxItem[key].toString() : '?'}
                    </li>
                  )
                })}
              </ul>
            )}
          </React.Fragment>
        }
      >
        <Box sx={{ display: 'flex', gap: '6px', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button sx={{ padding: 0, fontSize: '11px', lineHeight: '1.45em', fontWeight: 'bold', textAlign: 'left' }}>
            {pax[0].name} {pax[0].surname}
          </Button>
          <Button
            variant="contained"
            size="small"
            title={paxPhone ? `Copiar telefone` : 'Definir telefone'}
            sx={{ minWidth: 'auto', padding: '4px', borderRadius: '2px', backgroundColor: !paxPhone ? 'gray' : null }}
            onClick={() => {
              if (paxPhone) {
                navigator.clipboard.writeText(`${pax[0].phoneDDI ? `+${pax[0].phoneDDI}` : ''}${pax[0].phoneDDD}${pax[0].phone}`)
                notify('Telefone copiado para área de transferência', { type: 'success' })
              } else {
                handlePhoneModalOpen()
              }
            }}>
            {paxPhone ?
              <PhoneIcon sx={{ width: '12px', height: '12px' }} />
              : <AddIcCallIcon sx={{ width: '12px', height: '12px' }} />}
          </Button>
        </Box>
      </HtmlTooltip>
      <Modal
        open={phoneModalOpen}
        onClose={handlePhoneModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Telefone de {pax[0].name} {pax[0].surname}
          </Typography>
          <Form onSubmit={handleFormSubmit}>
            <Box sx={{
              position: 'relative',
              display: 'flex',
              gap: '4px',
              alignItems: 'center',
              marginTop: '16px',
              '& .MuiFormLabel-root': {
                marginTop: '-9px',
                '&.Mui-focused': {
                  margin: 0
                },
                '&.MuiFormLabel-filled': {
                  margin: 0
                }
              },
              '& .MuiInputBase-root': {
                borderRadius: '6px',
                margin: '0 0 -20px'
              },
              '& .MuiInputBase-input': {
                padding: '8px 10px !important',
                fontSize: '0.875rem',
                MozAppearance: 'textfield',
                '&::-webkit-inner-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0
                },
                '&::-webkit-outer-spin-button': {
                  WebkitAppearance: 'none',
                  margin: 0
                }
              }
            }}>
              <TextInput sx={{
                width: '70px',
                  '& .MuiInputBase-input': {
                    padding: '8px 0 !important'
                  },
                  '& .MuiInputBase-root': {
                    paddingLeft: '8px'
                  },
                  '& .MuiInputAdornment-root': {
                    marginRight: '4px'
                  }
                }}
                type="number" source="paxPhoneDDI" label="DDI"
                InputProps={{
                  startAdornment: <InputAdornment position="start">+</InputAdornment>
                }} />
              <TextInput sx={{ width: '70px' }} type="number" source="paxPhoneDDD" label="DDD" />
              <TextInput source="paxPhone" type="number" label="Número" />
              <Button type="submit" variant="contained">
                {isLoading ? <CircularProgress size={'18px'} sx={{ color: 'white' }} /> : <>Salvar</>}
              </Button>
            </Box>
          </Form>
        </Box>
      </Modal>
    </>
  )
}

export default ItemPax
