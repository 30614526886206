
export const statusOptions = {
  'notDone': {
    label: 'Pendente',
    color: '#4290f5',
    value: 'notDone'
  },
  'done': {
    label: 'Concluído',
    color: '#4ec21d',
    value: 'done'
  },
  'none': {
    label: '-',
    color: 'lightgray',
    value: null,
    disabled: true
  }
}
