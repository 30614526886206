import { SaveButton, DeleteButton, Toolbar } from 'react-admin'

const EditFormToolbar = ({ mode, ...props }) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      <SaveButton alwaysEnable />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar> 
  )
}

export default EditFormToolbar
