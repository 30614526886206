import React from 'react'
import { Datagrid, TextField, DateField, FunctionField, EditButton, useRecordContext } from 'react-admin'
import { getCityName, getPaxNumber } from '../utils'
import Box from '@mui/material/Box'
import ItemAssignee from './ItemAssignee'
// import ItemStatus from './ItemStatus'
import ItemClientMessageStatus from './ItemClientMessageStatus'
import ItemSupplierMessageStatus from './ItemSupplierMessageStatus'
import ItemCgStatus from './ItemCgStatus'
import ItemSupplierStatus from './ItemSupplierStatus'
import ItemConvertedDate from './ItemConvertedDate'
import ItemPaymentStatus from './ItemPaymentStatus.js'
import ItemSupplierPaymentStatus from './ItemSupplierPaymentStatus.js'
import ItemPax from './ItemPax'
import ItemUnverifiedChanges from './ItemUnverifiedChanges'
import ItemObs from './ItemObs'
import ItemClient from './ItemClient'
import ItemSupplier from './ItemSupplier.js'
import ItemCheckoutHour from './ItemCheckoutHour.js'
// import ItemSupplierPrice from './ItemSupplierPrice'
import WarningIcon from '@mui/icons-material/Warning'
import StatusButton from '../../components/StatusButton'
import { operationalStatusOptions } from '../utils'
import ItemStartLocation from './ItemStartLocation.js'
import ItemVoucherObs from './ItemVoucherObs.js'
import { IfCanAccess } from '../../rbac/index.js'
import ItemBookingNumber from './ItemBookingNumber.js'
import ItemEndLocation from './ItemEndLocation.js'
// import { useAuthProvider } from 'react-admin'

const Panel = () => {
  const record = useRecordContext()
  const customFieldsObj = {}

  for (const customField of record.customFields) {
    customFieldsObj[customField.name] = customField.value
  }

  const flightStr = record.originAirCiaName ? `${record.originAirCiaName} ${record.originAirNumber}` : null

  return (
    <>
      <ItemUnverifiedChanges />
      <div style={{ display: 'flex', gap: '24px', padding: '12px' }}>
        <div>
          <b>Voo: </b>{flightStr ? <>
            <a href={`https://www.google.com/search?q=${flightStr}`} target="_blank" rel="noreferrer">{flightStr}</a> às {record.originAirTime}
          </> : '-'}
        </div>
        <div>
          <b>Endereço do Hotel: </b>{record.hotelAddress || '-'}
        </div>
      </div>
    </>
  )
}

const rowStyle = (record, index) => {
  if (record && record.operationalStatus === 'done') {
    return {
      backgroundColor: 'hsl(0deg 0% 24.56% / 35%)',
      opacity: 0.8
    }
  }

  if (record && record.operationalStatus === 'error') {
    return {
      backgroundColor: 'hsl(0deg 100% 77% / 30%)',
      opacity: 0.8
    }
  }

  return {
    backgroundColor: index % 2 ? "hsl(222 39% 92% / 1)" : ""
  }
}

const ServiceItem = ({ ...props }) => {
  // const authProvider = useAuthProvider()
  // const user = authProvider.getUser()

  // highlight clicked row
  document.addEventListener('click', (e) => {
    const clickedRows = document.querySelectorAll('.MuiTableRow-root.clicked')
    clickedRows.forEach(row => row.classList.remove('clicked'))

    if (e.target.tagName === 'TD' && e.target.parentElement.classList.contains('MuiTableRow-root')) {
      e.target.parentElement.classList.add('clicked')
    }
  })

  return (
    <Datagrid expand={<Panel />} bulkActionButtons={false} rowSx={rowStyle} sx={{
      "& .RaDatagrid-headerCell": {
        backgroundColor: "hsl(222 39% 92% / 1)",
        fontSize: '10px',
        fontWeight: '600',
        lineHeight: '1.4em',
        textTransform: 'uppercase'
      },
      "& .MuiTableRow-root:hover, & .MuiTableRow-root.clicked": {
        backgroundColor: "#fff2c3 !important",
        opacity: '1 !important'
      },
      "& .MuiTableCell-root": {
        borderLeft: '1px solid',
        borderRight: '1px solid',
        borderColor: 'hsl(0 0% 78% / 1)',
        padding: '5px 10px'
      },
      "& .MuiTableCell-root:first-of-type": {
        border: '0'
      },
      "& .RaDatagrid-expandIcon": {
        padding: '0 !important'
      },
      "& .RaDatagrid-expandedPanel": {
        boxShadow: '4px 9px 10px -4px #00000036',
        position: 'relative'
      }
    }}>
      <Box sx={{ position: 'relative', width: '16px' }}>
        <FunctionField render={record => {
          return record.unverifiedChanges && record.unverifiedChanges.length ?
            <WarningIcon color="error" sx={{ position: 'absolute', left: '-23px' }} /> : ''
        }} />
        <IfCanAccess action="edit">
          <EditButton label="" sx={{ padding: 0, minWidth: '32px', position: 'relative', left: '-8px' }} />
        </IfCanAccess>
      </Box>
      <Box label="Responsável" source="assignee">
        <ItemAssignee />
      </Box>
      <ItemCgStatus label="Sts. Rsv." />
      <Box label={`Data/Hora BRT`} source="utcServiceDate" sx={{ textAlign: 'center', width: '72px' }}>
        <ItemConvertedDate />
      </Box>
      <ItemClientMessageStatus label="MSG Cli." />
      <ItemSupplierMessageStatus label="MSG Forn." />
      <ItemSupplierStatus label="Sts. Forn." />
      <Box label="Pgto Forn.">
        <ItemSupplierPaymentStatus />
      </Box>
      <Box label="Sts. Op.">
        <StatusButton resource="services" source="operationalStatus" options={operationalStatusOptions} />
      </Box>
      <Box label="Sts. Pgto" source="paymentStatus" sx={{ whiteSpace: 'nowrap' }}>
        <ItemPaymentStatus />
      </Box>
      <Box sx={{ width: '100px' }} label="Cidade">
        <FunctionField source="cityName" sx={{ fontSize: '12px', textTransform: 'uppercase' }} render={record => {
          const cityName = getCityName(record)
          return cityName ? cityName.split(',')[0] : null
        }} />
      </Box>
      <DateField source="serviceDate" label="Data Local" sx={{ fontWeight: 'bold' }} />
      <TextField source="checkInHour" label="H Local Início" />
      <Box label="H Local Fim">
        <ItemCheckoutHour />
      </Box>
      <TextField source="serviceType" label="Tipo" sx={{ fontSize: '12px', textTransform: 'uppercase', fontWeight: 'bold', color: '#555' }} />
      {/* <ItemPaymentStatus label="Pagamento Cliente" /> */}
      <Box sx={{ width: '120px' }} label="Passageiro">
        <ItemPax />
      </Box>
      <Box sx={{ textAlign: 'center' }} label="PAXs">
        <FunctionField sx={{ fontWeight: 'bold', color: '#cc0000', fontSize: '16px' }}
          render={record => getPaxNumber(record)} />
      </Box>

      <Box label="Local Início">
        <ItemStartLocation />
      </Box>

      <Box label="Local Fim">
        <ItemEndLocation />
      </Box>

      <FunctionField label="No. RSV" source="cgBookingNumber" render={record =>
        <ItemBookingNumber record={record} showInternalId={true} />
      } />
      <FunctionField label="Observação" source="observation" render={record => <ItemObs obs={record.observation} />} />
      <Box label="Voucher Obs." sx={{ width: '290px' }}>
        <ItemVoucherObs />
      </Box>
      <Box label="Cliente" sx={{ width: '125px' }}>
        <ItemClient />
      </Box>
      <Box label="Fornecedor" sx={{ width: '230px' }}>
        <ItemSupplier />
      </Box>
      {/* {user?.role?.name === 'ADMIN' ?
        <FunctionField label="Margem Bruta" render={record => {
          return (
            <>
              {record.price && record.price.value && record.supplierPrice && record.supplierPrice.value ? 
                <Box textAlign={'center'}>
                  {Math.round(((record.price.value - record.supplierPrice.value) / record.price.value) * 100)}%
                </Box>
              : ''}
            </>
          )
        }} />
      : ''} */}
    </Datagrid>
  )
}

export default ServiceItem