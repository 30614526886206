import { useRef, useState, useEffect } from 'react'
import { useListContext } from 'react-admin'
import { Button } from '@mui/material'
import EventIcon from '@mui/icons-material/Event'
import styles from './styles.module.css'
import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import Calendar from '../../components/Calendar'

const DateFilter = ({ source, label, ...props }) => {
  const {
    filterValues,
    setFilters
  } = useListContext()
  const [range, setRange] = useState()
  const detailsRef = useRef(null)
  const calendarComponentRef = useRef(null)

  useEffect(() => {
    if (source && filterValues && (filterValues[`${source}_gte`] || filterValues[`${source}_lte`])) {
      const newRange = {
        startDate: filterValues[`${source}_gte`] ? new Date(`${filterValues[`${source}_gte`].split('T')[0]}T00:00`) : null
      }

      if (filterValues[`${source}_lte`]) {
        newRange.endDate = new Date(`${filterValues[`${source}_lte`].split('T')[0]}T00:00`)
      } else if (filterValues[`${source}_gte`]) {
        newRange.endDate = new Date(`${filterValues[`${source}_gte`].split('T')[0]}T00:00`)
      } else {
        newRange.endDate = null
      }

      setRange(newRange)
    }
  }, [filterValues, source])

  const handleCalendarChange = (e) => {
    setRange(e.selection)
  }

  const handleApplyClick = () => {
    if (range) {
      const filterValuesAlt = { ...filterValues }
      filterValuesAlt[`${source}_gte`] = range.startDate ? range.startDate.toISOString().split('T')[0] : null
      filterValuesAlt[`${source}_lte`] = range.endDate ? `${range.endDate.toISOString().split('T')[0]}T23:59:59` : null // range.endDate && range.endDate !== range.startDate ? `${range.endDate.toISOString().split('T')[0]}T23:59:59` : null

      setFilters(filterValuesAlt)
    }

    if (detailsRef && detailsRef.current) {
      detailsRef.current.removeAttribute('open')
    }
  }

  const handleResetClick = () => {
    const filtersAlt = { ...filterValues }
    filtersAlt[`${source}_gte`] = null
    filtersAlt[`${source}_lte`] = null

    setFilters(filtersAlt)

    if (calendarComponentRef && calendarComponentRef.current) {
      calendarComponentRef.current.clear()
    }

    if (detailsRef && detailsRef.current) {
      detailsRef.current.removeAttribute('open')
    }
  }

  const handleTodayClick = () => {
    const today = new Date().toISOString().split('T')[0]
    const filtersAlt = { ...filterValues }
    filtersAlt[`${source}_gte`] = today
    filtersAlt[`${source}_lte`] = `${today}T23:59:59`

    setFilters(filtersAlt)

    if (detailsRef && detailsRef.current) {
      detailsRef.current.removeAttribute('open')
    }
  }

  if (!source) {
    return 'no source provided'
  }

  return (
    <details className={styles.wrapper} ref={detailsRef}>
      <summary className={styles.summary}>
        <EventIcon />
        {
          filterValues && (filterValues[`${source}_gte`] || filterValues[`${source}_lte`]) ?
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              {filterValues[`${source}_gte`] ? <span>{new Date(`${filterValues[`${source}_gte`].split('T')[0]}T00:00`).toLocaleDateString()}</span> : '...'}
              —
              {filterValues[`${source}_lte`] ? <span>{new Date(`${filterValues[`${source}_lte`].split('T')[0]}T00:00`).toLocaleDateString()}</span> : <AllInclusiveIcon style={{ margin: '0 4px' }} />}
            </div>
            : label || source
        }
      </summary>
      <div className={styles.popoverContent}>
        <Calendar
          ref={calendarComponentRef}
          monthsInView={2}
          scroll={false}
          minDate={new Date('1970-01-01')}
          onChange={handleCalendarChange}
          ranges={range ? [range] : []} />
        <div className={styles.actions}>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Button
              variant="outlined"
              onClick={handleResetClick}>
              Limpar
            </Button>
            <Button
              onClick={handleTodayClick}>
              Hoje
            </Button>
          </div>
          <Button
            variant="contained"
            onClick={handleApplyClick}>
            Aplicar
          </Button>
        </div>
      </div>
    </details>
  )
}

export default DateFilter
