import React from 'react'
import { List, Datagrid, TextField, DateField, EditButton, Authenticated, FunctionField } from 'react-admin'
import { GrLocation } from 'react-icons/gr'

const CustomList = (props) => {
  return (
    <Authenticated>
      <List {...props} queryOptions={{ meta: { populate: 'deep,3' } }}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" />
          <TextField source="googlePlaceFrom.label" label="Origem" />
          <FunctionField label="Destino" render={(record) => {
            return record.googlePlaceTo
              ? <>{record.googlePlaceTo.label}</>
              : <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}><GrLocation /> Arredores</span>
          }} />
          <TextField source="distance" label="Distancia (km)" />
          <TextField source="timeInWay" label="Duração (min)" />
          <DateField source="updatedAt" />
          <EditButton />
        </Datagrid>
      </List>
    </Authenticated>
  )
}

export default CustomList