import { useParams } from 'react-router-dom'
import Icon from '@mui/icons-material/Route'
import EditForm from '../EditForm'
import Container from '@mui/material/Container'

const TransferEdit = () => {
  const { id } = useParams()

  return (
    <Container>
      <h1 style={{ marginBottom: '12px', marginTop: '1em', display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Icon sx={{ width: '32px', height: '32px' }} />
        Editar rota de transfer #{id}
      </h1>
      <EditForm mode="edit" />
    </Container>
  )
}

export default TransferEdit
