import * as React from 'react'
import { useState, useEffect } from 'react'
import { useRecordContext } from 'react-admin'
import Tooltip, {tooltipClasses } from '@mui/material/Tooltip';
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

const ItemPax = () => {
  const record = useRecordContext()
  const [pax, setPax] = useState([])

  useEffect(() => {
    if (record && record.pax && record.pax.length) {
      setPax(record.pax)
    }
  }, [record])

  if (!pax || !pax.length) return null

  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            {pax.map((paxItem, index) =>
              <ul key={index} style={{ padding: '12px', margin: 0, listStyle: 'none', lineHeight: '22px' }}>
                <li><b style={{ fontSize: '14px' }}>{paxItem.name} {paxItem.surname}</b></li>
                {Object.keys(paxItem).map((key, i) => {
                  if (
                    key === 'id' ||
                    key === 'name' ||
                    key === 'surname' ||
                    key === 'whatsappAllowed' ||
                    key === 'phoneDDD' ||
                    key === 'phoneDDI' ||
                    key === 'mainPax' ||
                    key === 'countryCode'
                  ) return null

                  return <li key={i} style={{ color: '#444' }}><b>{key}</b>: {paxItem[key] ? paxItem[key].toString() : '?'}</li>
                })}
              </ul>
            )}
          </React.Fragment>
        }
      >
        <Button sx={{ padding: 0, fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
          {/* {(() => {
            const fullname = pax.name + ' ' + pax.surname
            return `${fullname.split(' ')[0]} ${fullname.split(' ')[1]}...`
          })()} */}
          {pax[0].name} {pax[0].surname}
        </Button>
      </HtmlTooltip>
    </>
  )
}

export default ItemPax
