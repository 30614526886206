import { fetchUtils, useRecordContext } from 'react-admin'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useState } from 'react'

const ClientRestrospective = () => {
  const record = useRecordContext()
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  if (!record) return null

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)

    const formData = new FormData(e.target)
    const startDate = formData.get('startDate')
    const endDate = formData.get('endDate')
    const url = `${process.env.REACT_APP_DATA_PROVIDER}/clients/${record.id}/retrospective?startDate=${startDate}&endDate=${endDate}`

    fetchUtils.fetchJson(url).then(async (res) => {
      setData(res.json)
    }).catch(error => {
      console.error(error)
      setData({ error })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <Stack>
      <form style={{ marginBottom: '20px' }} onSubmit={handleFormSubmit}>
        <Stack direction="row" gap="12px" alignItems="center">
          <TextField type="date" label="Data inicio" name='startDate' defaultValue="2023-01-01" required />
          <TextField type="date" label="Data fim" name='endDate' defaultValue="2023-12-31" required />
          <Button type='submit' variant="contained" size="small" sx={{ height: "44px" }}>
            {isLoading ? 'Aguarde...' : 'Obter Dados'}
          </Button>
        </Stack>
      </form>
      {data ? 
      <div>
        JSON:
        <pre style={{ fontSize: '10px', backgroundColor: 'black', color: 'white' }}>{JSON.stringify(data, null, 2)}</pre>
      </div>
      : ''}
    </Stack>
  )
}

export default ClientRestrospective