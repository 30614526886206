import { List, Datagrid, TextField, BooleanField, EditButton, TextInput, ShowButton } from 'react-admin'
import SearchIcon from "@mui/icons-material/Search"
import { useContext } from 'react'
import userContext from '../contexts/userContext'

const filters = [
  <TextInput source="company_name_contains" label="Razão social" InputProps={{
    endAdornment: (
      <SearchIcon style={{ fill: "#0f6b9b" }} />
    )
  }} alwaysOn />
]

const CustomList = () => {
  const [user] = useContext(userContext)

  return (
    <List filters={filters}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        {['ADMIN', 'MANAGER'].includes(user.role.name) ? <EditButton /> : <ShowButton />}
        <TextField source="company_name" label="Razão social" />
        <TextField source="cnpj" label="CNPJ" />
        <TextField source="cgClientId" label="Cangooroo ClientId" />
        <BooleanField source="canPayLater" label="Faturado" />
      </Datagrid>
    </List>
  )
}

export default CustomList