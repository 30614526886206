import Icon from '@mui/icons-material/Route'
import EditForm from '../EditForm'
import Container from '@mui/material/Container'

const TransferCreate = () => {
  return (
    <Container>
      <h1 style={{ marginBottom: '12px', marginTop: '1em', display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Icon sx={{ width: '32px', height: '32px' }} />
        Nova rota de transfer
      </h1>
      <EditForm />
    </Container>
  )
}

export default TransferCreate
