import { useState, useEffect } from 'react'
import { useRecordContext, useUpdate, useRefresh } from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'
import { supplierReservationPriorityOptions } from '../services/utils'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

const options = supplierReservationPriorityOptions

const ItemStatus = ({ readOnly = false, ...props }) => {
  const [update, { isLoading }] = useUpdate()
  const refresh = useRefresh()
  const record = useRecordContext()
  const [status, setStatus] = useState()

  useEffect(() => {
    if (record) {
      if (record.supplierReservationPriority) {
        setStatus(record.supplierReservationPriority)
      } else {
        setStatus('none')
      }
    }
  }, [record])

  const handleChange = (event) => {
    setStatus(event.target.value)

    const getDeadlineDate = (days = 0, serviceDate = null) => {
      let d = new Date()

      if (serviceDate) {
        d = new Date(serviceDate)
      }

      d.setUTCHours(0, 0, 0, 0)
      d.setDate(d.getDate() - days)
      return d.toISOString().split('T')[0]
    }

    let supplierReservationDeadline = record.supplierReservationDeadline

    if (event.target.value === 'none') {
      supplierReservationDeadline = null
    }

    if (event.target.value === 'asap') {
      supplierReservationDeadline = getDeadlineDate()
    }

    if (event.target.value === '7-days') {
      supplierReservationDeadline = getDeadlineDate(7, record.serviceDate)
    }

    if (event.target.value === '14-days') {
      supplierReservationDeadline = getDeadlineDate(14, record.serviceDate)
    }

    update(
      'services',
      {
        id: record.id,
        data: {
          supplierReservationPriority: event.target.value,
          supplierReservationDeadline: supplierReservationDeadline
        },
        previousData: record
      },
      {
        onSuccess: () => {
          refresh()
        }
      }
    )
  }

  if (!status) {
    return (
      <Box sx={{
        width: '70px',
        height: '24px',
        borderRadius: '4px',
        background: 'linear-gradient(90deg, #ddd, #ccc)'
      }}></Box>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '12px' }}>
        <Select
          value={status}
          onChange={handleChange}
          title={options[status].label}
          readOnly={readOnly}
          sx={{
            height: 24,
            minWidth: 70,
            color: 'white',
            textAlign: 'center',
            borderRadius: '4px',
            fontSize: '13px',
            fontWeight: '600',
            bgcolor: options[status].color,
            '.MuiSelect-select': {
              padding: '0 8px !important',
            },
            '.color-box': {
              display: 'none'
            },
            'svg': {
              display: 'none'
            }
          }}>
          {Object.keys(options).map((item, index) =>
            <MenuItem key={index} value={item}>
              <Box className='color-box' sx={{ backgroundColor: options[item].color, width: '16px', height: '16px', borderRadius: '4px', marginRight: '8px' }}></Box>
              {options[item].label}
            </MenuItem>
          )}
        </Select>
        {isLoading ? <CircularProgress size={24} /> : ''}
      </div>
    </>
  )
}

export default ItemStatus

