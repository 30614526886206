import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin'

/**
* Maps react-admin queries to a simple REST API
* @example
* GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
* GET_ONE      => GET http://my.api.url/posts/123
* GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
* UPDATE       => PUT http://my.api.url/posts/123
* CREATE       => POST http://my.api.url/posts
* DELETE       => DELETE http://my.api.url/posts/123
*/
const simpleRestProvider = (apiUrl, httpClient = fetchUtils.fetchJson, uploadFields = []) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params = {}) => {
    let url = ''
    const options = {}
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        const metaQueryString = new URLSearchParams(params.meta).toString()
        url = `${apiUrl}/${resource}?${adjustQueryForStrapi(params)}&${metaQueryString}`
        break
      case GET_ONE:
        // initialize queryObj with populate: 'deep' to get all the data
        let queryObj = {
          populate: 'deep'
        }

        // if meta is present, use it as queryObj, overriding the populate: 'deep'
        // this is useful for custom queries and to avoid populating all the data
        if (params.meta) {
          queryObj = { ...params.meta }
        }

        url = `${apiUrl}/${resource}/${params.id}?${new URLSearchParams(queryObj).toString()}`
        break
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'PUT'
        // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
        const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data
        options.body = JSON.stringify({ data: data })
        break
      case CREATE:
        url = `${apiUrl}/${resource}`
        options.method = 'POST'
        options.body = JSON.stringify({ data: params.data })
        break
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'DELETE'
        break
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }
    return { url, options }
  }

  const adjustQueryForStrapi = (params) => {

    /*
    params = { 
        pagination: { page: {int} , perPage: {int} }, 
        sort: { field: {string}, order: {string} }, 
        filter: {Object},
        target: {string}, (REFERENCE ONLY)
        id: {mixed} (REFERENCE ONLY)
    }
    */

    // Handle SORTING
    const s = params.sort
    const sort = s.field === "" ? "sort=updated_at:DESC" : ("sort=" + s.field + ":" + s.order)

    // Handle FILTER
    const operators = {
      '_gte': '$gte',
      '_lte': '$lte',
      '_lt': '$lt',
      '_neq': '$ne',
      '_null': '$null',
      '_contains': '$contains',
      '_containsi': '$containsi',
      '_in': '$in',
    }

    const f = params.filter || {}
    const keys = Object.keys(f)
    let filter = ""

    for (let i = 0 ; i < keys.length ; i++) {
      let field = keys[i]
      let value = f[keys[i]]

      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        function objectToString(obj, parentKey = '') {
          let result = [];

          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              let currentKey = parentKey ? `${parentKey}[${key}]` : key
        
              if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                result = result.concat(objectToString(obj[key], currentKey))
              } else {
                result.push(`${currentKey}=${obj[key]}`)
              }
            }
          }
        
          return result
        }

        const altObj = {}
        altObj[`[${keys[i]}]`] = value

        filter += `filters${objectToString(altObj).join('')}${keys[i + 1] ? "&" : ""}`
      } else {
        let operator = '$eq'
  
        // set >= as operator if field is date_gte and remove _gte from field
        // get last from split because field can be date_gte_gte
        const keyParts = field.split('_')
        const operationKey = keyParts.length ? '_' + keyParts[keyParts.length - 1] : null
  
        if (operationKey && Object.keys(operators).includes(operationKey)) {
          operator = operators[operationKey]
          field = field.replace(operationKey, '')
        }
  
        if (Array.isArray(value)) {
          filter += `${value.map(item => `filters[${field}][${operator}]=${item}`).join('&')}${keys[i + 1] ? "&" : ""}`
        } else {
          filter += `filters[${field}][${operator}]=${value}${keys[i + 1] ? "&" : ""}`
        }
      }
    }

    if (params.id && params.target && params.target.indexOf('_id') !== -1) {
      const target = params.target.substring(0, params.target.length - 3)
      // filter += "&" + target + "=" + params.id
      filter += `&filters[${target}][$eq]=${params.id}`
    }

    // Handle PAGINATION
    const { page, perPage } = params.pagination
    const start = (page - 1) * perPage
    const limit = perPage//for strapi the limit params indicate the amount of elements to return in the response
    const range = "pagination[start]=" + start + "&pagination[limit]=" + limit

    return `${sort}${range && `&${range}`}${filter && `&${filter}`}`
  }

  // Determines if there are new files to upload
  const determineUploadFieldNames = params => {
    if (!params || !params.data) return []

    // Check if the field names are mentioned in the uploadFields
    // and verify there are new files being added
    const requestUplaodFieldNames = []
    Object.keys(params.data).forEach(field => {
      let fieldData = params.data[field]
      if (uploadFields.includes(field)) {
        fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData
        fieldData.filter(f => f && f.rawFile instanceof File).length > 0
          && requestUplaodFieldNames.push(field)
      }
    })

    // Return an array of field names where new files are added
    return requestUplaodFieldNames
  }

  // Handles file uploading for CREATE and UPDATE types
  const handleFileUpload = (type, resource, params, uploadFieldNames) => {
    const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data
    const id = type === UPDATE ? `/${params.id}` : ""
    const url = `${apiUrl}/${resource}${id}`
    const requestMethod = type === UPDATE ? "PUT" : "POST"
    const formData = new FormData()

    for (let fieldName of uploadFieldNames) {
      let fieldData = params.data[fieldName]
      fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData
      const existingFileIds = []

      for (let item of fieldData) {
        item.rawFile instanceof File
          ? formData.append(`files.${fieldName}`, item.rawFile)
          : existingFileIds.push(item.id || item._id)
      }

      data[fieldName] = [...existingFileIds]
    }
    formData.append("data", JSON.stringify(data))

    return httpClient(url, {
      method: requestMethod,
      body: formData
    }).then(response => ({ data: replaceRefObjectsWithIds(response.json) }))
  }

  // Replace reference objects with reference object IDs	
  const replaceRefObjectsWithIds = json => {
    Object.keys(json).forEach(key => {
      const fd = json[key] // field data
      const referenceKeys = []
      if (fd && (fd.id || fd._id) && !fd.mime) {
        json[key] = fd.id || fd._id
      } else if (Array.isArray(fd) && fd.length > 0 && !fd[0].mime) {
        fd.map(item => referenceKeys.push(item.id || item._id))
        json[key] = referenceKeys
      }
    })
    return json
  }

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { json, total } = response
    switch (type) {
      case GET_ONE:
        // return { data: replaceRefObjectsWithIds({ id: json.data.id, ...json.data.attributes }) }
        return { data: { id: json.data.id, ...json.data.attributes } }
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (resource === 'upload/files') {
          return {
            data: json,
            total
          }
        }

        return {
          data: json.data.map(item => {
            return {id: item.id, ...item.attributes}
          }),
          total
        }
      case CREATE:
        return { data: { id: json.data.id, ...json.data.attributes } }
      case DELETE:
        return { data: { id: null } }
      default:
        return { data: json }
    }
  }

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} params Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resourceFromParams, params) => {
    const resource = resourceFromParams.split(':')[0] // used when a different page requests the same resource (services:modal)

    // Handle file uploading
    const uploadFieldNames = determineUploadFieldNames(params)
    if (uploadFieldNames.length > 0) {
      return handleFileUpload(type, resource, params, uploadFieldNames)
    }


    if (type === UPDATE) {
      return Promise.all(
        [params.id].map(id => {
          const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data
          return httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ data: data }),
          })
        })
      ).then(responses => ({
        data: responses.map(response => {
          const responseJson = response.json
          return {
            id: responseJson.data.id,
            ...responseJson.data.attributes
          }
        })[0],
      }))
    }

    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id => {
          // Omit created_at/updated_at(RDS) and createdAt/updatedAt(Mongo) in request body
          const { created_at, updated_at, createdAt, updatedAt, ...data } = params.data
          return httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ data: data }),
          })
        })
      ).then(responses => ({
        data: responses.map(response => {
          const responseJson = response.json
          return {
            id: responseJson.data.id,
            ...responseJson.data.attributes
          }
        }),
      }))
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }))
    }
    //strapi doesn't handle filters in GET route
    if (type === GET_MANY) {
      return Promise.all(
        params.ids.map(i =>
          httpClient(`${apiUrl}/${resource}/${i.id || i._id || i}`, {
            method: 'GET',
          })
        )
      ).then(responses => ({
        data: responses.map(response => {
          const responseJson = response.json 
          return {
            id: responseJson.id,
            ...responseJson.attributes
          }
        }),
      }))
    }

    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params
    )

    if (type === GET_MANY_REFERENCE || type === GET_LIST) {
      if (resource === 'upload/files') {
        return Promise.all([
          httpClient(url, options)
        ]).then(promises => {
          const response = {
            ...promises[0],
            // Add total for further use
            // total: parseInt(promises[0].json.meta.pagination.total, 10),
          }
          return convertHTTPResponse(response, type, resource, params)
        })
      }

      return Promise.all([
        httpClient(url, options)
      ]).then(promises => {
        const response = {
          ...promises[0],
          // Add total for further use
          total: parseInt(promises[0].json.meta.pagination.total, 10),
        }
        return convertHTTPResponse(response, type, resource, params)
      })
    } else {
      return httpClient(url, options).then((response) =>
        convertHTTPResponse(response, type, resource, params)
      )
    }
  }
} 

export default simpleRestProvider
