import { List, Datagrid, TextField, FunctionField, EditButton, SingleFieldList, ArrayField, DateField, ReferenceInput, AutocompleteInput, DateInput, SearchInput } from 'react-admin'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import EditableField from '../components/EditableField'
import ItemValidate from '../transactions/ItemValidate'
import DateFilter from '../accounts-payable/DateFilter'
import Tooltip from '@mui/material/Tooltip'

const filters = [
  <SearchInput source="description[$containsi]" placeholder="Descrição" alwaysOn />,
  <DateFilter source="paymentDueDate" label="Data Vencimento" alwaysOn />,
  <ReferenceInput source="[categories][id][$in]" reference="accounting-transaction-categories" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
    <AutocompleteInput sx={{ width: '320px' }} label="Categoria"
      optionText={choice =>
        `#${choice.id} - ${choice.name}`
      }
      filterToQuery={
        searchText => ({ name_containsi: searchText })
      } />
  </ReferenceInput>,
  <ReferenceInput source="[chart_of_accounts][id][$in]" reference="chart-of-accounts" alwaysOn>
    <AutocompleteInput sx={{ width: '320px' }} label="Plano de contas"
      optionText={choice =>
        `${choice.structural_code || '?'} - ${choice.name}`
      }
      filterToQuery={
        searchText => ({ name_containsi: searchText })
      } />
  </ReferenceInput>,
  <SearchInput source="invoiceNumber[$containsi]" placeholder="RSV / Lançamento" alwaysOn />,
]

const CustomList = (props) => {
  return (
    <List {...props}
      style={{ minWidth: '1900px' }}
      filters={filters}
      sort={{ field: 'paymentDueDate', order: 'ASC' }}
      storeKey={false}
      title="Contas a receber"
      resource="accounting-transactions"
      queryOptions={{
        meta: {
          // populate only the necessary relationships to improve performance
          populate: 'chart_of_accounts,bank_account,categories,parties',
          'filters[transactionType]': 'in',
          'filters[validated]': false
        }
      }}>
      {/* 
        Campos: Vencimento, valor, tipo, beneficiário, plano de contas, N. RSV, conta bancária, data baixa, botão ok para validar e baixar.
      */}
      <Datagrid>
        <Box label="ID">
          <FunctionField render={record => {
            return <EditButton label={record.id?.toString()} icon="" sx={{ padding: 0, minWidth: '32px', position: 'relative', left: '-8px' }} />
          }} />
        </Box>
        {/* <TextField source="description" label="DESCRIÇÃO" sortable={false}/> */}
        <Box label="DESCRIÇÃO" sx={{ minWidth: '180px' }}>
          <FunctionField render={record => (
            <Tooltip title={record.description}>
              <span>{record.description.split('|')[0]}</span>
            </Tooltip>
          )} />
        </Box>
        <DateField source="paymentDueDate" label="VENCIMENTO" />
        <Box label="VALOR" sx={{ width: '100px' }}>
          <FunctionField render={record => {
            return `${record.value ? record.value.toLocaleString('pt-BR', { style: 'currency', currency: record.currency }) : '?'}`
          }} />
        </Box>
        <ArrayField source="categories.data" label="CATEGORIA" sortable={false}>
          <SingleFieldList linkType={false}>
            <TextField source="attributes.name" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="parties.data" label="PAGADOR" sortable={false}>
          <SingleFieldList linkType={false}>
            <TextField source="attributes.name" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="chart_of_accounts.data" label="PLANO DE CONTAS" sortable={false}>
          <SingleFieldList linkType={false}>
            <FunctionField label="CONTA BANCÁRIA" source="chart_of_accounts" render={record => {
              return `${record.attributes.structural_code} - ${record.attributes.name}`
            }} />
          </SingleFieldList>
        </ArrayField>
        <FunctionField label="RSV / LANÇAMENTO / FATURA" render={record => {
          if (record.invoiceUrl) {
            return <a href={record.invoiceUrl} target="_blank" rel="noreferrer">{record.invoiceNumber}</a>
          }

          return record.invoiceNumber
        }} />
        <EditableField resource="accounting-transactions" label="CONTA BANCÁRIA"
          field={
            <FunctionField render={record => {
              if (!record?.bank_account?.data?.attributes?.name) {
                return <Button size="small">Definir</Button>
              }

              return record.bank_account.data.attributes.name
            }} />
          }
          input={
            <ReferenceInput source="bank_account.data.id" reference="bank-accounts">
              <AutocompleteInput
                fullWidth
                sx={{
                  '.MuiFormLabel-root': {
                    lineHeight: '1.4em !important'
                  },
                  '.MuiInputBase-root': {
                    padding: '0 !important',
                  },
                  '.MuiAutocomplete-input': {
                    padding: '8px 16px !important',
                    fontSize: '14px'
                  }
                }}
                label="Conta bancária"
                optionText={choice =>
                  `${choice.name}`
                }
                filterToQuery={
                  searchText => ({ name_containsi: searchText })
                } />
            </ReferenceInput>
          }
          transform={data => {
            return {
              ...data,
              bank_account: data?.bank_account?.data?.id
            }
          }}
        />
        <EditableField resource="accounting-transactions" label="DATA BAIXA"
          field={
            <FunctionField render={record => {
              if (!record.paymentSettlementDate) {
                return <Button size="small">Definir</Button>
              }

              return <DateField source="paymentSettlementDate" />
            }} />
          }
          input={<DateInput source="paymentSettlementDate" label="DATA BAIXA" />}
        />
        <ItemValidate />
      </Datagrid>
    </List>
  )
}

export default CustomList
