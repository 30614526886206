import { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import ButtonGroup from '@mui/material/ButtonGroup'
import Checkbox from '@mui/material/Checkbox'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CachedIcon from '@mui/icons-material/Cached'
import StrapiImage from '../StrapiImage'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

const SelectModal = ({ ...props }) => {
  const dataProvider = useDataProvider()
  const [open, setOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [items, setItems] = useState([])
  const [mode, setMode] = useState('select') // select | upload
  const handleOpen = () => setOpen(true)

  const handleClose = () => {
    setOpen(false)
    if (props.onChange) {
      props.onChange(selectedItems)
    }
  }

  const reloadItems = () => {
    dataProvider.getList('upload/files', {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'updatedAt', order: 'DESC' }
    }).then(({ data, total }) => {
      setItems(data)
    })
  }

  useEffect(() => {
    if (props.selectedItems && props.selectedItems.length) {
      setSelectedItems(props.selectedItems)
    }
  }, [props.selectedItems])

  useEffect(() => {
    if (dataProvider && (!items || !items.length)) {
      dataProvider.getList('upload/files', {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'updatedAt', order: 'DESC' }
      }).then(({ data, total }) => {
        setItems(data)
      })
    }
  }, [dataProvider, items])

  if (!items.length) {
    return 'Aguarde...'
  }

  return (
    <div>
      <Button onClick={handleOpen} sx={{ padding: '4px', fontSize: '13px', fontWeight: '600' }}>{props.buttonText || `Selecionar`}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {mode === 'upload' ?
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={() => setMode('select')}>Voltar</Button>
              </Box>
              <Button
                variant="contained"
                component="label"
              >
                Upload File
                <input
                  type="file"
                  hidden
                />
              </Button>
            </>
          : 
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Selecionar Arquivos
                </Typography>
                <Box sx={{ display: 'flex', gap: '16px' }}>
                  <Button onClick={reloadItems}>
                    <CachedIcon />
                  </Button>
                  <Button variant="contained" href={`${process.env.REACT_APP_STRAPI_URL}/admin/plugins/upload`} target="_blank">
                    Gerenciar Arquivos <OpenInNewIcon sx={{ width: '20px', height: '20px', marginLeft: '8px' }} />
                  </Button>
                </Box>
                {/* <Button variant="contained" onClick={() => setMode('upload')}>Adicionar novo</Button> */}
              </Box>

              <Box>
                <ImageList sx={{ width: 800, height: 450 }} cols={4} rowHeight={150}>
                  {items.map((item, index) => (
                    <ImageListItem key={index} sx={{ position: 'relative' }}>
                      <Checkbox size="small"
                        checked={(() => {
                          let selected = false

                          selectedItems.forEach((selectedItem) => {
                            if (selectedItem.id === item.id) {
                              selected = true
                            }
                          })

                          return selected
                        })()}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelectedItems([...selectedItems, item])
                          } else {
                            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id))
                          }
                        }}
                        sx={{
                          position: 'absolute',
                          top: '4px',
                          left: '4px',
                          padding: '0',
                          height: '18px',
                          width: '18px',
                          bgcolor: 'white',
                          borderRadius: '2px',
                          ':hover': { bgcolor: 'white' }
                        }} />
                      <StrapiImage
                        src={`${item.formats && item.formats.small ? item.formats.small.url : item.url}`}
                        alt={item.title}
                        width={'160'}
                        loading="lazy"
                      />
                      <ImageListItemBar
                        sx={{ '.MuiImageListItemBar-title': { fontSize: '13px', fontWeight: '600', lineHeight: '1.4', whiteSpace: 'break-spaces' } }}
                        title={`#${item.id}`}
                        subtitle={item.name}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
                <Box sx={{ textAlign: 'right' }}>
                  <ButtonGroup variant="outlined">
                    <Button size="small"><ChevronLeftIcon /></Button>
                    <Button size="small"><ChevronRightIcon /></Button>
                  </ButtonGroup>
                </Box>
              </Box>

              <Button onClick={handleClose} variant='contained' sx={{ marginTop: '16px' }}>OK</Button>
            </>
          }
        </Box>
      </Modal>
    </div>
  )
}

export default SelectModal