import Icon from '@mui/icons-material/WineBar'
import { Authenticated, Create, SimpleForm, TextInput, NumberInput } from 'react-admin'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

const TransferAdditItemCreate = () => {
  return (
    <Authenticated>
      <h1 style={{ marginBottom: '12px', marginTop: '1em', display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Icon sx={{ width: '32px', height: '32px' }} />
        Novo Item Adicional
      </h1>
      <Create sx={{ width: '100%' }}>
        <SimpleForm>
          <Box sx={{ width: '1300px', maxWidth: '100%' }}>
            <Stack direction="row" spacing={2} alignItems={'center'} width="100%">
              <TextInput source="namePT" label="Nome PT" fullWidth required sx={{ margin: 0 }} />
              <TextInput source="nameEN" label="Nome EN" fullWidth sx={{ margin: 0 }} />
              <TextInput source="nameES" label="Nome ES" fullWidth sx={{ margin: 0 }} />
              <TextInput source="code" label="Código" sx={{ width: '150px', flexShrink: 0 }} />
              <NumberInput source="basePriceUSD" label="Preço base (USD)" type="number" step={0.1} sx={{ width: '200px', flexShrink: 0 }} />
            </Stack>
          </Box>
        </SimpleForm>
      </Create>
    </Authenticated>
  )
}

export default TransferAdditItemCreate
