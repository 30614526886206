import { useState, useContext } from 'react'
import userContext from './contexts/userContext'
import { Menu } from 'react-admin'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import CategoryIcon from '@mui/icons-material/Category'
import ListIcon from '@mui/icons-material/List'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import KeyIcon from '@mui/icons-material/Key'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import CardTravelIcon from '@mui/icons-material/CardTravel'
import DvrIcon from '@mui/icons-material/Dvr'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import TransfersIcon from '@mui/icons-material/DirectionsCar'
import UpcomingIcon from '@mui/icons-material/Upcoming'
import RouteIcon from '@mui/icons-material/Route'
import WineBarIcon from '@mui/icons-material/WineBar'
import ArticleIcon from '@mui/icons-material/Article'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import PaymentIcon from '@mui/icons-material/Payment'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import EventIcon from '@mui/icons-material/Event'

const MyMenu = (props) => {
  const [user] = useContext(userContext)
  const [open, setOpen] = useState({
    records: false,
    transfers: false
  })

  const handleClick = (key) => {
    const openAlt = { ...open }
    openAlt[key] = !openAlt[key]
    setOpen(openAlt)
  }

  if (!user) return null

  return (
    <Menu {...props}
      sx={{
        color: 'white',
        '.MuiListItemIcon-root': {
          minWidth: '40px',
        },
        '.MuiListItemButton-root': {
          paddingTop: '6px',
          paddingBottom: '6px',
          borderLeft: '3px solid #fff'
        },
        '.MuiListItemText-root': {
          margin: 0
        }
      }}>
      {/* <Menu.DashboardItem /> */}
      {user.role.name === 'ADMIN' ?
        [
          <Menu.Item key="services" to="/services" primaryText="Planning" leftIcon={<DvrIcon />} />,
          <Menu.Item key="bookingFlow" to="/booking-flow" primaryText="Fluxo de reservas" leftIcon={<UpcomingIcon />} />,
          <Menu.Item key="quotations" to="/quotations" primaryText="Orçamentos" leftIcon={<RequestQuoteIcon />} />,
          <ListItemButton key="financial" onClick={() => handleClick('financial')}>
            <ListItemIcon>
              <PointOfSaleIcon />
            </ListItemIcon>
            <ListItemText primary="Financeiro" />
            {open.financial ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>,
          <Collapse key="financialCollapse" in={open.financial} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: '0', '.MuiButtonBase-root': { paddingLeft: '28px', border: 0 } }}>
              <Menu.Item to="/accounts-payable" primaryText="Contas a pagar" leftIcon={<MonetizationOnIcon />} />
              <Menu.Item to="/accounts-receivable" primaryText="Contas a receber" leftIcon={<MonetizationOnIcon />} />
              <Menu.Item to="/accounting-transactions" primaryText="Mov. Financeira" leftIcon={<PointOfSaleIcon />} />
              <Menu.Item to="/supplier-payments" primaryText="Pgto. Fornecedor" leftIcon={<EmojiTransportationIcon />} />
              <Menu.Item to="/operating-income" primaryText="Receita Op." leftIcon={<DvrIcon />} />
              <Menu.Item to="/accounting-transaction-parties" primaryText="Beneficiarios" leftIcon={<PeopleOutlineIcon />} />
              <Menu.Item to="/accounting-transaction-categories" primaryText="Categorias" leftIcon={<CategoryIcon />} />
            </List>
          </Collapse>,
          <ListItemButton key="record" onClick={() => handleClick('records')}>
            <ListItemIcon>
              <CreateNewFolderIcon />
            </ListItemIcon>
            <ListItemText primary="Cadastros" />
            {open.records ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>,
          <Collapse key="recordsCollapse" in={open.records} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: '0', '.MuiButtonBase-root': { paddingLeft: '28px', border: 0 } }}>
              <Menu.Item key="clients" to="/clients" primaryText="Clientes" leftIcon={<AssignmentIndIcon />} />
              <Menu.Item key="suppliers" to="/suppliers" primaryText="Fornecedores" leftIcon={<EmojiTransportationIcon />} />
              <Menu.Item key="costCenters" to="/cost-centers" primaryText="Centros de custo" leftIcon={<KeyIcon />} />
              <Menu.Item key="productCategories" to="/product-categories" primaryText="Categoria produto" leftIcon={<CategoryIcon />} />
              <Menu.Item key="chartOfAccounts" to="/chart-of-accounts" primaryText="Plano de contas" leftIcon={<AccountTreeIcon />} />
              <Menu.Item key="clientCategories" to="/client-categories" primaryText="Class. cliente" leftIcon={<ListIcon />} />
            </List>
          </Collapse>,
          <ListItemButton key="bookings" onClick={() => handleClick('bookings')}>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Reservas" />
            {open.bookings ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>,
          <Collapse key="bookingsCollapse" in={open.bookings} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: '0', '.MuiButtonBase-root': { paddingLeft: '28px', border: 0 } }}>
              <Menu.Item to="/bookings" primaryText="Booking Engine" leftIcon={<DvrIcon />} />
              <Menu.Item to="/service-requests" primaryText="Solicitações" leftIcon={<UpcomingIcon />} />
            </List>
          </Collapse>,
          <ListItemButton key="transfers" onClick={() => handleClick('transfers')}>
            <ListItemIcon>
              <TransfersIcon />
            </ListItemIcon>
            <ListItemText primary="Transfers" />
            {open.transfers ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>,
          <Collapse key="transfersCollapse" in={open.transfers} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: '0', '.MuiButtonBase-root': { paddingLeft: '28px', border: 0 } }}>
              <Menu.Item to="/transfer-routes" primaryText="Rotas" leftIcon={<RouteIcon />} />
              <Menu.Item to="/transfer-vehicles" primaryText="Veículos" leftIcon={<TransfersIcon />} />
              <Menu.Item to="/transfer-additional-services" primaryText="Adicionais" leftIcon={<WineBarIcon />} />
              <Menu.Item to="/transfer-contracts" primaryText="Contratos" leftIcon={<ArticleIcon />} />
            </List>
          </Collapse>,
          <Menu.Item key="create-cielo-link" to="/create-cielo-link" primaryText="Link de Pagamento" leftIcon={<PaymentIcon />} />,
          <Menu.Item key="imported-items" to="/imported-items" primaryText="Audit. importação" leftIcon={<CloudDoneIcon />} />
        ]
        : []}

      {user.role.name === 'MANAGER' ?
        [
          <Menu.Item key="services" to="/services" primaryText="Planning" leftIcon={<DvrIcon />} />,
          <Menu.Item key="bookingFlow" to="/booking-flow" primaryText="Fluxo de reservas" leftIcon={<UpcomingIcon />} />,
          <Menu.Item key="quotations" to="/quotations" primaryText="Orçamentos" leftIcon={<RequestQuoteIcon />} />,
          // <ListItemButton key="financial" onClick={() => handleClick('financial')}>
          //   <ListItemIcon>
          //     <PointOfSaleIcon />
          //   </ListItemIcon>
          //   <ListItemText primary="Financeiro" />
          //   {open.financial ? <ExpandLess /> : <ExpandMore />}
          // </ListItemButton>,
          // <Collapse key="financialCollapse" in={open.financial} timeout="auto" unmountOnExit>
          //   <List component="div" sx={{ padding: '0', '.MuiButtonBase-root': { paddingLeft: '28px', border: 0 } }}>
          //     <Menu.Item to="/accounts-payable" primaryText="Contas a pagar" leftIcon={<MonetizationOnIcon />} />
          //     <Menu.Item to="/accounts-receivable" primaryText="Contas a receber" leftIcon={<MonetizationOnIcon />} />
          //     <Menu.Item to="/accounting-transactions" primaryText="Mov. Financeira" leftIcon={<PointOfSaleIcon />} />
          //     <Menu.Item to="/supplier-payments" primaryText="Pgto. Fornecedor" leftIcon={<EmojiTransportationIcon />} />
          //   </List>
          // </Collapse>,
          <ListItemButton key="record" onClick={() => handleClick('records')}>
            <ListItemIcon>
              <CreateNewFolderIcon />
            </ListItemIcon>
            <ListItemText primary="Cadastros" />
            {open.records ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>,
          <Collapse key="recordsCollapse" in={open.records} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: '0', '.MuiButtonBase-root': { paddingLeft: '28px', border: 0 } }}>
              <Menu.Item key="clients" to="/clients" primaryText="Clientes" leftIcon={<AssignmentIndIcon />} />
              <Menu.Item key="suppliers" to="/suppliers" primaryText="Fornecedores" leftIcon={<EmojiTransportationIcon />} />
              <Menu.Item key="productCategories" to="/product-categories" primaryText="Categoria produto" leftIcon={<CategoryIcon />} />
              <Menu.Item key="clientCategories" to="/client-categories" primaryText="Class. cliente" leftIcon={<ListIcon />} />
            </List>
          </Collapse>,
          // <ListItemButton key="transfers" onClick={() => handleClick('transfers')}>
          //   <ListItemIcon>
          //     <TransfersIcon />
          //   </ListItemIcon>
          //   <ListItemText primary="Transfers" />
          //   {open.transfers ? <ExpandLess /> : <ExpandMore />}
          // </ListItemButton>,
          // <Collapse key="transfersCollapse" in={open.transfers} timeout="auto" unmountOnExit>
          //   <List component="div" sx={{ padding: '0', '.MuiButtonBase-root': { paddingLeft: '28px', border: 0 } }}>
          //     <Menu.Item to="/transfer-routes" primaryText="Rotas" leftIcon={<RouteIcon />} />
          //     <Menu.Item to="/transfer-vehicles" primaryText="Veículos" leftIcon={<TransfersIcon />} />
          //     <Menu.Item to="/transfer-additional-services" primaryText="Adicionais" leftIcon={<WineBarIcon />} />
          //     <Menu.Item to="/transfer-contracts" primaryText="Contratos" leftIcon={<ArticleIcon />} />
          //   </List>
          // </Collapse>,
          <Menu.Item key="create-cielo-link" to="/create-cielo-link" primaryText="Link de Pagamento" leftIcon={<PaymentIcon />} />,
          <Menu.Item key="imported-items" to="/imported-items" primaryText="Audit. importação" leftIcon={<CloudDoneIcon />} />
        ]
        : []}

      {user.role.name === 'CONSULTANT' ?
        [
          <Menu.Item key="services" to="/services" primaryText="Planning" leftIcon={<DvrIcon />} />,
          <Menu.Item key="bookingFlow" to="/booking-flow" primaryText="Fluxo de reservas" leftIcon={<UpcomingIcon />} />,
          <Menu.Item key="quotations" to="/quotations" primaryText="Orçamentos" leftIcon={<RequestQuoteIcon />} />,
          <Menu.Item key="create-cielo-link" to="/create-cielo-link" primaryText="Link de Pagamento" leftIcon={<PaymentIcon />} />,
        ]
        : []}

      {user.role.name === 'AFFILIATE' ?
        [
          <Menu.Item to="/services" primaryText="Reservas" leftIcon={<CardTravelIcon />} />
        ]
        : []}

      {user.role.name === 'MARKETING' ?
        [
          <ListItemButton key="record" onClick={() => handleClick('records')}>
            <ListItemIcon>
              <CreateNewFolderIcon />
            </ListItemIcon>
            <ListItemText primary="Cadastros" />
            {open.records ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>,
          <Collapse key="recordsCollapse" in={open.records} timeout="auto" unmountOnExit>
            <List component="div" sx={{ padding: '0', '.MuiButtonBase-root': { paddingLeft: '28px', border: 0 } }}>
              <Menu.Item key="clients" to="/clients" primaryText="Clientes" leftIcon={<AssignmentIndIcon />} />
            </List>
          </Collapse>
        ]
        : []}
    </Menu>
  )
}

export default MyMenu
