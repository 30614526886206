import { SaveButton, Toolbar } from 'react-admin'

const EditFormToolbar = ({ mode, ...props }) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      <SaveButton alwaysEnable label="Salvar" />
      {/* <DeleteButton mutationMode="pessimistic" /> */}
    </Toolbar> 
  )
}

export default EditFormToolbar
