import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'
import AdditionalServiceItem from './AdditionalServiceItem'
import Switch from '@mui/material/Switch'
import StrapiImage from '../../media-library/StrapiImage'

const OptionCard = ({ option, currency, ...props }) => {
  const handlePriceChange = (e) => {
    if (props.onChange) {
      const optionAlt = { ...option }
      optionAlt[e.target.name] = Number(e.target.value)
      props.onChange({ index: props.index, option: optionAlt })
    }
  }

  const handleAdditionalServiceChange = (e) => {
    if (props.onChange) {
      const additionalServicesAlt = [...option.additionalServices]
      additionalServicesAlt[e.index] = e.service
      props.onChange({ index: props.index, option: { ...option, additionalServices: additionalServicesAlt } })
    }
  }

  const handleFerryIncludedChange = (e) => {
    if (props.onChange) {
      props.onChange({ index: props.index, option: { ...option, ferryIncluded: e.target.checked } })
    }
  }

  const handleActiveChange = (e) => {
    if (props.onChange) {
      props.onChange({ index: props.index, option: { ...option, active: e.target.checked } })
    }
  }

  return (
    <Box sx={{
      position: 'relative',
      borderRadius: '8px',
      width: '100%',
      border: '1px solid #eee',
      padding: '16px',
      marginBottom: '20px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.12)',
      boxSizing: 'border-box',
      backgroundColor: '#fff',
      opacity: option.active ? 1 : 0.5
    }}>
      <Box sx={{ display: 'flex', gap: '24px', alignItems: 'center', color: '#333', marginBottom: '20px' }}>
        <StrapiImage
          src={`${option.vehicle?.images && option.vehicle.images.data && option.vehicle.images.data.length ?
            `${option.vehicle.images.data[0].attributes.url}`
            : `https://fakeimg.pl/720x480`}`}
          alt="option"
          style={{ width: '200px', height: '130px', objectFit: 'contain', paddingRight: '24px', borderRight: '1px solid #eee' }} />
        <Box sx={{ width: '100%' }}>
          <h3 style={{ marginBottom: 0 }}>{option.vehicle.namePT}</h3>
          <p style={{ marginTop: '8px', marginBottom: '8px', color: '#555', fontSize: '14px' }}>{option.vehicle.modelsPT}</p>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', width: '100%' }}>
            <List sx={{ '.MuiListItemText-root .MuiTypography-root': { fontSize: '14px' }, width: '100%', maxWidth: '262px' }}>
              <ListItem disablePadding>
                <ListItemText primary={`${option.vehicle.maxSmallBaggage || 0} malas pequenas`} />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary={`${option.vehicle.maxMediumBaggage || 0} malas médias`} />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText primary={`${option.vehicle.minPax || 1} a ${option.vehicle.maxPax || '?'} passageiros`} />
              </ListItem>
            </List>
            <Box sx={{ display: 'flex', gap: '16px', width: '100%' }}>
              <TextField label={`Preço Km ${currency || ''}`} type="number"
                variant="outlined"
                value={option.priceKmUSD || ''}
                required={option.active}
                inputProps={{ name: 'priceKmUSD', step: 0.1, min: 0 }}
                onChange={handlePriceChange} />
              <TextField label={`Preço Base ${currency || ''}`} type="number"
                variant="outlined"
                value={option.priceUSD || ''}
                required={option.active}
                inputProps={{ name: 'priceUSD', min: 0 }}
                onChange={handlePriceChange} />
            </Box>
          </Box>
        </Box>
        {/* <IconButton type="button" aria-label="delete" sx={{ position: 'absolute', top: '8px', right: '8px' }}>
          <BiTrash />
        </IconButton> */}
        <Box sx={{ position: 'absolute', top: '8px', right: '8px', display: 'flex', alignItems: 'center', gap: '4px' }}>
          {/* <div style={{ flexShrink: 0, fontSize: '14px' }}>
            Habilitado
          </div> */}
          <Switch
            title="Ativar"
            sx={{
              '.MuiSwitch-switchBase.Mui-checked': {
                color: 'green',
                '&+.MuiSwitch-track': {
                  backgroundColor: 'green'
                }
              },
            }}
            checked={option.active || false}
            onChange={handleActiveChange}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: '24px', alignItems: 'start', color: '#333' }}>
        <Box sx={{ flex: '1' }}>
          {option.additionalServices ?
            <>
              <h4 style={{ margin: '0 0 16px' }}>Adicionais</h4>
              <FormGroup sx={{ gap: '12px', '.MuiFormControlLabel-root': { height: '24px', marginRight: '6px' }, '.MuiTypography-root': { fontSize: '14px' } }}>
                {option.additionalServices.map((service, index) =>
                  <AdditionalServiceItem key={index} index={index} service={service} currency={currency} onChange={handleAdditionalServiceChange} />
                )}
              </FormGroup>
            </>
            : ''}
        </Box>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <div style={{ flexShrink: 0, fontSize: '14px' }}>
              Balsa inclusa?
            </div>
            <Switch
              checked={option.ferryIncluded || false}
              onChange={handleFerryIncludedChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OptionCard
