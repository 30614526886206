import {
  getCityName as getCityNameFromIndex,
  fixPlaceName as fixPlaceNameFromIndex
} from './index'

export const getCityName = getCityNameFromIndex
export const fixPlaceName = fixPlaceNameFromIndex

/**
 * 
 * @param {*} record 
 * @returns the start location string
 */
export const getStartLocationString = (record) => {
  if (!record) {
    return ''
  }

  if (record.serviceType === 'Car') {
    return `${record.vendorName} - ${record.carModel} - Location: ${record.originName}`
  }

  if (record.serviceType === 'Insurances') {
    return record.tourName
  }

  if (record.serviceType === 'Hotel') {
    return record.destinationName && !record.destinationName.toLowerCase().includes('hotel') ?
      `Hotel: ${record.destinationName}`
      : record.destinationName
  }

  if (record.serviceType === 'Tour') {
    return `${record.tourName} ${record.tourAddress ? `- ${record.tourAddress}` : ''}`
  }

  return (record.originName || record.destinationName) ?
    (() => {
      if (record.originAddressType && record.originAddressType === 'Airport') {
        return (
          `Airport: ${fixPlaceName(record.originName)} ${record.originAirNumber ? `- Flight: ${record.originAirNumber}` : ''}`
        )
      }

      if (record.originAddressType && record.originAddressType === 'Accomodation') {
        const accomodationName = fixPlaceName(record.originLocation)
        return (
          `${accomodationName.toLowerCase().includes('hotel') ? '' : 'Hotel: '}` +
          `${accomodationName} - ${record.originAddress}`
        )
      }

      if (record.originName || record.originAddress) {
        const originStr = fixPlaceName(record.originName || record.originAddress)
        return `${originStr}` +
          `${record.originAddress && record.originAddress !== originStr ? ` - ${record.originAddress}` : ''}` +
          `${record.originAddressComplement ? ` - ${record.originAddressComplement}` : ''}` +
          `${record.additOriginNumberInfo && !originStr.includes(record.additOriginNumberInfo) ? ` - ${record.additOriginNumberInfo}` : ''}`
      } else {
        return '?'
      }
    })()
    : record.tourName ? record.tourName : ''
}

/**
 * 
 * @param {*} record 
 * @returns the end location string
 */
export const getEndLocationString = (record) => {
  const typesWithoutDestination = ['Car', 'Insurances', 'Hotel', 'Tour']

  if (record && (!typesWithoutDestination.includes(record.serviceType) && (record.destinationName || record.destinationAddress))) {
    if (record.destinationAddressType && record.destinationAddressType === 'Accomodation') {
      const accomodationName = fixPlaceName(record.destinationLocation)
      return (
        `${accomodationName.toLowerCase().includes('hotel') ? '' : 'Hotel: '}` +
        `${accomodationName} - ${record.destinationAddress}`
      )
    }

    if (record.destinationName || record.destinationLocation || record.additInfoDestinationCity) {
      const destinationStr = fixPlaceName(record.destinationName || record.destinationLocation || record.additInfoDestinationCity)

      return `${destinationStr}` +
        `${record.destinationAddress && record.destinationAddress !== destinationStr ? ` - ${record.destinationAddress}` : ''}` +
        `${record.destinationAddressComplement ? ` - ${record.destinationAddressComplement}` : ''}` +
        `${record.additDestinationNumberInfo && !destinationStr.includes(record.additDestinationNumberInfo) ?
          ` - ${record.additDestinationNumberInfo}` : ''}`
    }

    return '?'
  }

  return null
}
