import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { fetchUtils, useRecordContext } from 'react-admin'
import Box from '@mui/material/Box'

const ConvertedDateFetcher = ({ ...props }) => {
  const record = useRecordContext()
  const [convertedDate, setConvertedDate] = useState(null)
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  useEffect(() => {
    if (inView && !convertedDate && record.serviceDate) {
      const getTimeStamp = async () => {
        const params = {
          itemId: record.id,
          time: new Date(new Date(record.serviceDate).getTime() - new Date().getTimezoneOffset() * 60000).getTime(),
          targetTimeZone: 'America/Sao_Paulo'
        }
  
        if (params.itemId && params.time) {
          const url = `${process.env.REACT_APP_DATA_PROVIDER}/services/convert-time-zone?${new URLSearchParams(params).toString()}`

          fetchUtils.fetchJson(url).then(async (res) => {
            setConvertedDate(res.json)
          })
          .catch(error => {
            console.error('error', error)
          })
        }
      }

      getTimeStamp()
    }
  }, [record, inView, convertedDate, record.id, record.cityId, record.serviceDate])

  return (
    <div ref={ref} style={{ height: '63px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
     {convertedDate ?
      <Box>
        <div>🇧🇷</div>
        <div>{new Date(convertedDate.timestamp).toLocaleDateString('pt-BR', { timeZone: 'UTC' })}</div>
        <Box sx={{
          padding: '1px 4px',
          color: 'white',
          fontSize: '13px',
          fontWeight: 'bold',
          backgroundColor: record.operationalStatus === 'notDone' ? 'green' : 'gray',
          gridColumn: '1 / 3',
          borderRadius: '4px' }}>
          {new Date(convertedDate.timestamp).toLocaleTimeString('pt-BR', { timeZone: 'UTC' }).slice(0, -3)}
        </Box>
      </Box>
     : '-'}
    </div>
  )
}

export default ConvertedDateFetcher