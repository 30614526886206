import { useState, useEffect } from 'react'
import { useRecordContext, useUpdate } from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import { supplierStatusOptions as options } from '../utils'

const ItemStatus = ({ readOnly = false, ...props }) => {
  const [update, { isLoading }] = useUpdate()
  const record = useRecordContext()
  const [status, setStatus] = useState()

  useEffect(() => {
    if (record) {
      if (record.supplierStatus) {
        setStatus(record.supplierStatus)
      } else {
        setStatus('reservation')
      }
    }
  }, [record])

  const handleChange = (event) => {
    if (event.target.value === 'confirmed' && record && record.cgStatus && record.cgStatus === 'Cancelled') {
      if (!window.confirm('A reserva está cancelada. Deseja realmente confirmar o fornecedor?')) {
        return
      }
    }

    setStatus(event.target.value)

    update(
      'services',
      { id: record.id, data: { supplierStatus: event.target.value }, previousData: record }
    )
  }

  if (!status) {
    return (
      <Box sx={{
        width: '24px',
        height: '24px',
        borderRadius: '6px',
        background: 'linear-gradient(90deg, #ddd, #ccc)'
      }}></Box>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '12px' }}>
        <Select
          value={status}
          readOnly={readOnly}
          onChange={handleChange}
          title={options[status].label}
          sx={{
            height: 24,
            width: 24,
            textIndent: '-999px',
            color: 'white',
            borderRadius: '6px',
            fontSize: '13px',
            fontWeight: '600',
            bgcolor: options[status].color,
            '.color-box': {
              display: 'none'
            },
            'svg': {
              display: 'none'
            }
          }}>
          {Object.keys(options).map((item, index) =>
            <MenuItem key={index} value={item}>
              <Box className='color-box' sx={{ backgroundColor: options[item].color, width: '16px', height: '16px', borderRadius: '4px', marginRight: '8px' }}></Box>
              {options[item].label}
            </MenuItem>
          )}
        </Select>
        {isLoading ? <CircularProgress size={24} /> : ''}
      </div>
    </>
  )
}

export default ItemStatus
