import React, { useCallback, useMemo, useState, useEffect } from 'react'
import {
  useAuthProvider,
  TextInput,
  SimpleForm,
  required,
  useDataProvider,
  useNotify,
  SaveContextProvider
} from 'react-admin'

export const ProfileEdit = ({ staticContext, ...props }) => {
  const dataProvider = useDataProvider()
  const authProvider = useAuthProvider()
  const notify = useNotify()
  const [identity, setIdentity] = useState()
  // const [isLoading, setIsLoading] = useState()
  // const { data: identity, isLoading } = useGetIdentity()
  const [saving, setSaving] = useState()

  useEffect(() => {
    try {
      const data = authProvider.getUser()
      setIdentity(data)
      // setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }, [authProvider])

  const handleSave = useCallback((values) => {
    setSaving(true)
    dataProvider.updateUserProfile(
      { data: values },
      {
        onSuccess: ({ data }) => {
          setSaving(false);
          notify("Your profile has been updated", "info", {
            _: "Your profile has been updated"
          });
        },
        onFailure: () => {
          setSaving(false);
          notify(
            "A technical error occured while updating your profile. Please try later.",
            "warning",
            {
              _:
                "A technical error occured while updating your profile. Please try later."
            }
          );
        }
      }
    );
  }, [dataProvider, notify])

  const saveContext = useMemo(() => ({
    save: handleSave,
    saving
  }), [saving, handleSave])

  if (!identity) {
    return null
  }

  console.log(identity)

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm save={handleSave} record={identity ? identity : {}}>
        <TextInput source="username" validate={required()} />
      </SimpleForm>
    </SaveContextProvider>
  )
}
