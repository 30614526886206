import StatusButton from '../../components/StatusButton'

const options = {
  '': {
    label: 'Não Enviada',
    color: '#ef3133',
    value: null,
    disabled: true
  },
  'notSent': {
    label: 'Não Enviada',
    color: '#ef3133',
    value: 'false'
  },
  'awaiting': {
    label: 'Aguardando Confirmação',
    color: '#4290f5',
    value: 'awaiting'
  },
  'sent': {
    label: 'Respondida',
    color: '#4ec21d',
    value: 'true'
  }
}

const ItemSupplierMessageStatus = () => {
  return <StatusButton resource="services" source="supplierMessageSent" options={options} />
}

export default ItemSupplierMessageStatus
