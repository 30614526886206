import { useState, useEffect } from 'react'
import { useRecordContext } from 'react-admin'
import Box from '@mui/material/Box'
import { cgStatusOptions as options } from '../utils'

const ItemCgStatus = () => {
  const record = useRecordContext()
  const [status, setStatus] = useState()

  useEffect(() => {
    if (record) {
      if (record.cgStatus) {
        setStatus(record.cgStatus)
      }
    }
  }, [record])

  if (!status) {
    return (
      <Box sx={{
        width: '24px',
        height: '24px',
        borderRadius: '6px',
        background: 'linear-gradient(90deg, #ddd, #ccc)'
      }}></Box>
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '12px' }}>
      <Box title={options[status].label} sx={{
        height: 24,
        width: 24,
        bgcolor: options[status].color,
        borderRadius: '6px',
        cursor: 'not-allowed'
      }}></Box>
    </div>
  )
}

export default ItemCgStatus
