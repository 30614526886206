import { InfiniteList, Authenticated, Datagrid, TextField, DateField, EditButton, FunctionField, useNotify } from 'react-admin'
import { getContent } from '../utils'
import Button from '@mui/material/Button'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const CustomList = (props) => {
  const notify = useNotify()
  const meta = {}

  // populate relations
  meta[`populate`] = 'deep,3'

  const handleCopyContentClick = (record) => {
    const content = getContent(record)
    navigator.clipboard.writeText(content)
    notify(`Conteúdo copiado!`, { type: 'success' })
  }

  return (
    <Authenticated>
      <InfiniteList {...props}
        title="Orçamentos"
        resource="quotations"
        queryOptions={{ meta: meta }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        debounce={500}>
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" />
          <DateField source="createdAt" label="Criado em" />
          <DateField source="expiry" label="Data Vencimento" />
          <TextField source="client.data.attributes.company_name" label="Agencia" />
          <DateField source="seller" label="Vendedor" />
          <FunctionField render={(record) => {
            return (
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ gap: '8px' }}
                startIcon={<ContentCopyIcon />}
                onClick={() => handleCopyContentClick(record)}>
                Copiar
              </Button>
            )
          }} />
          <EditButton resource="quotations" />
        </Datagrid>
      </InfiniteList>
    </Authenticated>
  )
}

export default CustomList
