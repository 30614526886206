import { useState, useEffect, useRef } from 'react'
import { useInput, useDataProvider, useEditContext } from 'react-admin'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Item from './Item'

const ServiceSelector = ({ source = 'services', label, helperText, sx }) => {
  const { isLoading, isFetching } = useEditContext()
  const dataProvider = useDataProvider()
  const { field } = useInput({ source })
  const suggestionRef = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [suggestionItems, setSuggestionItems] = useState([])
  const items = field?.value?.data?.map(item => {
    return {
      id: item.id,
      ...item.attributes
    }
  }) || []

  const handleSearchClick = () => {
    const [bookingNumber, serviceId] = searchText.split('-').map(s => s.trim())
    const filter = {}

    if (bookingNumber) filter.cgBookingNumber = bookingNumber
    if (serviceId) filter.cgServiceId = serviceId

    dataProvider.getList('services', {
      pagination: { page: 1, perPage: 25 },
      sort: { field: 'id', order: 'DESC' },
      meta: { populate: 'deep,1' },
      filter
    })
      .then(({ data }) => {
        setSuggestionItems(data)
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    /**
     * Detects if clicked outside suggestionRef
     */
    function handleClickOutside(event) {
      if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
        setSuggestionItems([])
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    };
  }, [suggestionRef])

  return (
    <Stack gap={2} sx={{ ...sx }}>
      <Stack position="relative">
        <Stack direction="row" alignItems="center" gap={1} width="380px" position="relative">
          <TextField sx={{ width: '100%' }} label={label} helperText={helperText} value={searchText} onChange={e => setSearchText(e.target.value)} />
          <IconButton variant="contained"
            onClick={handleSearchClick}
            aria-label="Search"
            color="primary"
            sx={{ position: 'absolute', right: '8px', top: '16px' }}>
            <SearchIcon />
          </IconButton>
        </Stack>
        {suggestionItems.length ?
          <Stack
            ref={suggestionRef}
            divider={<Divider />}
            sx={{
              position: 'absolute',
              top: 'calc(100% - 30px)',
              boxShadow: '0 3px 8px rgba(0, 0, 0, .3)',
              backgroundColor: 'white',
              borderRadius: '8px',
              zIndex: 9,
              overflowY: 'scroll',
              maxHeight: '480px',
            }}>
            {suggestionItems.map((item, index) => (
              <Item key={index} item={item} actions={
                <>
                  {items.find(i => i.id === item.id) ?
                    <IconButton disabled={true}>
                      <CheckIcon color="success" />
                    </IconButton>
                    :
                    <IconButton onClick={() => {
                      field.onChange({
                        target: {
                          value: {
                            data: [
                              ...items.map(i => {
                                return {
                                  id: i.id,
                                  attributes: i
                                }
                              }),
                              {
                                id: item.id,
                                attributes: item
                              }
                            ]
                          }
                        }
                      })
                    }}>
                      <AddIcon color="primary" />
                    </IconButton>
                  }
                </>
              } />
            ))}
          </Stack>
          : ''}
      </Stack>
      {items.length ?
        <Stack gap="12px" sx={{ opacity: (isLoading || isFetching) ? 0.5 : 1 }}>
          <Typography variant="subtitle2">Serviços Selecionados</Typography>
          <Stack divider={<Divider />} sx={{ boxShadow: '0 2px 4px rgba(0, 0, 0, .3)' }}>
            {items.map((item, index) => (
              <Item key={index} item={item} actions={
                <IconButton onClick={() => {
                  field.onChange({
                    target: {
                      value: {
                        data: items.filter(i => i.id !== item.id).map(i => {
                          return {
                            id: i.id,
                            attributes: i
                          }
                        })
                      }
                    }
                  })
                }}>
                  <DeleteIcon color="error" />
                </IconButton>
              } />
            ))}
          </Stack>
        </Stack>
        : ''}

      {isLoading || isFetching ? <CircularProgress size={20} /> : ''}
    </Stack>
  )
}

export default ServiceSelector
