import { Show, TextField, BooleanField, useRecordContext, TabbedShowLayout, Labeled, TopToolbar, ListButton } from 'react-admin'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import ClientRestrospective from './Retrospective'

const PageActions = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  )
}

const Aside = () => {
  const record = useRecordContext()
  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Typography variant="h6">Detalhes</Typography>
      {record && (
        <>
          <Typography variant="body2">
            createdAt: {record.createdAt}
          </Typography>
          <br />
          <Typography variant="body2">
            updatedAt: {record.updatedAt}
          </Typography>
        </>
      )}
    </div>
  )
}

const Page = () => {

  return (
    <Show aside={<Aside />} actions={<PageActions />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Cliente" sx={{ '.input-stack .MuiFormControl-root:first-of-type': { margin: 0 } }}>
          <Stack direction={'row'} spacing={2}>
            <Labeled>
              <TextField source="id" sx={{ width: '100px' }} />
            </Labeled>
            <Labeled>
              <TextField source="company_name" label="Razão social" />
            </Labeled>
            <Labeled>
              <TextField source="trade_name" label="Nome fantasia" />
            </Labeled>
            <Labeled>
              <TextField source="cpf" />
            </Labeled>
            <Labeled>
              <TextField source="cnpj" />
            </Labeled>
            <Labeled>
              <BooleanField source="canPayLater" label="Faturado" />
            </Labeled>
          </Stack>

          <Stack className="input-stack" direction={'row'} spacing={2}>
            <Labeled>
              <TextField source="inscrEstadual" />
            </Labeled>
            <Labeled>
              <TextField source="inscrMunicipal" />
            </Labeled>
            <Labeled>
              <TextField source="cadastur" />
            </Labeled>
            <Labeled>
              <TextField source="accounting_code" />
            </Labeled>
            <Labeled>
              <TextField source="cgClientId" label="Cangooroo ClientId" />
            </Labeled>
          </Stack>

          <Stack className="input-stack" direction={'row'} spacing={2}>
            <Labeled>
              <TextField source="email" label="E-mail principal" />
            </Labeled>
            <Labeled>
              <TextField source="phone1" label="Telefone 1" />
            </Labeled>
            <Labeled>
              <TextField source="phone2" label="Telefone 2" />
            </Labeled>
            <Labeled>
              <TextField source="zipCode" label="CEP" />
            </Labeled>
            <Labeled>
              <TextField source="address" label="Endereço" />
            </Labeled>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Retrospectiva">
          <ClientRestrospective />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default Page
