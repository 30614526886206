import {
  useAuthProvider,
  UserMenu,
  // MenuItemLink,
  Logout
} from "react-admin"
// import SettingsIcon from "@mui/icons-material/Settings"
import Box from "@mui/material/Box"

const MyUserMenu = (props) => {
  const authProvider = useAuthProvider()
  const user = authProvider.getUser()

  return (
    <UserMenu {...props}>
      {/* <MenuItemLink
        to="/my-profile"
        primaryText="Meu perfil"
        leftIcon={<SettingsIcon />}
      /> */}
      <Box sx={{ padding: '8px 16px 16px' }}>
        {user?.username}
      </Box>
      <Logout />
    </UserMenu>
  )
}

export default MyUserMenu
