import Icon from '@mui/icons-material/Article'
import { useParams } from 'react-router-dom'
import { Authenticated, Edit, SimpleForm, TextInput, NumberInput } from 'react-admin'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

const TransferAdditItemEdit = () => {
  const { id } = useParams()

  return (
    <Authenticated>
      <h1 style={{ marginBottom: '12px', marginTop: '1em', display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Icon sx={{ width: '32px', height: '32px' }} />
        Editar Contrato #{id}
      </h1>
      <Edit sx={{ width: '100%' }}>
        <SimpleForm>
          <Box sx={{ width: '1000px', maxWidth: '100%' }}>
            <Stack direction="row" spacing={2} alignItems={'center'} width="100%">
              <TextInput source="name" label="Nome" fullWidth required sx={{ margin: 0 }} />
              <TextInput source="code" label="Código" fullWidth sx={{ margin: 0 }} />
            </Stack>
            <Stack direction="row" spacing={2} alignItems={'center'} width="100%">
              <NumberInput source="paxWaitingTime" fullWidth />
              <NumberInput source="cancellationTimeMin" fullWidth />
            </Stack>
            <Stack direction="row" spacing={2} alignItems={'center'} width="100%">
              <NumberInput source="bookingTimeMin" fullWidth />
              <NumberInput source="bookingTimeMax" fullWidth />
            </Stack>
          </Box>
        </SimpleForm>
      </Edit>
    </Authenticated>
  )
}

export default TransferAdditItemEdit
