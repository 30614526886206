import { List, Datagrid, TextField, NumberField, EditButton } from 'react-admin'
import SearchForm from './SearchForm'

const filters = [
  <SearchForm alwaysOn />
]

const CustomList = () => (
  <List filters={filters}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <EditButton />
      <TextField source="trade_name" label="Razão social" />
      <TextField source="company_name" label="Razão social" />
      <TextField source="phone1" label="Telefone" />
      <TextField source="cnpj" label="CNPJ" />
      <NumberField source="rating" label="Rating" />
    </Datagrid>
  </List>
)

export default CustomList