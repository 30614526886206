// https://tintef.github.io/react-google-places-autocomplete/docs/props
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'

const PlacesAutocomplete = ({ placeholder, value, ...props }) => {

  const onChange = (value) => {
    if (props.onChange) {
      geocodeByPlaceId(value.value.place_id)
        .then(results => {
          const newValue = {
            ...value,
            value: {
              ...value.value,
              ...results[0]
            }
          }

          props.onChange(newValue)
        })
        .catch(error => console.error(error))
    }
  }

  return (
    <GooglePlacesAutocomplete
      apiKey="AIzaSyDEcgaxFe-BltDl8ffRBjwgd_tMEo_qd2E"
      apiOptions={{ language: 'pt', region: 'br' }}
      autocompletionRequest={{
        // types: ['establishment'] // https://developers.google.com/maps/documentation/javascript/supported_types?hl=pt-br
      }}
      selectProps={{
        placeholder,
        value: value,
        onChange: onChange,
        styles: {
          container: (baseStyles) => ({
            ...baseStyles,
            width: '100%',
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            padding: '8px',
            borderRadius: '10px'
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 3
          })
        }
      }}
    />
  )
}

export default PlacesAutocomplete