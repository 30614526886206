import { useState, useEffect } from 'react'
import { useRecordContext } from 'react-admin'

const ItemPaymentStatus = ({ status: statusFromProps, ...props }) => {
  const record = useRecordContext()
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (statusFromProps) {
      if (statusFromProps === 'Payed') {
        setStatus('Pago')
      } else {
        setStatus('Não')
      }
    } else {
      if (record.client && record.client.data && record.client.data.attributes && record.client.data.attributes.canPayLater) {
        setStatus('Fat.')
      } else {
        if (record && record.paymentStatus && record.paymentStatus === 'Payed') {
          setStatus('Pago')
        } else {
          setStatus('Não')
        }
      }
    }
  }, [statusFromProps, record])

  if (!record) return null

  return (
    <div style={{ color: status === 'Pago' || status === 'Fat.' ? 'green' : 'red', fontWeight: 'bold', fontSize: '13px' }}>
      {status}
    </div>
  )
}

export default ItemPaymentStatus
