export const serviceMap = {
  'transfers': 'Traslados privativos',
  'tours': 'Passeios privativos',
  'hotels': 'Hospedagem',
  'car_rentals': 'Aluguel de veículos',
  'special_vehicle_rentals': 'Aluguel de veículos especiais',
  'other': 'Outros serviços'
}

export const paymentConditionMap = {
  'pix': 'Tarifas válidas para pagamento por PIX',
  'pre_pix': 'Tarifas válidas para pré pagamento por PIX',
  'pre_pix_or_cc': 'Tarifas válidas para pré pagamento por PIX (5% de desconto) ou Cartão de crédito em até 3x sem juros'
}

export const getContent = (record) => {
  return `
ORÇAMENTO: #${record.id}

AGÊNCIA: ${record.client.data.attributes.company_name}

COMISSÃO: ${record.commission}%

PAGAMENTO: ${paymentConditionMap[record.paymentConditions] || record.paymentConditions}

ORÇAMENTO VÁLIDO E GARANTIDO PARA CONFIRMAÇÃO ATÉ: ${new Date(record.expiry).toLocaleDateString()}
APÓS ESTA DATA, ESTARÁ SUJEITO A DISPONIBILIDADE E ALTERAÇÃO DE VALORES.
${record.services.map((service) => {
  return `
-----------------------------------------

${serviceMap[service.name].toUpperCase()}:

${service.description}
`
})}

AGRADCEMOS POR SUA SOLICITAÇÃO E PERMANECEMOS À DISPOSIÇÃO.
`
}
