import { useState } from 'react'
import { useUpdate, useRecordContext } from 'react-admin'
import { DateInput, Form, Button } from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'

const ItemSupplierPaymentDate = ({ canEdit = true }) => {
  const record = useRecordContext()
  const [update, { isLoading }] = useUpdate()
  const [shouldSave, setShouldSave] = useState(false)

  const handleInputChange = (e) => {
    setShouldSave(record.supplierPaymentDate !== e.target.value)
  }

  const handleFormSubmit = async (data) => {
    const value = data.supplierPaymentDate

    update(
      'services',
      { id: data.id, data: { supplierPaymentDate: value }, previousData: record },
      {
        onSuccess: () => {
          setShouldSave(false)
        }
      }
    )
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <div style={{ position: 'relative', maxWidth: '120px' }}>
        <DateInput source="supplierPaymentDate" label="" readOnly={!canEdit} hiddenLabel onChange={handleInputChange} sx={{
          width: '100%',
          '& .MuiInputBase-root': {
            borderRadius: 0,
            margin: '0 0 -20px',
            'fieldset': {
              display: 'none'
            }
          },
          '& .MuiInputBase-input': {
            padding: '2px 4px',
            fontSize: '0.875rem',
            border: '1px solid #bbb',
            borderRadius: '4px',
            '&::-webkit-calendar-picker-indicator': {
              background: 'none',
              display: 'none'
            }
          },
        }} />
        {shouldSave &&
          <Button disableElevation variant="contained" type="submit" sx={{
            padding: 0,
            minWidth: '32px',
            textAlign: 'center',
            fontSize: '12px',
            fontWeight: '600',
            borderRadius: '0 4px 4px 0',
            position: 'absolute',
            top: '8px',
            height: '22px',
            right: '-32px',
            'span': {
              margin: 0
            }}}>
            {isLoading ? <CircularProgress size={'18px'} sx={{ color: 'white' }} /> : <>OK</>}
          </Button>
        }
      </div>
    </Form>
  )
}

export default ItemSupplierPaymentDate