import Stack from '@mui/material/Stack'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import './styles.css'
// import styled from 'styled-components'

// const StyledStack = styled(Stack)`
//   .additional-item-value-input {
//     border: 0;
//     outline: 0;
//     width: 36px;
//     color: #333;
//     -moz-appearance: textfield;
//     font-size: '14px';
//   }

//   .additional-item-value-input::-webkit-outer-spin-button,
//   .additional-item-value-input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }
// `

const AdditionalServiceItem = ({ service, currency, ...props }) => {
  const handleItemChange = (e) => {
    const serviceAlt = { ...service }

    if (e.target.name === 'checked') {
      serviceAlt[e.target.name] = e.target.checked
    }

    if (e.target.name === 'priceUSD') {
      serviceAlt[e.target.name] = Number(e.target.value)
    }

    if (props.onChange) {
      props.onChange({ index: props.index, service: serviceAlt })
    }
  }

  return (
    <Stack spacing={1} alignItems="center" direction="row">
      <FormControlLabel control={<Checkbox checked={service.checked} inputProps={{ name: 'checked' }} onChange={handleItemChange} />} label={service.item.namePT} />
      <Stack
        spacing={0.5}
        alignItems="center"
        direction="row"
        padding="4px 8px"
        fontSize="13px"
        border="1px solid #ccc"
        borderRadius="4px">
        <span style={{ fontWeight: '500' }}>{currency}</span>
        <input value={service.priceUSD || ''}
          name="priceUSD"
          onChange={handleItemChange}
          className='additional-item-value-input'
          type="number"
          step={0.1}
          placeholder='0.0' />
      </Stack>
    </Stack>
  )
}

export default AdditionalServiceItem
