import { useEffect } from 'react'
import { lightTheme } from './layout/themes'
import MyLayout from './MyLayout'
import { fetchUtils, Admin, Resource, CustomRoutes } from 'react-admin'
import simpleRestProvider from './ra-strapi-v4-rest'
import authProvider from './authProvider'
import Cookies from './helpers/Cookies'
import { Route } from "react-router"
import './App.css'

import bookings from './bookings'
import services from './services'
import bookingFlow from './booking-flow'
import serviceRequests from './service-requests'
import quotations from './quotations'
import supplierPayments from './supplier-payments'
import accountingTransactionCategories from './accounting-transaction-categories'
import accountingTransactionParties from './accounting-transaction-parties'
import accountsPayable from './accounts-payable'
import accountsReceivable from './accounts-receivable'
import transactions from './transactions'
import clients from './clients'
import suppliers from './suppliers'
import costCenters from './cost-centers'
import productCategories from './product-categories'
import clientCategories from './client-categories'
import chartOfAccounts from './chart-of-accounts'
import operatingIncome from './operating-income'

import transferRoutes from './transfer-routes'
import transferVehicles from './transfer-vehicles'
import transferAdditionalServices from './transfer-additional-services'
import transferContracts from './transfer-contracts'
import importedItems from './imported-items'

// custom routes
import { ProfileEdit } from './profile'
import CreateCieloLinkForm from './cielo/create-link/form'
// import mediaLibrary from './media-library'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = Cookies.getCookie('token')
  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}

const dataProvider = simpleRestProvider(process.env.REACT_APP_DATA_PROVIDER, httpClient)

const initUpcomingServicesChecker = () => {
  const checkUpcomingServices = async () => {
    try {
      const permission = await authProvider.getPermissions()
      if (permission !== 'ADMIN') return
    } catch (e) {
      return
    }

    const alertItems = []
    const timeRanges = [
      10,
      60
    ]

    for (const timeRange of timeRanges) {
      const currentDate = new Date()
      const startDate = new Date(currentDate.getTime() + timeRange * 60000)
      const isoStartDate = startDate.toISOString()

      // isoStartDate with time with hours and minutes only
      const isoStartDateWithHoursAndMinutes = isoStartDate.split('T')[0] + 'T' + isoStartDate.split('T')[1].split(':').slice(0, 2).join(':') + ':00'
      const isoEndDate = isoStartDateWithHoursAndMinutes.split('T')[0] + 'T' + isoStartDateWithHoursAndMinutes.split('T')[1].split(':').slice(0, 2).join(':') + ':59'

      const checkRes = await dataProvider('GET_LIST', 'services', {
        meta: {
          'filters[utcServiceDate][$gte]': isoStartDateWithHoursAndMinutes,
          'filters[utcServiceDate][$lte]': isoEndDate,
          'filters[cgStatus][$eq]': 'Confirmed',
          'filters[operationalStatus][$not]': 'done',
        },
        pagination: { page: 1, perPage: 5 },
        sort: { field: 'updatedAt', order: 'DESC' }
      })

      if (checkRes && checkRes.data) {
        alertItems.push(...checkRes.data.map(item => `Serviço ${item.cgBookingNumber}-${item.cgServiceId} - inicia em ${timeRange}min`))
      }
    }

    // test if browser supports notifications
    if ("Notification" in window) {
      if (alertItems.length) {
        const notification = new Notification('Alerta de Reservas',
          {
            body: alertItems.join('\n'),// ` Serviço xxxx - inicia em 10min\nServiço xxxx - inicia em 1h`,
            icon: '/logo512.png',
            requireInteraction: true
          })

        notification.onclick = () => {
          notification.close()
          window.parent.focus()
        }
      }
    }
  }

  if ("Notification" in window) {
    checkUpcomingServices()

    setInterval(() => {
      checkUpcomingServices()
    }, 60000)
  }
}

const App = () => {
  useEffect(() => {
    if ("Notification" in window) {
      let permission = Notification.permission

      if (permission === "granted") {
        initUpcomingServicesChecker()
      } else if (permission === "default") {
        Notification.requestPermission((permission) => {
          if (permission === "granted") {
            initUpcomingServicesChecker()
          }
        })
      } else {
        alert("Permita as notificações para receber alertas.")
      }
    }
  }, [])

  return (
    <Admin
      title="Backoffice"
      layout={MyLayout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={lightTheme}
      requireAuth>

      {permissions => [
        permissions === 'ADMIN' ?
          <>
            <Resource name="services" {...services} />
            <Resource name="bookings" {...bookings} />
            <Resource name="booking-flow" {...bookingFlow} />
            <Resource name="service-requests" {...serviceRequests} />
            <Resource name="imported-items" {...importedItems} />
            <Resource name="quotations" {...quotations} />
            <Resource name="supplier-payments" {...supplierPayments} />
            <Resource name="clients" {...clients} />
            <Resource name="suppliers" {...suppliers} />
            <Resource name="client-categories" {...clientCategories} />
            <Resource name="product-categories" {...productCategories} />
            {/* Financial */}
            <Resource name="accounting-transaction-categories" {...accountingTransactionCategories} />
            <Resource name="accounting-transaction-parties" {...accountingTransactionParties} />
            <Resource name="accounts-payable" {...accountsPayable} />
            <Resource name="accounts-receivable" {...accountsReceivable} />
            <Resource name="accounting-transactions" {...transactions} />
            <Resource name="cost-centers" {...costCenters} />
            <Resource name="chart-of-accounts" {...chartOfAccounts} />
            <Resource name="operating-income" {...operatingIncome} />
            {/* Transfers */}
            <Resource name="transfer-routes" {...transferRoutes} />
            <Resource name="transfer-vehicles" {...transferVehicles} />
            <Resource name="transfer-additional-services" {...transferAdditionalServices} />
            <Resource name="transfer-contracts" {...transferContracts} />
            {/* <Resource name="upload/files" {...mediaLibrary} /> */}
          </>
          : '',

        permissions === 'MANAGER' ?
          <>
            <Resource name="services" {...services} />
            <Resource name="booking-flow" {...bookingFlow} />
            <Resource name="imported-items" {...importedItems} />
            <Resource name="quotations" {...quotations} />
            <Resource name="supplier-payments" {...supplierPayments} />
            <Resource name="clients" {...clients} />
            <Resource name="suppliers" {...suppliers} />
            <Resource name="client-categories" {...clientCategories} />
            <Resource name="product-categories" {...productCategories} />
            {/* Transfers */}
            <Resource name="transfer-routes" {...transferRoutes} />
            <Resource name="transfer-vehicles" {...transferVehicles} />
            <Resource name="transfer-additional-services" {...transferAdditionalServices} />
            <Resource name="transfer-contracts" {...transferContracts} />
            {/* <Resource name="upload/files" {...mediaLibrary} /> */}
          </>
          : '',

        permissions === 'CONSULTANT' ?
          <>
            <Resource name="services" {...services} />
            {/* <Resource name="imported-items" {...importedItems} /> */}
            <Resource name="booking-flow" {...bookingFlow} />
            <Resource name="quotations" {...quotations} />
            {/* <Resource name="supplier-payments" {...supplierPayments} />
            <Resource name="clients" {...clients} />
            <Resource name="suppliers" {...suppliers} />
            <Resource name="client-categories" {...clientCategories} />
            <Resource name="product-categories" {...productCategories} /> */}
            {/* <Resource name="upload/files" {...mediaLibrary} /> */}
          </>
          : '',

        permissions === 'AFFILIATE' ?
          <>
            <Resource
              name="services"
              list={services.affiliateList}
              options={{ label: 'Reservas' }}
              edit={null}
              icon={services.icon} />
          </>
          : '',

        permissions === 'MARKETING' ?
          <>
            <Resource name="clients"
              icon={clients.icon}
              options={clients.options}
              list={clients.list}
              show={clients.show} />
          </>
          : '',
      ]}

      {/* <Resource name="bank-accounts" {...bankAccounts} /> */}

      <CustomRoutes>
        <Route path="/my-profile" element={<ProfileEdit />} />
        <Route path="/create-cielo-link" element={<CreateCieloLinkForm />} />
      </CustomRoutes>
    </Admin>
  )
}

export default App