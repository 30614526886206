import { defaultTheme } from 'react-admin'

export const darkTheme = {
  palette: {
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#FBBA72',
    },
    mode: 'dark', // Switching the dark mode on is a single property value change.
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #000',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #90caf9',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#ffffffb3',
          backgroundColor: '#616161e6',
        },
      },
    },
  },
}

export const lightTheme = {
  palette: {
    primary: {
      main: '#0f6b9b',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfe',
    },
    mode: 'light',
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 200,
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #fff',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #3c81c9',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
          color: '#18202e !important'
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 8px 0px #00000029',
          border: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#808080',
          backgroundColor: '#fff',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          '& .MuiButton-startIcon': {
            margin: 0
          }
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&[data-testid="selectArray"]': {
            border: '1px solid #ccc',
            borderRadius: '8px',
            height: '55px',
            background: 'white',
            '&::before': {
              display: 'none'
            },
            '&.Mui-focused': {
              background: 'white',

              '&::after': {
                display: 'none'
              }
            },
            '.MuiSelect-select': {
              '&:focus': {
                background: 'white',
              }
            }
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '.MuiFormLabel-root': {
            lineHeight: '2.4em',
            '&.MuiFormLabel-filled': {
              lineHeight: '1.8em',
            },
            '&.Mui-focused': {
              lineHeight: '1.8em',
            }
          },
          '.MuiInputBase-root': {
            '.MuiSelect-select': {
              padding: '16px 20px'
            },
          },
          '.MuiInputBase-input': {
            padding: '16px 20px',
          }
        }
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiInputBase-input': {
            padding: '10px 20px !important',
          }
        }
      }
    }
  }
}
