import {
  Edit,
  TabbedForm,
  FunctionField,
  TextInput,
  DateInput,
  DateTimeInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  required,
  useEditContext,
  useRefresh,
  useNotify
} from 'react-admin'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import Divider from '@mui/material/Divider'
import StatusButton from '../../components/StatusButton'
import { statusOptions, paymentStatusOptions } from '../utils'
import countries from '../../utils/countries'
import ItemsSummary from './ItemsSummary'
import ServiceSelector from './ServiceSelector'
import TransferItems from './TransferItems'

const Aside = () => {
  const { isLoading } = useEditContext()

  if (isLoading) return null

  return (
    <Box sx={{ width: '400px', margin: '0 1.5em' }}>
      <ItemsSummary />
    </Box>
  )
}

export const CustomToolbar = () => {
  return (
    <Toolbar>
      <SaveButton label="Salvar" />
    </Toolbar>
  )
}

const itemTypesMap = {
  'package': 'Pacote',
  'transfer': 'Transfer'
}

const EditPage = () => {
  const notify = useNotify()
  const refresh = useRefresh()
  const transform = data => ({
    ...data,
    services: data.services?.data?.map(service => service.id) || []
  })

  return (
    <Edit
      aside={<Aside />}
      transform={transform}
      redirect={false}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          notify(`Booking updated successfully`, {
            type: 'success'
          })

          refresh()
        }
      }}>
      <Box padding="1em 1em 0.5em">
        <FunctionField sx={{ marginBottom: '20px' }} render={record => {
          const itemTypes = record?.items?.map(item => item.type) || []
          return (
            <>
              <h2 style={{ margin: '0' }}>
                Reserva {record?.bookingRef} |{' '}
                {itemTypes.map((itemType) => (itemTypesMap[itemType] || itemType)).filter((itemType, index, self) => self.indexOf(itemType) === index).join(' + ')}
              </h2>
              <Typography variant="subtitle1">{record?.places?.data?.map(place => place.attributes.namePT).join(' | ')}</Typography>
            </>
          )
        }} />
      </Box>
      <TabbedForm
        toolbar={<CustomToolbar />}
        sx={{
          paddingBottom: '3em',
          overflowX: 'auto',
          '.MuiFormLabel-root': {
            color: '#333'
          },
        }}>
        <TabbedForm.Tab label="Reserva">
          <Stack gap={3} width="100%" divider={<Divider />}>
            <Stack direction="row" gap={3} justifyContent="space-between" alignItems="center">
              <Stack direction="row" gap={3}>
                <Box>
                  <InputLabel>Status Reserva</InputLabel>
                  <StatusButton resource="bookings" source="status" options={statusOptions} variant="text" />
                </Box>
                <Box>
                  <InputLabel>Status Pagamento</InputLabel>
                  <StatusButton resource="bookings" source="paymentStatus" options={paymentStatusOptions} variant="text" readOnly />
                </Box>
              </Stack>
              <Stack direction="row" gap={3}>
                <DateTimeInput source="startDate" label="Data Início" sx={{ '.MuiFormHelperText-root': { display: 'none' } }} />
                <DateTimeInput source="endDate" label="Data Fim" sx={{ '.MuiFormHelperText-root': { display: 'none' } }} />
              </Stack>
            </Stack>
            <Stack>
              <InputLabel>Titular da compra</InputLabel>
              <Stack direction="row" gap={3}>
                <TextInput source="bookerInfo.name" label="Nome" validate={required()} />
                <TextInput source="bookerInfo.surname" label="Sobrenome" validate={required()} />
                <DateInput source="bookerInfo.birthdate" label="Data de Nascimento" />
                <TextInput source="bookerInfo.doc" label="CPF" />
              </Stack>
            </Stack>
            <Stack>
              <InputLabel>Contato</InputLabel>
              <Stack direction="row" gap={3}>
                <TextInput source="contact.email" label="E-mail" validate={required()} />
                <TextInput source="contact.phone" label="Telefone" validate={required()} />
                <TextInput source="contact.phoneDDD" label="DDD" sx={{ width: '92px' }} />
                <TextInput source="contact.phoneDDI" label="Código País" sx={{ width: '92px' }} />
                <BooleanInput source="contact.mailAllowed" label="Aceita e-mails" sx={{ paddingTop: '1em' }} />
                <BooleanInput source="contact.whatsappAllowed" label="WhatsApp Permitido" sx={{ paddingTop: '1em' }} />
              </Stack>
            </Stack>
            <TransferItems />
            <Stack>
              <Stack direction="row" gap={3}>
                <TextInput source="observation" multiline rows={5} label="Observação" sx={{ width: '100%', '.MuiInputBase-input': { color: 'red' } }} />
                <TextInput source="voucherObservation" multiline rows={5} label="Observação Voucher" sx={{ width: '100%' }} />
              </Stack>
            </Stack>
          </Stack>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Passageiros">
          <TransferItems showPrice={false} paxDetails={true} />
          <Box maxWidth="1080px" sx={{ '.RaSimpleFormIterator-line:hover': { backgroundColor: '#fafafa' } }}>
            <ArrayInput source="pax" label="Passageiros">
              <SimpleFormIterator inline>
                <TextInput source="name" label="Nome" validate={required()} />
                <TextInput source="surname" label="Sobrenome" validate={required()} />
                <TextInput source="cpf" label="CPF" />
                <TextInput source="phone" label="Telefone" />
                <TextInput source="email" label="E-mail" />
                <DateInput source="birthday" label="Data Nascimento" />
                <SelectInput source="countryCode" label="País" choices={countries.map(country => {
                  return { id: country.code, name: country.name }
                })} />
                <BooleanInput source="whatsappAllowed" label="WhatsApp Permitido" />
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Serviços">
          <Stack minHeight="480px">
            <ServiceSelector
              label="Buscar Serviço (xxxx-xxxx)"
              helperText="bookingNumber-serviceId" />
          </Stack>
        </TabbedForm.Tab>
      </TabbedForm>
    </Edit>
  )
}

export default EditPage
