import { BooleanField, EmailField, Show, SimpleShowLayout, TextField, WrapperField, ArrayField, Datagrid, FunctionField, useRecordContext } from 'react-admin'
import StatusButton from '../../components/StatusButton'
import { statusOptions } from '../utils'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const Aside = () => {
  const record = useRecordContext()
  return (
    <Box sx={{ width: '360px', margin: '1em' }}>
      <StatusButton resource="service-requests" source="status" options={statusOptions} variant="text" />
      <Box sx={{ marginTop: '1em' }}>
        <Typography variant="h6">Detalhes</Typography>
        {record && (
          <>
            <Typography variant="body2">
              Criado em: {new Date(record.createdAt).toLocaleString()}
            </Typography>
            <br />
            <Typography variant="body2">
              Atualizado em: {new Date(record.updatedAt).toLocaleString()}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  )
}

export const CustomShow = () => (
  <Show aside={<Aside />}>
    <SimpleShowLayout>
      <WrapperField label="Solicitante">
        <TextField source="requestor.name" />{' '}
        <TextField source="requestor.surname" />
      </WrapperField>
      <EmailField source="contact.email" label="E-mail" />
      <TextField source="contact.phone" label="Telefone" />
      <BooleanField source="contact.mailAllowed" label="E-mail permitido" />
      <BooleanField source="contact.whatsappAllowed" label="WhatsApp" />
      <ArrayField source="customFields">
        <Datagrid bulkActionButtons={false}>
          <FunctionField label="Key" render={record => {
            if (record.key === 'type') {
              return 'Tipo'
            }

            if (record.key === 'originName') {
              return 'Origem'
            }

            if (record.key === 'destinationName') {
              return 'Destino'
            }

            return record.key
          }} />
          <TextField source="value" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)

export default CustomShow
