import { useState, useEffect } from 'react'
import { useListContext } from 'react-admin'
import IconButton from "@mui/material/IconButton"
import SearchIcon from "@mui/icons-material/Search"
import TextField from "@mui/material/TextField"
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import Box from '@mui/material/Box'

const SearchForm = () => {
  const {
    filterValues,
    setFilters
  } = useListContext()
  const [query, setQuery] = useState('')

  const handleSubmit = () => {
    setFilters({
      ...filterValues,
      query
    })
  }

  const handleClear = () => {
    setQuery('')
    setFilters({
      ...filterValues,
      query: ''
    })
  }

  useEffect(() => {
    if (filterValues && filterValues.query) {
      setQuery(filterValues.query)
    }
  }, [filterValues])

  return (
    <Box>
      <TextField
        id="search-bar"
        className="text"
        onInput={(e) => {
          setQuery(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              {query.length > 0 ?
                <IconButton onClick={handleClear}>
                  <ClearOutlinedIcon />
                </IconButton>
                : ''}
              <IconButton type="button" onClick={handleSubmit} aria-label="search">
                <SearchIcon style={{ fill: "#0f6b9b" }} />
              </IconButton>
            </Box>
          )
        }}
        value={query}
        label="Buscar..."
        variant="outlined"
        size="small"
      />
    </Box>
  )
}

export default SearchForm
