import Icon from '@mui/icons-material/CardTravel'
import List from './List'
import AffiliateList from './AffiliateList'
import Edit from './Edit'

const props = {
  icon: Icon,
  options: { label: 'Serviços' },
  list: List,
  affiliateList: AffiliateList,
  edit: Edit
}

export default props
