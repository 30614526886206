import Icon from '@mui/icons-material/CardTravel'
import List from './List'
import Edit from './Edit'
import Create from './Create'

const props = {
  icon: Icon,
  options: { label: 'Orçamentos' },
  list: List,
  edit: Edit,
  create: Create
}

export default props
