import Icon from '@mui/icons-material/AccountTree'
import List from './List'
import Edit from './Edit'
import Create from './Create'

const props = {
  icon: Icon,
  options: { label: 'Plano de contas'},
  list: List,
  edit: Edit,
  create: Create
}

export default props
