import { useState, useEffect } from 'react'
import { useRecordContext, useUpdate } from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

const options = {
  'notPaid': {
    label: 'Não pago',
    color: '#ef3133'
  },
  'paid': {
    label: 'Pago',
    color: '#4ec21d'
  }
}

const ItemSupplierPaymentStatus = () => {
  const [update, { isLoading }] = useUpdate()
  const record = useRecordContext()
  const [status, setStatus] = useState('notPaid')

  useEffect(() => {
    if (record && record.supplierPaymentStatus) {
      setStatus(record.supplierPaymentStatus)
    }
  }, [record])

  const handleChange = (event) => {
    setStatus(event.target.value)

    update(
      'services',
      { id: record.id, data: { supplierPaymentStatus: event.target.value }, previousData: record }
    )
  }

  if (!status) {
    return (
      <Box sx={{
        width: '24px',
        height: '24px',
        borderRadius: '6px',
        background: 'linear-gradient(90deg, #ddd, #ccc)'
      }}></Box>
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '12px' }}>
      <Select
        value={status}
        onChange={handleChange}
        title={options[status] ? options[status].label : null}
        sx={{
          height: 24,
          width: 24,
          textIndent: '-999px',
          color: 'white',
          borderRadius: '6px',
          fontSize: '12px',
          fontWeight: '600',
          bgcolor: options[status] ? options[status].color : null,
          '.color-box': {
            display: 'none'
          },
          'svg': {
            display: 'none'
          }
        }}>
        {Object.keys(options).map((item, index) =>
          <MenuItem key={index} value={item}>
            <Box className='color-box' sx={{ backgroundColor: options[item] ? options[item].color : null, width: '16px', height: '16px', borderRadius: '4px', marginRight: '8px' }}></Box>
            {options[item].label}
          </MenuItem>
        )}
      </Select>
      {isLoading ? <CircularProgress size={16} /> : ''}
    </div>
  )
}

export default ItemSupplierPaymentStatus
