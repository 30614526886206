import Icon from '@mui/icons-material/AssignmentInd'
import List from './List'
import Edit from './Edit'
import Create from './Create'
import Show from './Show'

const props = {
  icon: Icon,
  options: { label: 'Clientes'},
  list: List,
  edit: Edit,
  create: Create,
  show: Show
}

export default props
