import EditForm from "./EditForm"

const Create = (props) => {
  return (
    <>
      <EditForm />
    </>
  )
}

export default Create
