import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import ItemBookingNumber from '../../../services/List/ItemBookingNumber'
import { getPaxNumber, cgStatusOptions } from '../../../services/utils'
import Tooltip from '@mui/material/Tooltip'
import ItemStartLocation from '../../../services/List/ItemStartLocation'
import ItemEndLocation from '../../../services/List/ItemEndLocation'

import HotelIcon from '@mui/icons-material/Hotel'
import CarRentalIcon from '@mui/icons-material/CarRental'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import FlightIcon from '@mui/icons-material/Flight'
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi'

const serviceIconMap = {
  'Hotel': <HotelIcon color="primary" />,
  'Transfer': <LocalTaxiIcon color="primary" />,
  'Tour': <LocalActivityIcon color="primary" />,
  'Car': <CarRentalIcon color="primary" />,
  'Insurances': <HealthAndSafetyIcon color="primary" />,
  'Flight': <FlightIcon color="primary" />,
}

const StatusBadge = ({ options, value }) => {
  return (
    <Box sx={{
      display: 'inline-block',
      padding: '4px 6px',
      borderRadius: '4px',
      backgroundColor: options[value]?.color,
      color: 'white',
      fontSize: '12px',
      fontWeight: 'bold',
    }}>
      {options[value]?.longLabel || options[value]?.label}
    </Box>
  )
}

const Item = ({ item, actions }) => {
  const record = item

  return (
    <Stack gap={1} sx={{ padding: '1em', fontSize: '14px' }}>
      <Stack direction="row" gap={2} alignItems="center">
        <Box>{serviceIconMap[record.serviceType]}</Box>
        <Box sx={{ minWidth: '120px', textTransform: 'uppercase' }}>
          <Tooltip title={
            <Stack gap={1}>
              {record.pax?.map((pax, index) => (
                <Box key={index}>{pax.name} {pax.surname}</Box>
              ))}
            </Stack>
          }>
            <span>{record.serviceType} {getPaxNumber(record)} PAX</span>
          </Tooltip>
          </Box>
        <Box sx={{ minWidth: '130px' }}>
          <ItemBookingNumber record={record} />
        </Box>
        <Box sx={{ minWidth: '130px', textAlign: 'center' }}>
          <StatusBadge options={cgStatusOptions} value={record.cgStatus}/>
        </Box>
        <Box sx={{ fontWeight: 'bold', minWidth: '140px' }}>
          {new Date(record.serviceDate).toLocaleDateString('pt-br', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
        </Box>
        <Box sx={{ minWidth: '232px' }}>
          <ItemStartLocation record={record} />
        </Box>
        <Box sx={{ minWidth: '232px' }}>
          <ItemEndLocation record={record} />
        </Box>
        {actions}
      </Stack>
    </Stack>
  )
}

export default Item
