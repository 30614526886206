import { useRecordContext } from 'react-admin'
import Box from '@mui/material/Box'
import { fixPlaceName } from '../utils'

const ItemEndLocation = ({ ...props }) => {
  const recordFromContext = useRecordContext()
  const record = props.record || recordFromContext
  const typesWithoutDestination = ['Car', 'Insurances', 'Hotel', 'Tour']

  if (record && (!typesWithoutDestination.includes(record.serviceType) && (record.destinationName || record.destinationAddress))) {
    return (
      <Box sx={{
        display: 'flex',
        width: '232px',
        alignItems: 'center',
        gap: '8px',
        fontSize: '13px',
        lineHeight: '1.3em',
        textTransform: 'uppercase',
        '& svg': {
          width: '16px',
          height: '16px',
          fill: 'teal'
        }
      }}>
        {(() => {
          if (record.destinationAddressType && record.destinationAddressType === 'Accomodation') {
            const accomodationName = fixPlaceName(record.destinationLocation)
            return `Hotel: ${accomodationName} - ${record.destinationAddress}`
          }

          if (record.destinationName || record.destinationLocation || record.additInfoDestinationCity) {
            const destinationStr = fixPlaceName(record.destinationName || record.destinationLocation || record.additInfoDestinationCity)

            return `
                  ${destinationStr}
                  ${record.destinationAddress && record.destinationAddress !== destinationStr ? ` - ${record.destinationAddress}` : ''}
                  ${record.destinationAddressComplement ? ` - ${record.destinationAddressComplement}` : ''}
                  ${record.additDestinationNumberInfo && !destinationStr.includes(record.additDestinationNumberInfo) ?
                ` - ${record.additDestinationNumberInfo}` : ''}`
          }

          return '?'
        })()}
      </Box>
    )
  }

  return null
}

export default ItemEndLocation
