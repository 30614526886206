import { useEffect, useState } from 'react'
import { Form, useRecordContext, useUpdate, useRefresh, useNotify, TextInput } from 'react-admin'
import ManageSupplier from './ManageSupplier'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import PhoneIcon from '@mui/icons-material/Phone'
import AddIcCallIcon from '@mui/icons-material/AddIcCall'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EmailIcon from '@mui/icons-material/Email'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { getPaxNumber, getLuggageNumber } from '../utils'
import { getStartLocationString, getEndLocationString } from '../utils/place'
import CountryFlag from '../../components/CountryFlag'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

const translations = {
  en: {
    locale: 'en-GB',
    date: 'DATE',
    time: 'TIME',
    origin: 'ORIGIN',
    destination: 'DESTINATION',
    paxNumber: 'PASSENGERS',
    luggage: 'LUGGAGE',
    suitcases: 'Suitcases',
    carryOn: 'Carry-on'
  },
  pt: {
    locale: 'pt-BR',
    date: 'DATA',
    time: 'HORA',
    origin: 'ORIGEM',
    destination: 'DESTINO',
    paxNumber: 'PASSAGEIROS',
    luggage: 'BAGAGEM',
    suitcases: 'Malas grandes',
    carryOn: 'Malas pequenas'
  },
  es: {
    locale: 'es-ES',
    date: 'FECHA',
    time: 'HORA',
    origin: 'ORIGEN',
    destination: 'DESTINO',
    paxNumber: 'PASAJEROS',
    luggage: 'EQUIPAJE',
    suitcases: 'Maletas grandes',
    carryOn: 'Maletas pequeñas'
  }
}

const getSupplierConfirmationMessage = (service, lang = 'en') => {
  const t = translations[lang]
  const luggageNumber = getLuggageNumber(service)
  // const translateAddressType = (type) => {
  //   if (type === 'Accomodation') {
  //     return 'Hotel'
  //   }

  //   if (type === 'Other') {
  //     return 'Address'
  //   }

  //   return type
  // }

  if (service.supplier && service.supplier.data && service.supplier.data.attributes) {
    // ORIGIN: ${translateAddressType(service.originAddressType)}${service.originAddressComplement ? ` - ${service.originAddressComplement}` : ''}${service.additOriginNumberInfo ? ` - ${service.additOriginNumberInfo}` : ''}
    // DESTINATION: ${translateAddressType(service.destinationAddressType)}${service.destinationAddressComplement ? ` - ${service.destinationAddressComplement}` : ''}${service.additDestinationNumberInfo ? ` - ${service.additDestinationNumberInfo}` : ''}
    return `
${t.date}: ${new Date(service.serviceDate.split(' ')[0]).toLocaleDateString(t.locale, {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    })}

${t.time}: ${service.serviceDate.split(' ')[1]}

${t.origin}: ${getStartLocationString(service)}

${t.destination}: ${getEndLocationString(service)}

${t.paxNumber}: ${getPaxNumber(service)}

${t.luggage}: ${[
  luggageNumber.suitcase ? `${luggageNumber.suitcase} ${t.suitcases}`: null,
  luggageNumber.carryOn ? `${luggageNumber.carryOn} ${t.carryOn}`: null
].filter(Boolean).join(' + ')}

${`${service.pax[0]?.name} ${service.pax[0]?.surname}`.toUpperCase()}
`
  } else {
    return ''
  }
}

const ItemSupplier = ({ readOnly = false, supplierMessageAction = true, ...props }) => {
  const record = useRecordContext()
  const refresh = useRefresh()
  const [phoneModalOpen, setPhoneModalOpen] = useState(false)
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const notify = useNotify()
  const [update, { isLoading }] = useUpdate()
  const [service, setService] = useState()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [messageLanguage, setMessageLanguage] = useState('en')
  const [supplierMessage, setSupplierMessage] = useState('')
  const availableMsgLanguages = [
    { id: 'en', countryCode: 'GB' },
    { id: 'pt', countryCode: 'BR' },
    { id: 'es', countryCode: 'ES' }
  ]

  const handlePhoneModalOpen = () => setPhoneModalOpen(true)
  const handlePhoneModalClose = () => setPhoneModalOpen(false)

  useEffect(() => {
    setService(record)
  }, [record])

  useEffect(() => {
    if (service && messageLanguage) {
      setSupplierMessage(getSupplierConfirmationMessage(service, messageLanguage))
    }
  }, [service, messageLanguage])

  const handlePhoneFormSubmit = (data) => {
    if (data.supplier && data.supplier.data && data.supplier.data.id && data.supplierPhone) {
      update(
        'suppliers',
        {
          id: data.supplier.data.id,
          data: { phone1: data.supplierPhone }
        },
        {
          onSuccess: () => {
            setPhoneModalOpen(false)
            refresh()
          }
        }
      )
    }
  }

  return (
    <>
      {service ?
        <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <ManageSupplier
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            readOnly={readOnly}
          />
          <Button
            sx={{
              color: service.supplier && service.supplier.data ? '#333' : '#555',
              fontWeight: service.supplier && service.supplier.data ? 600 : 400,
              textTransform: 'none',
              border: '1px solid #aaa',
              padding: '3px 6px',
              width: '100%',
              fontSize: '12px',
              justifyContent: 'flex-start'
            }} onClick={() => setDialogOpen(true)}>
            {
              service.supplier && service.supplier.data
                ? service.supplier.data.attributes.trade_name || service.supplier.data.attributes.company_name
                : '+ add fornecedor'
            }
          </Button>
          {service.supplier && service.supplier.data && service.supplier.data.attributes ?
            <>
              <Button
                variant="contained"
                size="small"
                title={service.supplier.data.attributes.phone1 ? 'Copiar telefone' : 'Adicionar telefone'}
                sx={{ minWidth: 'auto', padding: '4px', borderRadius: '2px', backgroundColor: !service.supplier.data.attributes.phone1 ? 'gray' : null }}
                onClick={() => {
                  if (service.supplier.data.attributes.phone1) {
                    navigator.clipboard.writeText(service.supplier.data.attributes.phone1)
                    notify('Telefone copiado para área de transferência', { type: 'success' })
                  } else {
                    handlePhoneModalOpen()
                  }
                }}>
                {service.supplier.data.attributes.phone1 ?
                  <PhoneIcon sx={{ width: '12px', height: '12px' }} />
                  : <AddIcCallIcon sx={{ width: '12px', height: '12px' }} />}
              </Button>
              {supplierMessageAction && service.serviceType === 'Transfer' ?
                <Button
                  variant="contained"
                  size="small"
                  title="Gerar mensagem de confirmação"
                  color="success"
                  sx={{ minWidth: 'auto', padding: '4px', borderRadius: '2px' }}
                  onClick={() => {
                    setMessageModalOpen(true)
                  }}>
                  <EmailIcon sx={{ width: '12px', height: '12px' }} />
                </Button>
                : ''}
              {phoneModalOpen ?
                <Modal
                  open={phoneModalOpen}
                  onClose={handlePhoneModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      Telefone de {service.supplier.data.attributes.trade_name || service.supplier.data.attributes.company_name}
                    </Typography>
                    <Form onSubmit={handlePhoneFormSubmit}>
                      <Box sx={{
                        position: 'relative',
                        display: 'flex',
                        gap: '4px',
                        alignItems: 'center',
                        marginTop: '16px',
                        '& .MuiFormLabel-root': {
                          marginTop: '-9px',
                          '&.Mui-focused': {
                            margin: 0
                          },
                          '&.MuiFormLabel-filled': {
                            margin: 0
                          }
                        },
                        '& .MuiInputBase-root': {
                          borderRadius: '6px',
                          margin: '0 0 -20px'
                        },
                        '& .MuiInputBase-input': {
                          padding: '8px 10px !important',
                          fontSize: '0.875rem',
                          MozAppearance: 'textfield',
                          '&::-webkit-inner-spin-button': {
                            WebkitAppearance: 'none',
                            margin: 0
                          },
                          '&::-webkit-outer-spin-button': {
                            WebkitAppearance: 'none',
                            margin: 0
                          }
                        }
                      }}>
                        <TextInput source="supplierPhone" type="number" label="Telefone" />
                        <Button type="submit" variant="contained">
                          {isLoading ? <CircularProgress size={'18px'} sx={{ color: 'white' }} /> : <>Salvar</>}
                        </Button>
                      </Box>
                    </Form>
                  </Box>
                </Modal>
                : ''}
              {messageModalOpen ?
                <Modal
                  open={messageModalOpen}
                  onClose={() => { setMessageModalOpen(false) }}
                  aria-labelledby="message-modal-title"
                  aria-describedby="message-modal-description"
                >
                  <Box sx={modalStyle}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                      <ButtonGroup variant="outlined" aria-label="Basic button group">
                        {availableMsgLanguages.map((lang) => (
                          <Button
                            key={lang.id}
                            onClick={() => setMessageLanguage(lang.id)}
                            variant={messageLanguage === lang.id ? 'contained' : 'outlined'}
                          >
                            <CountryFlag countryCode={lang.countryCode} size={24} />
                          </Button>
                        ))}
                      </ButtonGroup>
                      <Button variant="outlined" size="small" sx={{ gap: '8px' }} startIcon={<ContentCopyIcon />}
                        onClick={() => {
                          navigator.clipboard.writeText(supplierMessage)
                          notify('Mensagem copiada para área de transferência', { type: 'success' })
                        }}>
                        Copiar
                      </Button>
                    </Box>
                    <textarea style={{ width: '100%', height: '400px' }}
                      value={supplierMessage}
                      onChange={(e) => setSupplierMessage(e.target.value)} />
                  </Box>
                </Modal>
                : ''}
            </>
            : ''}
        </Box>
        : ''}
    </>
  )
}

export default ItemSupplier
