import Icon from '@mui/icons-material/CardTravel'
import List from './List'
import Show from './Show'

const props = {
  icon: Icon,
  options: { label: 'Solicitações de Serviços' },
  list: List,
  show: Show
}

export default props
