import { useRecordContext, useCreate, useRedirect, useNotify } from 'react-admin'
import Button from '@mui/material/Button'
import UndoIcon from '@mui/icons-material/Undo';

const ItemReverseRouteButton = () => {
  const record = useRecordContext()
  const notify = useNotify()
  const [create] = useCreate()
  const redirect = useRedirect()

  const handleClick = async () => {
    const payload = { ...record }

    if (payload) {
      delete payload.id
      delete payload.placeFrom
      delete payload.placeTo

      // Invert placeFrom and placeTo
      payload.googlePlaceFrom = record.googlePlaceTo
      payload.googlePlaceTo = record.googlePlaceFrom
      payload.googlePlaceIdFrom = record.googlePlaceIdTo
      payload.googlePlaceIdTo = record.googlePlaceIdFrom
      payload.options = record.options.map(option => {
        return {
          ...option,
          vehicle: option.vehicle?.data?.id
        }
      })
    }

    create(
      'transfer-routes',
      { data: payload },
      {
        onSuccess: (newItem) => {
          notify(`Rota criada com sucesso! #${newItem.id}`, { type: 'success' })
          redirect(`/transfer-routes/${newItem.id}`)
        }
      }
    )
  }

  return (
    <Button
      size="small"
      startIcon={<UndoIcon />}
      onClick={handleClick}
    >
      Criar rota inversa
    </Button>
  )
}

export default ItemReverseRouteButton
