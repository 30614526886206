import { List, DatagridConfigurable, TextField, FunctionField, EditButton, SingleFieldList, ArrayField, DateField, ReferenceInput, AutocompleteArrayInput } from 'react-admin'
import {
  ExportButton,
  FilterButton,
  SelectColumnsButton,
  TopToolbar
} from 'react-admin'
import DateFilter from './DateFilter'
import Box from '@mui/material/Box'
import { exportToExcel } from 'react-json-to-excel'
import Tooltip from '@mui/material/Tooltip'

const filters = [
  <DateFilter alwaysOn />,
  <ReferenceInput source="[bank_account][id][$in]" reference="bank-accounts-by-ids" alwaysOn>
    <AutocompleteArrayInput sx={{ width: '320px' }} label="Conta bancária"
      optionText={choice =>
        `${choice.name}`
      }
      filterToQuery={
        searchText => ({ name_containsi: searchText })
      } />
  </ReferenceInput>
]

const ListActions = () => {
  const exporter = (data) => {
    const transform = (item) => {
      return {
        'data': item.paymentSettlementDate,
        'conta_bancaria': item.bank_account.data?.attributes?.name,
        'descricao': item.description,
        'debito': item.transactionType === 'in' ? null : item.value,
        'credito': item.transactionType === 'out' ? null : item.value,
        'saldo': item.balance,
        'pessoa_empresa': item.parties.data?.map(p => p.attributes.name).join(' '),
        'categoria': item.categories.data?.map(c => c.attributes.name).join(' '),
        'rsv_lancamento_fatura': item.invoiceNumber,
        'plano_de_contas': item.chart_of_accounts.data?.map(c => `${c.attributes.structural_code} - ${c.attributes.name}`).join(' '),
        'cod_contabil_debito': item.transactionType === 'out' ?
          item.chart_of_accounts.data?.map(c => c.attributes.accounting_code).join(' ')
          : '',
        'cod_contabil_credito': item.transactionType === 'in' ?
          item.chart_of_accounts.data?.map(c => c.attributes.accounting_code).join(' ')
          : ''
      }
    }

    exportToExcel(data.map(item => transform(item)), 'movimentacao-financeira')
  }

  return (
    <TopToolbar>
      <SelectColumnsButton />
      <FilterButton />
      <ExportButton exporter={exporter}
        resource="export-validated-accounting-transactions"
        meta={{
          'populate': 'bank_account,parties,categories,chart_of_accounts',
        }} />
    </TopToolbar>
  )
}

const CustomList = (props) => {
  return (
    <>
      <List {...props}
        filters={filters}
        actions={<ListActions />}
        title="Movimentação Financeira"
        resource="accounting-transactions-with-balance"
        queryOptions={{
          meta: {
            'sort: paymentSettlementDate': 'ASC',
            'populate': '*',
            'filters[validated][$eq]': true
          }
        }}>
        {/* Campos: Vencimento, valor, tipo, beneficiário, plano de contas, N. RSV, conta bancária, data baixa, botão ok para validar e baixar. */}
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="id" label="ID" sortable={false} />
          <DateField source="paymentSettlementDate" label="DATA" />
          <FunctionField label="CONTA BANCÁRIA" render={record => {
            return record.bank_account ? record.bank_account.data?.attributes?.name : '?'
          }} />
          {/* <Box label="DESCRIÇÃO" sx={{ width: '130px' }}>
            <TextField source="description" />
          </Box> */}
          <Box label="DESCRIÇÃO" sx={{ minWidth: '180px' }}>
            <FunctionField render={record => (
              <Tooltip title={record.description}>
                <span>{record.description.split('|')[0]}</span>
              </Tooltip>
            )} />
          </Box>
          <Box label="DÉBITO" sx={{ width: '100px', color: 'red' }}>
            <FunctionField render={record => {
              if (record.transactionType === 'in') return null
              return `-${record.value ? record.value.toLocaleString('pt-BR', { style: 'currency', currency: record.currency }) : '?'}`
            }} />
          </Box>
          <Box label="CRÉDITO" sx={{ width: '100px' }}>
            <FunctionField render={record => {
              if (record.transactionType === 'out') return null
              return `${record.value ? record.value.toLocaleString('pt-BR', { style: 'currency', currency: record.currency }) : '?'}`
            }} />
          </Box>
          <FunctionField sx={{ whiteSpace: 'nowrap' }} label="SALDO" render={record => {
            return (
              <Box sx={{ color: record.balance && record.balance < 0 ? 'red' : 'initial' }}>
                {record.balance ? record.balance.toLocaleString('pt-BR', { style: 'currency', currency: record.currency }) : '-'}
              </Box>
            )
          }} />
          <ArrayField source="parties.data" label="PESSOA / EMPRESA" sortable={false}>
            <SingleFieldList linkType={false}>
              <TextField source="attributes.name" sx={{ minWidth: '180px' }} />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="categories.data" label="Categoria" sortable={false}>
            <SingleFieldList linkType={false}>
              <TextField source="attributes.name" />
            </SingleFieldList>
          </ArrayField>
          <FunctionField label="RSV / LANÇAMENTO / FATURA" render={record => {
            if (record.invoiceUrl) {
              return <a href={record.invoiceUrl} target="_blank" rel="noreferrer">{record.invoiceNumber}</a>
            }

            return record.invoiceNumber
          }} />
          <ArrayField source="chart_of_accounts.data" label="PLANO DE CONTAS" sortable={false}>
            <SingleFieldList linkType={false}>
              <FunctionField source="chart_of_accounts" render={record => {
                return `${record.attributes.structural_code} - ${record.attributes.name}`
              }} />
            </SingleFieldList>
          </ArrayField>
          <FunctionField label="COD CONTÁBIL DÉBITO" render={record => {
            return record.transactionType === 'out' ?
              record.chart_of_accounts.data?.map(c => c.attributes.accounting_code).join(', ')
              : ''
          }} />
          <FunctionField label="COD CONTÁBIL CRÉDITO" render={record => {
            return record.transactionType === 'in' ?
              record.chart_of_accounts.data?.map(c => c.attributes.accounting_code).join(', ')
              : ''
          }} />
          <EditButton label="" resource='accounting-transactions'
            sx={{ padding: 0, minWidth: '32px', position: 'relative', left: '-8px' }} />
        </DatagridConfigurable>
      </List>
    </>
  )
}

export default CustomList