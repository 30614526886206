import { useState } from 'react'
import {
  useRecordContext,
} from 'react-admin'
import HotelExpressPackageItem from './HotelExpressPackageItem'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import ItemClient from '../../List/ItemClient'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  height: 500,
  bgcolor: '#222',
  color: '#fff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
}

const ItemsSummary = () => {
  const record = useRecordContext()
  const [paymentModalOpen, setPaymentModalOpen] = useState(false)

  const handlePaymentModalClose = () => {
    setPaymentModalOpen(false)
  }

  return (
    <Stack gap={2} paddingBottom="2em">
      {/* hotel.express Package Items */}
      {record?.items?.filter(item => item.type === 'package' && item.provider === 'hotel.express')
        .map((item, index) => <HotelExpressPackageItem key={index} item={item} />)
      }

      {/* Financial */}
      <Card>
        <CardContent>
          <Stack>
            <Stack gap={1}>
              <Stack justifyContent="space-between" gap={2} direction="row" marginBottom="12px">
                <b>Valor Total</b>
                <b style={{ color: 'green' }}>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: record?.currency || 'BRL'
                  }).format(record?.totalPrice)}
                </b>
              </Stack>
              <Stack direction="row" fontSize={'14px'} justifyContent="space-between" gap={1}>
                <span>
                  {record?.payment?.Payment?.Type === 'CreditCard' ? 'Cartão de Crédito' : record?.payment?.Payment?.Type}
                  {record?.payment?.Payment?.Installments ? ` ${record?.payment?.Payment?.Installments}x` : ''}
                </span>
                <span>
                  {record?.payment?.Payment?.Currency}{' '}
                  {record?.payment?.Payment?.Amount / 100}
                </span>
              </Stack>
              <Stack direction="row" fontSize={'14px'} alignItems="center" justifyContent="space-between" gap={1}>
                <span>
                  {record?.payment?.Payment?.Provider}
                </span>
                <Stack>
                  <Button size="small" onClick={() => setPaymentModalOpen(true)}>
                    + Detalhes
                  </Button>
                  <Modal
                    open={paymentModalOpen}
                    onClose={handlePaymentModalClose}>
                    <Box sx={modalStyle}>
                      <pre>
                        {JSON.stringify(record?.payment, null, 2)}
                      </pre>
                    </Box>
                  </Modal>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      {/* Client */}
      <Card>
        <CardContent>
          <div><b>Cliente</b></div>
          <ItemClient sx={{ fontSize: '14px' }} openOnClick={true} />
        </CardContent>
      </Card>

      <Card>
        <CardContent sx={{ fontSize: '14px' }}>
          <Stack gap={2} divider={<Divider flexItem />}>
            <Stack justifyContent="space-between" gap={1} direction="row">
              <div>Reserva criada em:</div>
              <b>
                {
                  new Date(record?.createdAt).toLocaleDateString('pt-BR', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  })
                }
              </b>
            </Stack>
            <Stack justifyContent="space-between" gap={1} direction="row">
              <div>Última atualização:</div>
              <b>
                {
                  new Date(record?.updatedAt).toLocaleDateString('pt-BR', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  })
                }
              </b>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

    </Stack>
  )
}

export default ItemsSummary
