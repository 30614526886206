import { Create, TextInput, TopToolbar, SimpleForm, ListButton, BooleanInput } from 'react-admin'
import Stack from '@mui/material/Stack'

const CreateActions = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  )
}

const CreatePage = () => {
  return (
    <Create actions={<CreateActions />}>
      <SimpleForm>
        <Stack direction="row" spacing={2}>
          <TextInput source="name" label="Descrição" sx={{ marginTop: '0' }} />
          <TextInput source="structural_code" label="Estrutural" />
          <TextInput source="accounting_code" label="Cod Contábil" />
          <BooleanInput source="isActive" label="Ativo" options={{ checked: true }} />
        </Stack>
      </SimpleForm>
    </Create>
  )
}

export default CreatePage
