import styled from 'styled-components'

export const Month = styled.div`
  position: relative;
  padding: 8px;
  font-size: 14px;

  .month {
    &-header {
      text-align: center;
      padding: 12px 0;
    }

    &-title {
      font-weight: 500;
      color: #0f6b9b;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &-weekdays {
      display: flex;
      padding: 8px 0;
      color: #777;

      .item {
        flex: 0 0 14.28%;
        max-width: 14.28%;
        text-align: center;
      }
    }

    &-days {
      display: flex;
      flex-wrap: wrap;

      .item {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 0 0 10.8%;
        max-width: 10.8%;
        user-select: none;
        color: gray;
        font-weight: 500;
        background-color: white;
        border: 0;

        @media (min-width: 1280px) {
          &:hover {
            .item-content {
              color: white;
              background-color: #0f6b9b;
            }
          }
        }

        &[disabled] {
          cursor: not-allowed;
          font-weight: normal;
          color: #ccc;
        }

        &-active {
          .item-content {
            background-color: #0f6b9b;
            color: white;
          }

          &:hover {
            .item-content {
              color: white;
              background-color: #0f6b9b;
            }
          }
        }

        &-start-date {
          background: linear-gradient(90deg, white 50%, #E8F3FD 50%, #E8F3FD 100%);
        }

        &-end-date {
          background: linear-gradient(90deg, #E8F3FD 50%, white 50%, white 100%);
        }

        &-in-range {
          background-color: #E8F3FD;
        }

        &-content {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          font-size: 14px;
          border-radius: 50%;
          line-height: 50%;
          cursor: pointer;
        }
      }
    } 
  }
`