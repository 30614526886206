import { List, Datagrid, TextField, EditButton, DateField } from 'react-admin'

const CustomList = (props) => {
  return (
    <List {...props} title={"Beneficiários/Pagadores"}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" label="Nome" />
        <TextField source="cnpj" label="CNPJ" />
        <TextField source="cpf" label="CPF" />
        <DateField source="updatedAt" label="Atualizado em" showTime />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default CustomList
