import { useEffect, useState } from 'react'
import Icon from '@mui/icons-material/DirectionsCar'
import { Authenticated, Edit, SimpleForm, TextInput, useEditController } from 'react-admin'
import Toolbar from './Toolbar'
import MediaSelectModal from '../../media-library/SelectModal'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import countries from '../countries-cache.json'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import StrapiImage from '../../media-library/StrapiImage'

const TransferVehicleEdit = () => {
  const { record } = useEditController()
  const [continents, setContinents] = useState([])
  const [selectedImages, setSelectedImages] = useState([])

  useEffect(() => {
    if (record) {
      const continentsFromJson = countries.reduce((acc, item) => {
        if (!acc[item.attributes.continentName]) {
          acc[item.attributes.continentName] = {
            items: []
          }
        }

        let checked = false
        if (record.places && record.places.data && record.places.data.length) {
          checked = record.places.data.some(place => place.id === item.id)
        }

        acc[item.attributes.continentName].items.push({...item, checked: checked})
        return acc
      }, {})

      setContinents(continentsFromJson)

      if (record.images && record.images.data && record.images.data.length) {
        setSelectedImages(record.images.data.map(image => ({ id: image.id, ...image.attributes })))
      }
    }
  }, [record])

  const handleChange = (e) => {
    const continentsAlt = {...continents}

    if (e.target.dataset && e.target.dataset.type) {
      if (e.target.dataset.type === 'continent') {
        const items = continentsAlt[e.target.value].items.map(item => {
          item.checked = e.target.checked
          return item
        })

        continentsAlt[e.target.value] = {
          items: items,
          checked: e.target.checked
        }
      }

      if (e.target.dataset.type === 'country' && e.target.dataset.continent) {
        const items = continentsAlt[e.target.dataset.continent].items.map(item => {
          if (item.id === Number(e.target.value)) {
            item.checked = e.target.checked
          }
          return item
        })

        continentsAlt[e.target.dataset.continent] = {
          items: items,
          checked: items.every(item => item.checked)
        }
      }
    }

    setContinents(continentsAlt)
  }

  const transform = data => ({
    ...data,
    images: selectedImages.map(image => image.id),
    places: (() => {
      let checkedItemIds = []
      Object.keys(continents).forEach((continent) => {
        continents[continent].items.filter(item => item.checked).forEach(item => {
          checkedItemIds.push(item.id)
        })
      })

      return checkedItemIds
    })()
  })

  if (!record) {
    return (
      <Box sx={{ display: 'flex', padding: '16px' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Authenticated>
      <h1 style={{ marginBottom: '12px', marginTop: '1em', display: 'flex', gap: '8px', alignItems: 'center' }}>
        <Icon sx={{ width: '32px', height: '32px' }} />
        Editar veículo #{record.id}
      </h1>
      <Edit sx={{ width: '100%' }} transform={transform}>
        <SimpleForm toolbar={<Toolbar />}>
          <Box sx={{ width: '100%' }}>
            <Stack direction="row" spacing={3}>
              <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', width: '500px', height: '225px', padding: '12px', bgcolor: '#f9f9f9' }}>
                {/* <TextField label="IDs das imagens (separados por vírgula)" variant="outlined" fullWidth /> */}
                <MediaSelectModal buttonText="Selecionar imagem" selectedItems={selectedImages} onChange={(selectedImages) => setSelectedImages(selectedImages)} />
                <Box>
                  {selectedImages.length ?
                    <Stack direction="row" spacing={2} alignItems={'center'} sx={{ overflowX: 'scroll', width: '100%', padding: '16px 4px' }}>
                      {selectedImages.map((image, idx) => {
                        return (
                          <StrapiImage key={idx} src={`${image.formats && image.formats.small ? image.formats.small.url : image.url}`}
                            alt={image.name}
                            style={{ width: '200px', borderRadius: '6px', boxShadow: '0 2px 4px rgba(0,0,0,.2)', backgroundColor: 'white' }} />
                        )
                      })}
                    </Stack>
                  : ''}
                  
                </Box>
              </Box>
              <Stack width={'100%'}>
                <Stack direction="row" spacing={2} alignItems={'center'} width="100%">
                  <TextInput source="namePT" label="Nome PT" fullWidth required sx={{ margin: 0 }} />
                  <TextInput source="nameEN" label="Nome EN" fullWidth required sx={{ margin: 0 }} />
                  <TextInput source="nameES" label="Nome ES" fullWidth required sx={{ margin: 0 }} />
                  <TextInput source="code" label="código" sx={{ width: '150px', flexShrink: 0 }} />
                </Stack>
                <Stack direction="row" spacing={2} alignItems={'center'} width="100%">
                  <TextInput source="modelsPT" label="Modelos PT" fullWidth required />
                  <TextInput source="modelsEN" label="Modelos EN" fullWidth required />
                  <TextInput source="modelsES" label="Modelos ES" fullWidth required />
                </Stack>
                <Stack direction="row" spacing={2} alignItems={'center'}>
                  <TextInput source="maxSmallBaggage" fullWidth sx={{ margin: 0 }} />
                  <TextInput source="maxMediumBaggage" fullWidth />
                  <TextInput source="minPax" fullWidth sx={{ margin: 0 }} />
                  <TextInput source="maxPax" fullWidth />
                </Stack>
              </Stack>
            </Stack>
          </Box>
          <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px', marginTop: '24px' }}>
            <h3 style={{ margin: '0 0 12px' }}>Áreas</h3>
            <Grid container spacing={2}>
              {Object.keys(continents).map((continent, continentIdx) => {
                return (
                  <Grid item xs={2} key={continentIdx}>
                    <FormControlLabel
                      label={continent}
                      control={
                        <Checkbox
                          checked={continents[continent].checked || false}
                          indeterminate={!continents[continent].checked && continents[continent].items.some(item => item.checked)}
                          value={continent}
                          onChange={handleChange}
                          inputProps={{ 'data-type': 'continent' }}
                        />
                      }
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                      {continents[continent].items.map((country, countryIdx) => {
                        return (
                          <FormControlLabel
                            key={countryIdx}
                            label={country.attributes.namePT}
                            control={
                              <Checkbox
                                value={country.id}
                                inputProps={{ 'data-type': 'country', 'data-continent': continent }}
                                onChange={handleChange}
                                checked={country.checked || false} />
                            }
                          />
                        )
                      })}
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </SimpleForm>
      </Edit>
    </Authenticated>
  )
}

export default TransferVehicleEdit
