import { useState, useEffect } from 'react'
import { useListContext } from 'react-admin'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom'
import Box from '@mui/material/Box'
import 'instantsearch.css/themes/satellite.css'

const algoliaClient = algoliasearch('R1OKWHPZ4Y', '04cd0f2fca4f9760ab1491c78b8d9aac')
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      });
    }

    return algoliaClient.search(requests.map((req) => {
      return {
        ...req,
        params: {
          ...req.params,
          hitsPerPage: 5,
        }
      }
    }))
  },
}

const SupplierFilter = () => {
  const {
    filterValues,
    setFilters,
  } = useListContext()
  const [searchState, setSearchState] = useState({})
  const [supplier, setSupplier] = useState(filterValues.supplier?.id?.$in ? { id: filterValues.supplier.id.$in } : null)

  const handleSearchStateChange = (searchState) => {
    setSearchState(searchState)
  }

  useEffect(() => {
    setFilters({
      ...filterValues,
      supplier: supplier ? {
        id: {
          $in: supplier.id
        }
      } : null
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier])

  return (
    <Box sx={{
      position: 'relative',
      border: '1px solid #ccc',
      borderRadius: '12px',
      height: '54px',
      padding: '6px 0',
      marginBottom: '4px',
      width: '230px',
      '.ais-SearchBox-form': {
        backgroundColor: 'unset'
      },
      '.ais-SearchBox-input': {
        border: 0,
        boxShadow: 'none',
        backgroundColor: 'unset',
        caretColor: 'currentColor',
      }
    }}>
      {supplier ?
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', padding: '0 12px' }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            cursor: 'pointer',
            justifyContent: 'space-between',
            width: '100%',
            fontSize: '12px'
          }}
            onClick={() => {
              setSupplier(null)
            }}>
            <Box sx={{ borderRadius: '4px', padding: '2px 4px', backgroundColor: 'gray', color: 'white' }}>#{supplier.id}</Box>
            <Box sx={{
              flexGrow: 1,
              maxWidth: '150px',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {supplier.trade_name || supplier.company_name}
            </Box>
            <Box sx={{ color: 'gray', justifySelf: 'flex-end' }}>Limpar</Box>
          </Box>
        </Box>
        :
        <InstantSearch
          searchClient={searchClient}
          searchState={searchState}
          onSearchStateChange={handleSearchStateChange}
          indexName="production_api::supplier.supplier">
          <SearchBox
            translations={{
              placeholder: 'Fornecedor',
            }} />
          <Box sx={{ position: 'absolute', zIndex: 998 }}>
            <Hits hitComponent={({ hit }) => (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', width: '320px', cursor: 'pointer' }} onClick={() => {
                setSupplier(hit)
                setSearchState({
                  query: '',
                })
              }}>
                <Box sx={{ fontSize: '12px', borderRadius: '4px', padding: '2px 4px', backgroundColor: 'gray', color: 'white' }}>#{hit.id}</Box>
                <Box sx={{ flexGrow: 1 }}>{hit.trade_name || hit.company_name}</Box>
                {/* <Box>{hit.rating ? hit.rating : '?'} / 10</Box> */}
              </Box>
            )} />
          </Box>
        </InstantSearch>
      }
    </Box>
  )
}

export default SupplierFilter
