import Icon from '@mui/icons-material/Key'
import List from './List'
import Edit from './Edit'
import Create from './Create'

const props = {
  icon: Icon,
  options: { label: 'Centros de custo'},
  list: List,
  edit: Edit,
  create: Create
}

export default props
