import React, { useContext } from 'react'
import { List, Datagrid, TextField, DateField, FunctionField, useRecordContext, Authenticated } from 'react-admin'
import userContext from '../../contexts/userContext'
import { DateInput, SelectInput, TextInput } from 'react-admin'
import { getCityName } from '../utils'
import { Box, Typography } from '@mui/material'
import ItemStatus from './ItemStatus'
import ItemPaymentStatus from './ItemPaymentStatus.js'
import ItemSupplierPaymentStatus from './ItemSupplierPaymentStatus.js'
import ItemPax from './ItemPax'

const postFilters = [
  <DateInput label="Check-in a partir de..." sx={{ width: '210px' }} source="serviceDate_gte" alwaysOn />,
  <SelectInput label="Status" source="status" alwaysOn choices={[
    { id: 'confirmed', name: 'Confirmado' },
    { id: 'pending', name: 'Pendente' },
    { id: 'canceled', name: 'Cancelado' },
  ]} />,
  // <SelectInput label="Tipo produto" source="serviceType" alwaysOn choices={[
  //   { id: 'Tour', name: 'Tour' },
  //   { id: 'Transfer', name: 'Transfer' },
  //   { id: 'Hotel', name: 'Hotel' },
  //   { id: 'Car', name: 'Rent a Car' }
  // ]} />,
  <TextInput label="cgBookingNumber" source="cgBookingNumber" alwaysOn />,
  <TextInput label="cgServiceId" source="cgServiceId" alwaysOn />,
]

const Panel = () => {
  const record = useRecordContext()
  const customFieldsObj = {}

  for (const customField of record.customFields) {
    customFieldsObj[customField.name] = customField.value
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '24px', padding: '12px', color: '#555' }}>
        <div>
          <div>
            <b>Endereço do Hotel: </b>{record.hotelAddress || '-'}
          </div>
          <br />
          <div>
            <b>Pagamento no fornecedor: </b><ItemSupplierPaymentStatus />
          </div>
          <br />
          {/* <div>
            <b>Voo: </b>{flightStr ? <>
              <a href={`https://www.google.com/search?q=${flightStr}`} target="_blank" rel="noreferrer">{flightStr}</a> às {record.originAirTime}
            </> : '-'}
          </div> */}
        </div>
        <div>
          <div>
            <b>Voucher Obs.: </b> {record.cgObservation || '-'}
          </div>
        </div>
      </div>
    </>
  )
}

const rowStyle = (record, index) => ({
  backgroundColor: index % 2 ? "#E8ECF5" : ""
})

const Empty = () => (
  <Box textAlign="center">
    <Typography variant="body1" sx={{ margin: '32px 16px' }}>
      Nenhuma reserva ainda.
    </Typography>
  </Box>
)

const CustomList = (props) => {
  const [user] = useContext(userContext)
  const meta = {
    'populate': 'deep,3', // populate relations
  }

  if (user && user.affiliate && user.affiliate.id) {
    meta['filters[affiliate][id][$eq]'] = user.affiliate.id
  }

  return (
    <Authenticated>
      {user && user.affiliate && user.affiliate.id ?
        <List {...props}
          empty={<Empty />}
          queryOptions={{ meta: meta }}
          sort={{ field: 'serviceDate', order: 'ASC' }}
          filters={postFilters}>
          <Datagrid expand={<Panel />} bulkActionButtons={false} rowStyle={rowStyle} sx={{
            "& .RaDatagrid-headerCell": {
              backgroundColor: "#E8ECF5",
            },
            "& .MuiTableRow-root:hover": {
              backgroundColor: "#fff2c3 !important",
            },
          }}>
            <TextField source='hotelName' label="Nome" />
            <Box sx={{ width: '100px' }} label="Cidade">
              <FunctionField source="cityName" render={record => {
                const cityName = getCityName(record)
                return cityName ? cityName.split(',')[0] : null
              }} />
            </Box>

            <Box sx={{ width: '70px' }} label="Check-in">
              <DateField source="serviceDate" />
            </Box>
            <Box sx={{ width: '70px' }} label="Check-out">
              <DateField source="checkOutDate" />
            </Box>
            {/* <TextField source="checkInHour" label="H Início" /> */}
            {/* <Box sx={{ padding: '8px', background: '#eee', border: '1px solid #ccc', minHeight: '8px', minWidth: '28px' }} label="H Fim">
                  <TextField source="checkOutHour" />
                </Box> */}
            <ItemStatus label="Status" />
            <ItemPaymentStatus label="Pagamento Cliente" />
            <Box sx={{ width: '100px' }} label="Passageiro">
              <ItemPax />
            </Box>
            <Box sx={{ textAlign: 'center' }} label="PAXs">
              <FunctionField render={record => record.pax ? record.pax.length : 0} />
            </Box>
            <TextField source="serviceType" label="Produto" sx={{ fontSize: '12px', fontWeight: 'bold', color: '#555' }} />
            <FunctionField sx={{ color: 'teal', whiteSpace: 'nowrap' }} label="RSV Cangooroo" source="cgBookingNumber" render={record => record.cgBookingNumber ? `${record.cgBookingNumber}-${record.cgServiceId}` : ''} />
            <FunctionField sx={{ color: '#078F20', fontWeight: 'bold', whiteSpace: 'nowrap' }} source="price.value"
              render={record => record.price ? `${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: record.price.currency }).format(record.price.value - record.agencyCommissionValue)}` : ''} label="Valor Net" />
            {/* <Box label="Valor Fornecedor">
                  <ItemSupplierPrice />
                </Box> */}
          </Datagrid>
        </List>
      : <></>}
    </Authenticated>
  )
}

export default CustomList
