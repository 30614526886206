import { useState } from 'react'
import { useUpdate, useRecordContext } from 'react-admin'
import { TimeInput, Form, Button } from 'react-admin'
import CircularProgress from '@mui/material/CircularProgress'

const ItemCheckoutHour = () => {
  const record = useRecordContext()
  const [update, { isLoading }] = useUpdate()
  const [shouldSave, setShouldSave] = useState(false)

  const handleInputChange = (e) => {
    setShouldSave(!!e.target.value)
  }

  const handleFormSubmit = async (data) => {
    const date = data.checkOutHour
    const userTimezoneOffset = date.getTimezoneOffset() * 60000
    const finalDate = new Date(date.getTime() - userTimezoneOffset)
    const hour = finalDate.toISOString().split('T')[1].slice(0, 5)

    await update(
      'services',
      { id: data.id, data: { checkOutHour: hour }, previousData: record }
    )

    setShouldSave(false)
  }

  return (
    <Form onSubmit={handleFormSubmit}>
      <div style={{ position: 'relative' }}>
        <TimeInput source="checkOutHour" label="" hiddenLabel onChange={handleInputChange} sx={{
          '& .MuiInputBase-root': {
            borderRadius: 0,
            margin: '0 0 -20px',
            'fieldset': {
              display: 'none'
            }
          },
          '& .MuiInputBase-input': {
            padding: '0 2px',
            fontSize: '0.875rem',
            textAlign: 'center',
            border: '1px solid #bbb',
            '&::-webkit-calendar-picker-indicator': {
              background: 'none',
              display: 'none'
            }
          },
        }} />
        {shouldSave &&
          <Button disableElevation variant="contained" type="submit" sx={{
            padding: 0,
            minWidth: '32px',
            textAlign: 'center',
            fontSize: '12px',
            fontWeight: '600',
            borderRadius: '0 4px 4px 0',
            position: 'absolute',
            top: '8px',
            height: '22px',
            right: '-32px',
            'span': {
              margin: 0
            }}}>
            {isLoading ? <CircularProgress size={'18px'} sx={{ color: 'white' }} /> : <>OK</>}
          </Button>
        }
      </div>
    </Form>
  )
}

export default ItemCheckoutHour