import {
  TopToolbar,
  List,
  Authenticated,
  useNotify,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  EditButton,
  FilterButton,
  ExportButton,
  SelectArrayInput,
  SelectInput,
  TextInput,
  // ReferenceInput,
  // AutocompleteInput,
  Pagination,
  useRecordContext
} from 'react-admin'
import ImportChecker from '../imported-items/Checker'
import Alerts from '../services/Alerts'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DescriptionIcon from '@mui/icons-material/Description'
import { getPaxNumber } from '../services/utils'
import DateFilter from '../services/List/DateFilter'
import ItemPaymentStatus from '../services/List/ItemPaymentStatus'
import ItemClient from '../services/List/ItemClient'
import ItemCgStatus from '../services/List/ItemCgStatus'
import ItemSupplierStatus from '../services/List/ItemSupplierStatus'
import {
  cgStatusOptions,
  supplierStatusOptions,
  operationalStatusOptions,
  supplierReservationPriorityOptions,
  needsFinancialReviewOptions
} from '../services/utils'
import ItemFinancialReviewStatus from './ItemFinancialReviewStatus'
import ItemSupplierPrice from '../services/List/ItemSupplierPrice'
import ItemStartLocation from '../services/List/ItemStartLocation'
import ItemVoucherObs from '../services/List/ItemVoucherObs'
import Summary from './Summary'
import { exportToExcel } from 'react-json-to-excel'
import SupplierFilter from '../services/List/SupplierFilter'

const todaysDate = (() => {
  const date = new Date()
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() - userTimezoneOffset).toISOString()
})()

/**
 * - if does not have price or price.value is 0
 * - if does not have supplierPrice or supplierPrice.value is 0
 * - if margin is less than 30%
 * - if supplierPrice.currency is not BRL
 * - if price.currency is not BRL
 */
const getFinancialReviewReason = (record) => {
  if (!record.price || record.price.value === 0) {
    return 'O serviço não tem preço definido'
  }

  if (!record.supplierPrice || record.supplierPrice.value === 0) {
    return 'O serviço não tem custo de fornecedor definido'
  }

  if (((record.price.value - record.supplierPrice.value) / record.price.value) * 100 < 30) {
    return 'A margem bruta é menor que 30%'
  }

  if (record.supplierPrice.currency !== 'BRL') {
    return 'O custo de fornecedor não está em BRL'
  }

  if (record.price.currency !== 'BRL') {
    return 'O preço não está em BRL'
  }

  return null
}

const rowStyle = (record) => {
  if (record && (record.needsFinancialReview || getFinancialReviewReason(record))) {
    return {
      backgroundColor: 'hsl(0deg 100% 77.06% / 30%)'
    }
  }
}

const StatusChoiceWithColor = ({ choice }) => {
  return (
    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      <Box className='color-box' sx={{ backgroundColor: choice.color, width: '16px', height: '16px', borderRadius: '4px', marginRight: '8px' }}></Box>
      {choice.name}
    </Box>
  )
}

const postFilters = [
  <DateFilter alwaysOn />,
  <SelectInput label="Status Revisão" source="needsFinancialReview" alwaysOn
    choices={Object.keys(needsFinancialReviewOptions).filter(key => key !== 'none').map(key => {
      return {
        id: key,
        name: needsFinancialReviewOptions[key].label,
        color: needsFinancialReviewOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Status Reserva" source="cgStatus" alwaysOn
    choices={Object.keys(cgStatusOptions).map(key => {
      return {
        id: key,
        name: cgStatusOptions[key].label,
        color: cgStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Status Fornecedor" source="supplierStatus" alwaysOn
    choices={Object.keys(supplierStatusOptions).map(key => {
      return {
        id: key,
        name: supplierStatusOptions[key].label,
        color: supplierStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Tipo produto" source="serviceType" alwaysOn choices={[
    { id: 'Tour', name: 'Tour & Tickets' },
    { id: 'Transfer', name: 'Transfer' },
    { id: 'Hotel', name: 'Hotel' },
    { id: 'Car', name: 'Rent a Car' },
    { id: 'Flight', name: 'Aéreo' },
    { id: 'Insurances', name: 'Seguro' }
  ]} />,
  <SelectArrayInput label="Prioridade" source="supplierReservationPriority" alwaysOn
    choices={Object.keys(supplierReservationPriorityOptions).filter(key => key !== 'none').map(key => {
      return {
        id: key,
        name: supplierReservationPriorityOptions[key].label,
        color: supplierReservationPriorityOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <TextInput label="cgBookingNumber" source="cgBookingNumber" alwaysOn />,
  <TextInput label="cgServiceId" source="cgServiceId" alwaysOn />,
  <SelectArrayInput label="Status Operacional" source="operationalStatus"
    choices={Object.keys(operationalStatusOptions).map(key => {
      return {
        id: key,
        name: operationalStatusOptions[key].label,
        color: operationalStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  // <ReferenceInput source="[supplier][id][$in]" reference="suppliers" alwaysOn>
  //   <AutocompleteInput sx={{ width: '320px' }} label="Fornecedor"
  //     optionText={choice =>
  //       `#${choice.id} ${choice.trade_name}`
  //     }
  //     filterToQuery={
  //       searchText => ({ trade_name_containsi: searchText })
  //     } />
  // </ReferenceInput>
  <SupplierFilter alwaysOn />
]

const Panel = () => {
  const record = useRecordContext()
  return (
    <div>
      <b>{getFinancialReviewReason(record) || 'Nenhuma divergência encontrada'}</b>{' '}
      - Útima atualização: {new Date(record.updatedAt).toLocaleString()}
    </div>
  )
}

const CustomPagination = () => <Pagination sx={{
  '.MuiTablePagination-spacer': {
    display: 'none'
  },
  '.MuiToolbar-root': {
    padding: 0
  },
  '.MuiPaginationItem-root .MuiPaginationItem-icon': {
    width: '28px',
    height: '28px',
    margin: '0 4px'
  }
}} rowsPerPageOptions={[10, 25, 50, 100]} />

const ListActions = () => {
  const exporter = (data) => {
    const transform = (item) => {
      return {
        'id': item.id,
        'revisao': item.needsFinancialReview ? 'revisar' : 'ok',
        'sts_reserva': item.cgStatus,
        'data_rsv': item.reservationDate,
        'data_servico': item.serviceDate,
        'cliente': item.client?.data?.attributes?.company_name || '',
        'status_pgto': item.client?.data?.attributes?.canPayLater ?
          'FATURADO' : item.paymentStatus === 'Payed' ? 'PAGO' : 'NÃO',
        'tipo': `${item.serviceType}`,
        'pax': getPaxNumber(item),
        'voucher_obs': item.cgObservation,
        'sts_fornecedor': item.supplierStatus,
        'fornecedor': item.supplier?.data?.attributes?.trade_name || '',
        'valor_net': item.price?.value ?
          new Intl.NumberFormat('pt-BR', { style: 'currency', currency: item.price.currency }).format(item.price.value) : '',
        'valor_fornecedor': item.supplierPrice?.value ?
          new Intl.NumberFormat('pt-BR', { style: 'currency', currency: item.supplierPrice.currency }).format(item.supplierPrice.value) : '',
        'valor_fornecedor_me': item.supplierPriceFC,
        'margem_bruta': item.price?.value && item.supplierPrice?.value ?
          (((item.price.value - item.supplierPrice.value) / item.price.value) * 100).toFixed(2) : '',
      }
    }

    exportToExcel(data.map(item => transform(item)), `receita-operacional-${new Date().getTime()}`)
  }

  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton exporter={exporter}
        meta={{
          'populate': 'price, supplierPrice',
        }} />
    </TopToolbar>
  )
}

const CustomList = (props) => {
  const notify = useNotify()
  const meta = {}

  // populate relations
  meta[`populate`] = 'deep,3'

  return (
    <Authenticated>
      <ImportChecker />
      <Alerts />
      <List {...props}
        style={{ minWidth: '2200px' }}
        title="Receita Operacional"
        resource="services:bookingFlow"
        queryOptions={{ meta: meta }}
        sort={{ field: 'reservationDate', order: 'DESC' }}
        debounce={500}
        pagination={<CustomPagination />}
        perPage={25}
        filterDefaultValues={{ serviceDate_gte: todaysDate.split('T')[0] }}
        filters={postFilters}
        actions={<ListActions />}>
        <Datagrid rowSx={rowStyle} expand={<Panel />} bulkActionButtons={false}>
          <TextField source="id" />
          <Box label="Revisão">
            <ItemFinancialReviewStatus />
          </Box>
          <Box label="Sts. Reserva" sx={{ width: '80px' }}>
            <ItemCgStatus />
          </Box>
          <DateField source="reservationDate" label="Data RSV" />
          <DateField source="serviceDate" label="Data Serviço" />
          <Box label="Cliente" sx={{ width: '125px' }}>
            <ItemClient />
          </Box>
          <FunctionField label="No. RSV" source="cgBookingNumber" render={record =>
            <div>
              {record.cgBookingNumber ?
                <Button onClick={() => {
                  navigator.clipboard.writeText(`${record.cgBookingNumber}-${record.cgServiceId}`)
                  notify('Número reserva copiado para área de transferência', { type: 'success' })

                  if (record.voucherUrl && record.voucherUrl !== '') {
                    setTimeout(() => {
                      window.open(record.voucherUrl, 'popup', 'width=800,height=600')
                    }, 300)
                  }
                }}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '4px',
                    color: 'teal',
                    fontWeight: '600',
                    whiteSpace: 'nowrap',
                    padding: '0 6px'
                  }}
                  title={record.voucherUrl && record.voucherUrl !== '' ? 'Ver voucher' : ''}>
                  {record.cgBookingNumber}-{record.cgServiceId} {record.voucherUrl ? <DescriptionIcon sx={{ width: '12px', height: '12px', marginTop: '-1px' }} /> : ''}
                </Button>
                : ''}
            </div>
          } />
          <Box label="Status Pgto">
            <ItemPaymentStatus />
          </Box>
          <FunctionField label="Tipo" source="serviceType" sx={{ fontSize: '12px', textTransform: 'uppercase', fontWeight: 'bold', color: '#555' }} render={record => {
            return `${record.serviceType} ${getPaxNumber(record)} PAX`
          }} />
          <Box label="Local Início">
            <ItemStartLocation />
          </Box>
          <Box label="Voucher Obs." sx={{ width: '290px' }}>
            <ItemVoucherObs />
          </Box>
          <Box label="Sts. Fornecedor" sx={{ width: '100px' }}>
            <ItemSupplierStatus readOnly={true} />
          </Box>
          <Box label="Fornecedor" sx={{ width: '190px' }}>
            <FunctionField source="supplier" render={record => {
              return record?.supplier?.data?.attributes?.trade_name || ''
            }} />
          </Box>
          <FunctionField sx={{ color: '#078F20', fontWeight: 'bold', whiteSpace: 'nowrap' }} source="price.value"
            render={record => record.price ? `${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: record.price.currency }).format(record.price.value - record.agencyCommissionValue)}` : ''} label="Valor Net" />
          <Box label="Valor Fornecedor">
            <ItemSupplierPrice />
          </Box>
          <FunctionField label="Margem Bruta" render={record => {
            return (
              <>
                {record.price && record.price.value && record.supplierPrice && record.supplierPrice.value ?
                  <Box textAlign={'center'}>
                    {(((record.price.value - record.supplierPrice.value) / record.price.value) * 100).toFixed(2)}%
                  </Box>
                  : ''}
              </>
            )
          }} />
          <EditButton resource="services" />
        </Datagrid>
        <Box sx={{ margin: '16px 0', maxWidth: '720px' }}>
          <Summary />
        </Box>
      </List>
    </Authenticated>
  )
}

export default CustomList