import { useState, useEffect, useRef } from 'react'
import { useRecordContext, useUpdate } from 'react-admin'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    lineHeight: '22px',
    padding: '8px',
    border: '1px solid #dadde9'
  }
}))

const ItemObs = ({ ...props }) => {
  const record = useRecordContext()
  const ref = useRef(null)
  const [update, { isLoading }] = useUpdate()
  const [obs, setObs] = useState(props.obs || '')
  const [shouldSave, setShouldSave] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)

  useEffect(() => {
    // console.log(record.service[0])
    if (record && record.observation) {
      setObs(record.observation)
    }
  }, [record])

  useEffect(() => {
    if (isEditOpen && !shouldSave) {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsEditOpen(false)
        }
      }

      document.addEventListener('click', handleClickOutside, true)

      return () => {
        document.removeEventListener('click', handleClickOutside, true)
      }
    }
  }, [isEditOpen, shouldSave])

  const handleChange = (event) => {
    setObs(event.target.value)
    setShouldSave(event.target.value !== record.observation)
  }

  const handleButtonClick = (e) => {
    update(
      'services',
      { id: record.id, data: { observation: obs }, previousData: record },
      {
        onSuccess: () => {
          setShouldSave(false)
          setIsEditOpen(false)
        }
      }
    )
  }

  return (
    <Box ref={ref} sx={{ position: 'relative', width: '280px' }}>
      {isEditOpen ?
        <TextField
          onChange={handleChange}
          multiline
          rows={3}
          defaultValue={obs}
          placeholder='Observação...'
          variant="standard"
          sx={{
            bgcolor: '#fff',
            padding: '0',
            paddingBottom: '0',
            width: '100%',
            '.MuiInputBase-root': {
              padding: 0,
              '&::after': {
                display: 'none',
              },
              '&::before': {
                display: 'none',
              }
            },
            '.MuiInputBase-input': {
              fontSize: '12px',
              lineHeight: '1.4em',
              padding: '4px',
              '&:focus': {
                border: '1px solid #3c81c9'
              }
            }
          }}
        />
        :
        <LightTooltip title={obs}>
          <Box
            onClick={() => setIsEditOpen(true)}
            sx={{
              fontSize: '12px',
              lineHeight: '1.45em',
              color: 'red',
              width: '100%',
              height: '50px',
              display: '-webkit-box',
              WebkitLineClamp: '4',
              WebkitBoxOrient: 'vertical',
              cursor: 'text',
              overflow: 'hidden',
              ':hover': {
                backgroundColor: '#eee'
              }
            }}>{obs}</Box>
        </LightTooltip>
        }

      {shouldSave ?
        <Box sx={{ position: 'absolute', top: '8px', left: '100%', display: shouldSave ? 'block' : 'none' }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: '0 4px 4px 0',
              padding: '4px 8px',
              minWidth: 'auto'
            }}
            disabled={isLoading}
            onClick={handleButtonClick}
          >
            OK
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
        : ''}
    </Box>
  )
}

export default ItemObs
