import { InfiniteList, Authenticated, useNotify, Datagrid, DateField, FunctionField, EditButton, SelectArrayInput, TextInput } from 'react-admin'
import ImportChecker from '../imported-items/Checker'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DescriptionIcon from '@mui/icons-material/Description'
import { getPaxNumber } from '../services/utils'
import ItemPaymentStatus from '../services/List/ItemPaymentStatus'
import ItemSupplier from '../services/List/ItemSupplier'
import ItemClient from '../services/List/ItemClient'
import ItemCgStatus from '../services/List/ItemCgStatus'
import ItemSupplierStatus from '../services/List/ItemSupplierStatus'
import { cgStatusOptions, supplierStatusOptions, operationalStatusOptions, supplierPaymentStatusOptions } from '../services/utils'
import ItemSupplierPrice from '../services/List/ItemSupplierPrice'
import ItemSupplierPaymentStatus from '../services/List/ItemSupplierPaymentStatus'

// const todaysDate = (() => {
//   const date = new Date()
//   const userTimezoneOffset = date.getTimezoneOffset() * 60000
//   return new Date(date.getTime() - userTimezoneOffset).toISOString()
// })()

const StatusChoiceWithColor = ({ choice }) => {
  return (
    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      <Box className='color-box' sx={{ backgroundColor: choice.color, width: '16px', height: '16px', borderRadius: '4px', marginRight: '8px' }}></Box>
      {choice.name}
    </Box>
  )
}

const postFilters = [
  <SelectArrayInput label="Status Reserva" source="cgStatus" alwaysOn
    choices={Object.keys(cgStatusOptions).map(key => {
      return {
        id: key,
        name: cgStatusOptions[key].label,
        color: cgStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Status Fornecedor" source="supplierStatus" alwaysOn
    choices={Object.keys(supplierStatusOptions).map(key => {
      return {
        id: key,
        name: supplierStatusOptions[key].label,
        color: supplierStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Status Pgto. Fornecedor" source="supplierPaymentStatus" alwaysOn
    choices={Object.keys(supplierPaymentStatusOptions).map(key => {
      return {
        id: key,
        name: supplierPaymentStatusOptions[key].label,
        color: supplierPaymentStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />,
  <SelectArrayInput label="Tipo produto" source="serviceType" alwaysOn choices={[
    { id: 'Tour', name: 'Tour & Tickets' },
    { id: 'Transfer', name: 'Transfer' },
    { id: 'Hotel', name: 'Hotel' },
    { id: 'Car', name: 'Rent a Car' },
    { id: 'Flight', name: 'Aéreo' },
    { id: 'Insurances', name: 'Seguro' }
  ]} />,
  <TextInput label="cgBookingNumber" source="cgBookingNumber" alwaysOn />,
  <TextInput label="cgServiceId" source="cgServiceId" alwaysOn />,
  <SelectArrayInput label="Status Operacional" source="operationalStatus"
    choices={Object.keys(operationalStatusOptions).map(key => {
      return {
        id: key,
        name: operationalStatusOptions[key].label,
        color: operationalStatusOptions[key].color
      }
    })}
    optionText={(choice) => {
      return (
        <StatusChoiceWithColor choice={choice} />
      )
    }} />
]

const CustomList = (props) => {
  const notify = useNotify()
  const meta = {}

  // populate relations
  meta[`populate`] = 'deep,3'

  return (
    <Authenticated>
      <ImportChecker />
      <InfiniteList {...props}
        title="Pagamentos de Fornecedores"
        resource="services:supplierPayments"
        queryOptions={{ meta: meta }}
        sort={{ field: 'supplierPaymentDate', order: 'ASC' }}
        debounce={500}
        filterDefaultValues={{ supplierPaymentStatus: 'notPaid', cgStatus: 'Confirmed', supplierPaymentDate_gte: '1971-01-01' }}
        filters={postFilters}>
        <Datagrid bulkActionButtons={false}>
          <Box label="Sts. Reserva" sx={{ width: '60px' }}>
            <ItemCgStatus />
          </Box>
          <Box label="Sts. Forn." sx={{ width: '60px' }}>
            <ItemSupplierStatus />
          </Box>
          <Box label="Pgto Forn." source="supplierPaymentStatus" sx={{ width: '60px' }}>
            <ItemSupplierPaymentStatus />
          </Box>
          <DateField source="supplierPaymentDate" label="Data Pgto. Forn." />
          <Box label="Fornecedor" sx={{ width: '190px' }}>
            <ItemSupplier readOnly={true} supplierMessageAction={false} />
          </Box>
          <FunctionField sx={{ color: '#078F20', fontWeight: 'bold', whiteSpace: 'nowrap' }} source="price.value"
            render={record => record.price ? `${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: record.price.currency }).format(record.price.value - record.agencyCommissionValue)}` : ''} label="Valor Net" />
          <Box label="Valor Fornecedor" source="supplierPrice">
            <ItemSupplierPrice />
          </Box>
          <FunctionField label="Tipo" source="serviceType" sx={{ fontSize: '12px', textTransform: 'uppercase', whiteSpace: 'nowrap', fontWeight: 'bold', color: '#555' }} render={record => {
            return `${record.serviceType} ${getPaxNumber(record)} PAX`
          }} />
          <FunctionField label="No. RSV" source="cgBookingNumber" render={record =>
            <div>
              {record.cgBookingNumber ?
                <Button onClick={() => {
                  navigator.clipboard.writeText(`${record.cgBookingNumber}-${record.cgServiceId}`)
                  notify('Número reserva copiado para área de transferência', { type: 'success' })

                  if (record.voucherUrl && record.voucherUrl !== '') {
                    setTimeout(() => {
                      window.open(record.voucherUrl, 'popup', 'width=800,height=600')
                    }, 300)
                  }
                }}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '4px',
                    color: 'teal',
                    fontWeight: '600',
                    whiteSpace: 'nowrap',
                    padding: '0 6px'
                  }}
                  title={record.voucherUrl && record.voucherUrl !== '' ? 'Ver voucher' : ''}>
                  {record.cgBookingNumber}-{record.cgServiceId} {record.voucherUrl ? <DescriptionIcon sx={{ width: '12px', height: '12px', marginTop: '-1px' }} /> : ''}
                </Button>
                : ''}
            </div>
          } />
          <DateField source="serviceDate" label="Data Serviço" />
          <Box label="Cliente" sx={{ width: '125px' }}>
            <ItemClient />
          </Box>
          <Box label="Status Pgto">
            <ItemPaymentStatus />
          </Box>
          <EditButton resource="services" />
        </Datagrid>
      </InfiniteList>
    </Authenticated>
  )
}

export default CustomList
