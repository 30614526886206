import { useState, useEffect } from 'react'
import { useRecordContext } from 'react-admin'

const ItemPaymentStatus = ({ status: statusFromProps, ...props }) => {
  const record = useRecordContext()
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (statusFromProps) {
      if (statusFromProps === 'Payed') {
        setStatus('Pago')
      } else {
        setStatus('Não pago')
      }
    } else {
      if (record && record.paymentStatus && record.paymentStatus === 'Payed') {
        setStatus('Pago')
      } else {
        setStatus('Não pago')
      }
    }
  }, [statusFromProps, record])

  if (!record) return null

  return (
    <div style={{ color: status === 'Pago' ? 'green' : 'red', fontWeight: 'bold' }}>
      {status}
    </div>
  )
}

export default ItemPaymentStatus
