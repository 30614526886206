import { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { useListContext, useGetList } from 'react-admin'

const Summary = () => {
  const { filterValues } = useListContext()
  const { data, isLoading } = useGetList(
    'services/totals',
    {
      filter: filterValues,
    }
  )

  const [totals, setTotals] = useState({
    net: {
      label: 'Total Valor Net',
      value: null
    },
    cost: {
      label: 'Total Custo Fornecedor',
      value: null
    },
    diff: {
      label: 'Diferença',
      value: null
    },
    grossMargin: {
      label: 'Margem Bruta',
      value: null
    }
  })

  useEffect(() => {
    if (data) {
      const net = data.find(item => item.key === 'net')
      const cost = data.find(item => item.key === 'cost')
      const diff = data.find(item => item.key === 'diff')
      const grossMargin = data.find(item => item.key === 'grossMargin')

      setTotals({
        net: {
          label: 'Total Valor Net',
          ...(net ? { ...net, value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: net.currency }).format(net.value) } : {}),
        },
        cost: {
          label: 'Total Custo Fornecedor',
          ...(cost ? { ...cost, value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: cost.currency }).format(cost.value) } : {}),
        },
        diff: {
          label: 'Diferença',
          ...(diff ? {
            ...diff,
            value: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: diff.currency }).format(diff.value),
            style: { color: diff.value < 0 ? 'red' : 'green' }
          } : {}),
        },
        grossMargin: {
          label: 'Margem Bruta',
          ...(grossMargin ? {
            ...grossMargin,
            value: `${grossMargin.value ? grossMargin.value.toFixed(2) : 0}%`,
            style: { color: grossMargin.value < 30 ? 'red' : '' }
          } : {}),
        }
      })
    }
  }, [data])

  return (
    <Box sx={{ padding: '0 16px' }}>
      <Stack
        direction="row"
        sx={{ fontWeight: '500', marginBottom: '8px' }}
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}>
        {Object.values(totals).map((item, index) => {
          return (
            <Box key={index} sx={{ flexShrink: 0 }}>
              <b>{item.label}:</b>
              {isLoading ? <Skeleton variant="rectangular" height={24} /> :
                <Box style={item.style}>{item.value}</Box>
              }
            </Box>
          )
        })}
      </Stack>
      <small style={{ color: '#555' }}>Considerando serviços com valor fornecedor definido</small>
    </Box>
  )
}

export default Summary
