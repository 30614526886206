import { useEffect, useState } from 'react'
import { fetchUtils } from 'react-admin'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import Collapse from '@mui/material/Collapse'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  position: 'relative',
  top: '-2px',
  padding: 0,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ImportChecker = () => {
  const [items, setItems] = useState([])
  const [expanded, setExpanded] = useState(false)

  const startDate = new Date().toISOString().split('T')[0]

  // endDate is 60 days after startDate
  const endDate = new Date(new Date(startDate).getTime() + 60 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    try {
      const url = `${process.env.REACT_APP_DATA_PROVIDER}/services/import-audit-items/notImported?startDate=${startDate}&endDate=${endDate}`

      fetchUtils.fetchJson(url).then(async (res) => {
        setItems(res.json.items)
      }).catch(error => {
        console.error(error)
      })
    } catch (e) {
      console.error(e)
    }
  }, [startDate, endDate])

  if (items && items.length) {
    return (
      <Alert severity="error" sx={{ marginTop: '10px' }}>
        <Stack direction="row" spacing={1}>
          <span><b>{items.length}</b> serviços que ocorrerão até {new Date(`${endDate}T00:00`).toLocaleDateString()} não foram importados.</span>
          {/* <a href="/#/imported-items">
            Ver audit. importação
          </a> */}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            size="small"
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Stack>
        <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ marginTop: '8px' }}>
          <b>BookingIds:</b> {items.map(item => item.BookingId).filter((value, index, self) => self.indexOf(value) === index).join(', ')}
        </Collapse>
      </Alert>
    )
  }

  return null
}

export default ImportChecker