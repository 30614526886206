import Box from '@mui/material/Box'
import { useState, useEffect } from 'react'
import { useRecordContext } from 'react-admin'

const options = {
  'notPaid': {
    label: 'Não pago',
    color: 'error'
  },
  'paid': {
    label: 'Pago',
    color: 'success'
  }
}

const ItemSupplierPaymentStatus = () => {
  const record = useRecordContext()
  const [status, setStatus] = useState('notPaid')

  useEffect(() => {
    if (record && record.supplierPaymentStatus) {
      setStatus(record.supplierPaymentStatus)
    }
  }, [record])

  if (!status) return null

  return (
    <Box sx={{
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 24,
      padding: '0 12px',
      color: 'white',
      borderRadius: '12px',
      fontSize: '12px',
      fontWeight: '600',
      bgcolor: options[status] ? options[status].color + '.light' : null
    }}>
      {options[status].label}
    </Box>
  )
}

export default ItemSupplierPaymentStatus
