import React from 'react'
import { List, Datagrid, TextField, DateField, EditButton, Authenticated } from 'react-admin'
import Box from '@mui/material/Box'

const CustomList = (props) => {
  return (
    <Authenticated>
      <List {...props} queryOptions={{ meta: { populate: 'deep,3' } }}>
        <Datagrid bulkActionButtons={false}>
          <Box source="id" sx={{ textAlign: 'center' }}>
            <TextField source="id" />
          </Box>
          <Box sx={{ width: '50px' }}>
            <EditButton />
          </Box>
          <TextField source="namePT" />
          <TextField source="nameEN" />
          <TextField source="nameES" />
          <TextField source="modelsPT" />
          <TextField source="modelsEN" />
          <TextField source="modelsES" />
          <TextField source="code" />
          <DateField source="updatedAt" />
        </Datagrid>
      </List>
    </Authenticated>
  )
}

export default CustomList