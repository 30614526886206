import { SaveButton, DeleteButton, Toolbar } from 'react-admin'
import Stack from '@mui/material/Stack'
import ItemValidate from './ItemValidate'

const EditFormToolbar = ({ mode = 'create', canValidate = false, setRedirectTo = () => { }, ...props }) => {
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
      <Stack direction="row" gap={2}>
        <SaveButton label="Salvar" alwaysEnable={true} onClick={() => setRedirectTo('edit')} />
        <SaveButton sx={{ marginRight: '2em' }} label="Salvar e criar novo" alwaysEnable={true} variant="text" onClick={() => setRedirectTo('create')} />
        {mode === 'edit' && canValidate ?
          <ItemValidate alwaysEnable={true} type="submit" redirectTo="list" />
          : ''}
      </Stack>
      <DeleteButton
        label="Excluir"
        mutationMode="pessimistic" />
    </Toolbar>
  )
}

export default EditFormToolbar
