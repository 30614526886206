import * as React from 'react'
import { useState, useEffect } from 'react'
import { useRecordContext } from 'react-admin'
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const ItemClient = ({ sx, openOnClick = false }) => {
  const record = useRecordContext()
  const [client, setClient] = useState()

  useEffect(() => {
    if (record && record.client && record.client.data && record.client.data.attributes) {
      setClient({ id: record.client.data.id, ...record.client.data.attributes })
    }
  }, [record])

  if (!client) return null

  return (
    <>
      <Tooltip
        title={
          <React.Fragment>
            <ul style={{ padding: '12px', margin: 0, listStyle: 'none', lineHeight: '22px' }}>
              <li><b>ID</b>: {client.id}</li>
              <li><b>Razão social</b>: {client.company_name}</li>
              <li><b>Nome fantasia</b>: {client.trade_name ? client.trade_name : '?'}</li>
              <li><b>CNPJ</b>: {client.cnpj}</li>
            </ul>
          </React.Fragment>
        }
      >
        <Button sx={{
          padding: 0,
          fontSize: '10px',
          lineHeight: '1.4em',
          fontWeight: 'bold',
          textAlign: 'left',
          ...sx
        }}
          href={openOnClick ? `/#/clients/${client.id}` : null}
          target={openOnClick ? `_blank` : null}>
          <Box sx={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '4px',
          }}>
            <Box sx={{
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}>
              {client.company_name}
            </Box>
            {openOnClick && <OpenInNewIcon sx={{ width: '14px', height: '14px' }} />}
          </Box>
        </Button>
      </Tooltip>
    </>
  )
}

export default ItemClient
