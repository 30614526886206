import { Edit, TextInput, useRecordContext, SimpleForm, TopToolbar, ListButton } from 'react-admin'
import Typography from '@mui/material/Typography'

const PostEditActions = () => {
  return (
    <TopToolbar>
      <ListButton />
    </TopToolbar>
  )
}

const Aside = () => {
  const record = useRecordContext()
  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Typography variant="h6">Detalhes</Typography>
      {record && (
        <>
          <Typography variant="body2">
            createdAt: {record.createdAt}
          </Typography>
          <br />
          <Typography variant="body2">
            updatedAt: {record.updatedAt}
          </Typography>
        </>
      )}
    </div>
  )
}

const EditPage = () => {

  return (
    <Edit aside={<Aside />} actions={<PostEditActions />}>
      <SimpleForm>
        <TextInput source="name" label="Nome da categoria" />
      </SimpleForm>
    </Edit>
  )
}

export default EditPage
