import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DateField,
  FunctionField
} from 'react-admin'
import Box from '@mui/material/Box'
import { statusOptions, paymentStatusOptions } from '../utils'
import ItemVoucherObs from './ItemVoucherObs'
import ItemObs from './ItemObs'
import ItemClient from './ItemClient'
import StatusButton from '../../components/StatusButton'

const Panel = () => {
  return (
    <Box>
    </Box>
  )
}

const DateTimeField = (props) => {
  const { source } = props

  return (
    <DateField source={source} showTime
      sx={{ display: 'inline-block', width: '150px' }}
      options={{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }} />
  )
}

const CustomList = (props) => {
  // return only these fields from API
  const reqFields = [
    'bookingRef',
    'status',
    'paymentStatus',
    'createdAt',
    'voucherObservation',
    'observation',
    'places',
    'startDate',
    'endDate',
  ]

  const meta = {}

  /**
   * Build meta object like:
   * {
   *  'fields[0]': 'bookingRef',
   *  'fields[1]': 'status',
   *  'fields[2]': 'paymentStatus'
   *  ...
   * }
   */
  reqFields.forEach((item, index) => {
    meta[`fields[${index}]`] = item
  })

  // populate relations
  meta[`populate`] = 'deep,3'

  return (
    <List {...props} queryOptions={{ meta: meta }}>
      <Datagrid bulkActionButtons={false} expand={<Panel />}>
        <EditButton label="" sx={{ display: 'inline-block', minWidth: 'auto' }} />
        <StatusButton resource="bookings" source="status" options={statusOptions} variant="text" readOnly={true} />
        <StatusButton resource="bookings" source="paymentStatus" label="Sts. Pgto." options={paymentStatusOptions} variant="text" readOnly={true} />
        <FunctionField label="Cidade" source="places"
          sx={{ display: 'inline-block', minWidth: '130px' }}
          render={record => {
            return record.places?.data?.map(place => place.attributes.namePT).join(', ')
          }} />
        <DateTimeField source="startDate" label="Data/Hora Local Início" />
        <DateTimeField source="endDate" label="Data/Hora Local Fim" />
        <TextField source="bookingRef" sx={{ display: 'inline-block', minWidth: '80px' }} />
        <FunctionField label="Observação" source="observation" render={record => <ItemObs obs={record.observation} />} />
        <Box label="Voucher Obs." sx={{ width: '290px' }}>
          <ItemVoucherObs />
        </Box>
        <Box label="Cliente" sx={{ width: '125px' }}>
          <ItemClient />
        </Box>
        <DateTimeField source="createdAt" label="Criada em" />
      </Datagrid>
    </List>
  )
}

export default CustomList
