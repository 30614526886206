import { useState, useEffect, useRef } from 'react'
import * as S from './styles'

const getDateClasses = (date) => {
  const classes = ['item']

  if (date.active) classes.push('item-active')

  if (date.inRange) classes.push('item-in-range')

  if (date.startDate) classes.push('item-start-date')

  if (date.endDate) classes.push('item-end-date')

  return classes.join(' ')
}

const getDateString  = (date) => {
  const offset = date.getTimezoneOffset()
  date = new Date(date.getTime() - (offset * 60 * 1000))

  return date.toISOString().split('T')[0]
}

const Month = ({ ...props }) => {
  const initialDateISOString = props.date ? getDateString(props.date) : null
  const [ranges, setRanges] = useState(props.ranges)
  const [dates, setDates] = useState([])
  const monthRef = useRef()

  useEffect(() => {
    setRanges(props.ranges)
  }, [props.ranges])

  useEffect(() => {
    if (initialDateISOString) {
      const date = new Date(`${initialDateISOString}T00:00`)
      const currentMonth = date.getMonth()
      const datesAlt = []

      date.setDate(1)

      while (date.getMonth() === currentMonth) {
        const ISOString = getDateString(date)
        const dateObj = {
          ISOString: ISOString,
          date: new Date(`${ISOString}T00:00`)
        }

        if (props.minDate) {
          const ISOMinDate = getDateString(props.minDate)
          const minDate = new Date(`${ISOMinDate}T00:00`)

          if (dateObj.date < minDate) {
            dateObj.disabled = true
          }
        }

        if (ranges.length && ranges[0].startDate && ranges[0].endDate) {
          const range = ranges[0]

          if (
            getDateString(range.startDate) === ISOString ||
            getDateString(range.endDate) === ISOString
          ) {
            dateObj.active = true
          }

          if (range.startDate.getTime() !== range.endDate.getTime()) {
            if (
              ISOString > getDateString(range.startDate) &&
              ISOString < getDateString(range.endDate)
            ) {
              dateObj.inRange = true
            }

            if (getDateString(range.startDate) === ISOString) {
              dateObj.startDate = true
            }

            if (getDateString(range.endDate) === ISOString) {
              dateObj.endDate = true
            }
          }
        }

        datesAlt.push(dateObj)
        date.setDate(date.getDate() + 1)
      }

      setDates(datesAlt)
    }
  }, [initialDateISOString, ranges, props.minDate])

  useEffect(() => {
    if (dates.length && props.isShowingMonths && props.scroll && monthRef) {
      const startDate = dates.findIndex(item => item.startDate)
      const endDate = dates.findIndex(item => item.endDate)

      if (startDate && endDate && startDate !== endDate) {
        setTimeout(() => {
          monthRef.current.scrollIntoView({ behavior: 'smooth' })
        }, 30)
      }
    }
  }, [dates, props.isShowingMonths, props.scroll])

  const handleDateClick = (e, item) => {
    const range = ranges.length ? ranges[0] : {
      startDate: item.date,
      endDate: item.date
    }

    if (range.startDate !== range.endDate) {
      range.startDate = item.date
      range.endDate = item.date
    }

    if (item.date > range.startDate) {
      range.endDate = item.date
    }

    if (item.date < range.endDate) {
      range.startDate = item.date
    }

    if (props.onRangeChange) {
      props.onRangeChange(range)
    }
  }

  return (
    <S.Month className="month" ref={monthRef}>
      <div className="month-header">
        <div className="month-title">
          {dates.length && dates[0].date.toLocaleDateString('pt-BR', { month: 'long', year: 'numeric' })}
        </div>
      </div>
      <div className="month-weekdays">
        <span className="item">D</span>
        <span className="item">S</span>
        <span className="item">T</span>
        <span className="item">Q</span>
        <span className="item">Q</span>
        <span className="item">S</span>
        <span className="item">S</span>
      </div>
      <div className="month-days">
        {dates.map((item, index) =>
          <button key={index}
            type="button"
            className={getDateClasses(item)}
            disabled={item.disabled}
            style={{ marginLeft: `${item.date.getDate() === 1 ? `${item.date.getDay() * 14.28}%` : 0}` }}
            onClick={e => handleDateClick(e, item)}>
            <span className="item-content">{item.date.getDate()}</span>
          </button>
        )}
      </div>
    </S.Month>
  )
}

export default Month
