import Icon from '@mui/icons-material/WineBar'
import List from './List'
import Create from './Create'
import Edit from './Edit'

const props = {
  icon: Icon,
  options: { label: 'Itens Adicionais Transfer' },
  list: List,
  create: Create,
  edit: Edit
}

export default props
