import Icon from '@mui/icons-material/CloudDone'
import List from './List'

const props = {
  icon: Icon,
  options: { label: 'Auditoria de importação' },
  list: List
}

export default props
