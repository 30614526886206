import * as React from 'react'
import { useState, useEffect } from 'react'
import { useRecordContext } from 'react-admin'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))

const ItemClient = () => {
  const record = useRecordContext()
  const [client, setClient] = useState()

  useEffect(() => {
    if (record && record.client && record.client.data && record.client.data.attributes) {
      setClient({ id: record.client.data.id, ...record.client.data.attributes })
    }
  }, [record])

  if (!client) return null

  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            <ul style={{ padding: '12px', margin: 0, listStyle: 'none', lineHeight: '22px' }}>
              <li><b>ID</b>: {client.id}</li>
              <li><b>Razão social</b>: {client.company_name}</li>
              <li><b>Nome fantasia</b>: {client.trade_name ? client.trade_name : '?'}</li>
              <li><b>CNPJ</b>: {client.cnpj}</li>
            </ul>
          </React.Fragment>
        }
      >
        <Button sx={{
          padding: 0,
          fontSize: '10px',
          lineHeight: '1.4em',
          fontWeight: 'bold',
          textAlign: 'left'
        }}>
          <Box sx={{
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}>
            {client.company_name}
          </Box>
        </Button>
      </HtmlTooltip>
    </>
  )
}

export default ItemClient
