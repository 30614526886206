import { useState } from 'react'
import { useUpdate, useRecordContext, useNotify } from 'react-admin'
import { Form, Button } from 'react-admin'
import CurrencyInput from 'react-currency-input-field'
import EditIcon from '@mui/icons-material/Edit'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const formatPrice = (currency, value) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(value)
}

const ItemSupplierPrice = ({ ...props }) => {
  const record = useRecordContext()
  const notify = useNotify()
  const [update, { isLoading }] = useUpdate()
  const [supplierPrice, setSupplierPrice] = useState({ currency: 'BRL', value: null })
  const [shouldSave, setShouldSave] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [canEdit, setCanEdit] = useState(!!props.canEdit)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  useState(() => {
    if (record.supplierPrice && record.supplierPrice.value) {
      const supplierPriceAlt = { ...supplierPrice }

      supplierPriceAlt.currency = record.supplierPrice.currency || 'BRL'
      supplierPriceAlt.value = record.supplierPrice.value

      setSupplierPrice(supplierPriceAlt)
    }
  }, [record.supplierPrice])

  const handleInputBlur = (e) => {
    if (isConfirmDialogOpen || (e.relatedTarget && e.relatedTarget.id === 'submit-button')) {
      // ignore, submit button was clicked
    } else {
      if (record.supplierPrice) {
        setSupplierPrice(record.supplierPrice)
      } else {
        setSupplierPrice({ currency: 'BRL', value: null })
      }
  
      setCanEdit(!!props.canEdit)
      setIsEditing(false)
    }
  }

  const handleInputChange = (value) => {
    const fixedValue = value ? parseFloat(value.replace(',', '.')) : null
    setSupplierPrice({ currency: supplierPrice.currency, value: fixedValue })

    if (fixedValue) {
      if (record.supplierPrice && record.supplierPrice.value === fixedValue) {
        setShouldSave(false)
      } else {
        setShouldSave(true)
      }
    } else {
      setShouldSave(false)
    }
  }

  const handleDialogClose = () => {
    setIsConfirmDialogOpen(false)
    setIsEditing(false)

    if (record.supplierPrice) {
      setSupplierPrice(record.supplierPrice)
    }
  }

  const handleFormSubmit = async (data) => {
    setIsConfirmDialogOpen(true)
  }

  const handleDialogConfirm = async () => {
    update(
      'services',
      { id: record.id, data: { supplierPrice: supplierPrice }, previousData: record },
      {
        onSuccess: () => {
          setShouldSave(false)
          setIsEditing(false)
          setCanEdit(!!props.canEdit)
          setIsConfirmDialogOpen(false)
        },
        onError: (error) => {
          console.error(error)
          notify(`Erro ao alterar preço fornecedor!`, { type: 'error' })
        }
      }
    )
  }

  return (
    <>
      {isEditing ?
        <Form onSubmit={handleFormSubmit}>
          <div style={{ position: 'relative', display: 'flex', gap: '4px' }}>
            {/* <input source="checkOutHour" label="" hiddenLabel onChange={handleInputChange} sx={{
              '& .MuiInputBase-root': {
                borderRadius: 0,
                margin: '0 0 -20px'
              },
              '& .MuiInputBase-input': {
                padding: '0 2px',
                fontSize: '0.875rem'
              }
            }} /> */}
            <CurrencyInput
              style={{
                color: 'red',
                fontWeight: 'bold',
                fontSize: '16px',
                padding: '2px 4px',
                width: '110px'
              }}
              placeholder="R$ 0,00"
              intlConfig={{ locale: 'pt-BR', currency: supplierPrice.currency }}
              defaultValue={supplierPrice.value ? supplierPrice.value.toFixed(2) : null}
              decimalsLimit={2}
              onValueChange={handleInputChange}
              onBlur={handleInputBlur}
            />
            {shouldSave &&
              <Button disableElevation variant="contained" type="submit" id="submit-button" sx={{
                padding: 0,
                minWidth: '32px',
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: '600',
                borderRadius: '4px',
                'span': {
                  margin: 0
                }
              }}>
                <>OK</>
              </Button>
            }
          </div>
        </Form>
        : <span style={{ color: 'red', fontWeight: 'bold' }}>
          {
            (supplierPrice && supplierPrice.value) ?
              <>
                {canEdit ?
                  <Button variant="outlined" endIcon={<EditIcon />} color="error"
                    sx={{
                      fontWeight: 'bold',
                    }}
                    onClick={() => setIsEditing(true)}>
                    <>{formatPrice(supplierPrice.currency, supplierPrice.value)}</>
                  </Button>
                  : <>{formatPrice(supplierPrice.currency, supplierPrice.value)}</>
                }
              </>
              : <Button variant="outlined" endIcon={<EditIcon />}
                sx={{
                  fontWeight: 'bold',
                }}
                onClick={() => setIsEditing(true)}><>Definir</></Button>
          }
        </span>}

      <Dialog
        open={isConfirmDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Confirmar ação</DialogTitle>
        <DialogContent>
          <DialogContentText>
            O <b><u>Valor Fornecedor</u></b> será alterado para <b style={{ color: 'red' }}>{formatPrice(supplierPrice.currency, supplierPrice.value)}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isLoading ? <Button color="error" variant="outlined" onClick={handleDialogClose}><>Cancelar</></Button> : ''}
          <Button autoFocus color="success" variant="contained" onClick={handleDialogConfirm}>
            {isLoading ? <>...</> : <>Confirmar</>}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ItemSupplierPrice