import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { serviceMap } from './utils'

const ServiceList = (props) => {
  const [services, setServices] = useState([])

  useEffect(() => {
    setServices(props.services)
  } , [props.services])

  const handleInputChange = (event) => {
    const target = event.target
    const name = target.name
    const value = target.value

    const newServices = services.map((service) => {
      if (service.name === name) {
        return {
          ...service,
          description: value
        }
      }

      return service
    })

    setServices(newServices)

    if (props.onChange) {
      props.onChange(newServices)
    }
  }

  return (
    <>
      {services ? services.map((service, index) => {
        return (
          <Box sx={{ width: '720px' }} key={index}>
            <Box sx={{ padding: '1em', border: '1px solid #ddd', borderBottom: 'none', background: '#eee', borderRadius: '12px 12px 0 0', fontWeight: '600' }}>
              {serviceMap[service.name] || service.name}
            </Box>
            <textarea rows="5" style={{ padding: '1em', border: '1px solid #ddd', borderTop: 'none', width: '100%', outline: '0' }}
              value={service.description}
              name={service.name}
              onChange={handleInputChange} />
          </Box>
        )
      }) : null}
    </>
  )
}

export default ServiceList
